/* eslint-disable no-undef */
import * as actionTypes from './actionTypes'
import { DELETE } from '../callApiWrapper'

export const getMyProfile = (token, id, server) => {
  return {
    type: actionTypes.GET_MY_PROFILE,
    token: token,
    id: id,
    server: server
  }
}

export const getMyProfileStart = () => {
  return {
    type: actionTypes.GET_MY_PROFILE_START
  }
}

export const getMyProfileSuccess = user => {
  return {
    type: actionTypes.GET_MY_PROFILE_SUCCESS,
    user: user
  }
}
export const getMyProfileFail = error => {
  return {
    type: actionTypes.GET_MY_PROFILE_FAIL,
    error: error
  }
}

// ================================== EDIT

export const editMyProfile = (token, data, file, server) => {
  return {
    type: actionTypes.EDIT_MY_PROFILE,
    data: data,
    file: file,
    server: server,
    token: token
  }
}
export const editMyProfileStart = () => {
  return {
    type: actionTypes.EDIT_MY_PROFILE_START
  }
}

export const editMyProfileSuccess = response => {
  return {
    type: actionTypes.EDIT_MY_PROFILE_SUCCESS,
    response: response
  }
}

export const editMyProfileFail = error => {
  return {
    type: actionTypes.EDIT_MY_PROFILE_FAIL,
    error: error
  }
}

// Deactive my account

export const deleteMyProfile = (token, server) => {
  return {
    type: actionTypes.DELETE_MY_PROFILE,
    server: server,
    token: token
  }
}
export const deleteMyProfileStart = () => {
  return {
    type: actionTypes.DELETE_MY_PROFILE_START
  }
}

export const deleteMyProfileSuccess = response => {
  return {
    type: actionTypes.DELETE_MY_PROFILE_SUCCESS,
    response: response
  }
}

export const deleteMyProfileFail = error => {
  return {
    type: actionTypes.DELETE_MY_PROFILE_FAIL,
    error: error
  }
}

export const deleteMyProfileClear = () => dispatch => {
  dispatch({
    type: actionTypes.DELETE_MY_PROFILE_CLEAR
  })
}
