import { put } from "redux-saga/effects";
import * as actions from "../actions/index";
import { POST, PUT, DELETE } from "../callApiWrapper";

export function* addStreamsSaga(action) {
  yield put(actions.addStreamsStart());
  let response;
  if (action.data.joinStreamRequests[0].avatar.encoding === "BASE64") {
    const ddb = `--${action.data.joinStreamRequests[0].avatar.key}`;
    const ending = `${ddb}--`;
    let body;
    let file;
    const jsonData =
      `${ddb}\n` +
      `Content-Disposition: form-data; name="data"\n` +
      `Content-Type: application/json\n\n` +
      `${JSON.stringify(action.data)}\n`;

    if (action.file) {
      file =
        `${ddb}\n` +
        `Content-Disposition: form-data; name="files"; filename="eventStreamAvatar"\n` +
        `Content-Type: ${action.file.mime}\n\n` +
        `${action.file.data}\n`;
    }
    body = jsonData;
    if (file) {
      body = body + file + ending;
    } else {
      body = body + ending;
    }
    response = yield fetch(`${action.server}api/vume/event/streams`, {
      method: "POST",
      headers: {
        "Content-type": `multipart/form-data; boundary=${action.data.joinStreamRequests[0].avatar.key}`,
        "X-Auth-Token": action.token
      },
      body
    })
      .then(res => {
        return res.json();
      })
      .then(json => {
        const newResponse = {
          data: json,
          status: json.statusCode ? json.statusCode : 200
        };
        return newResponse;
      })
      .catch(err => {
        return err;
      });
  } else {
    const streamsData = new FormData();
    streamsData.append(
      "data",
      new Blob([JSON.stringify(action.data)], { type: "application/json" })
    );
    const headers = {
      "X-Auth-Token": action.token
    };
    response = yield POST(
      `${action.server}api/vume/event/streams`,
      streamsData,
      headers
    );
  }

  if (response && response === 503) {
    yield put(actions.serverErrorSuccess(response));
  } else if (response && response.status === 200) {
    yield put(actions.addStreamsSuccess(response.data));
  } else if (response && (response.status === 403 || response.status === 401)) {
    yield put(actions.userLogout(action.token, action.server));
    yield put(actions.addStreamsFail(response.data));
  } else {
    yield put(actions.addStreamsFail(response.data));
  }
}

export function* updateStreamSaga(action) {
  yield put(actions.updateStreamStart());
  let response;
  if (action.object.avatar.encoding === "BASE64") {
    const ddb = `--${action.object.avatar.key}`;
    const ending = `${ddb}--`;
    let body;
    let file;
    const jsonData =
      `${ddb}\n` +
      `Content-Disposition: form-data; name="data"\n` +
      `Content-Type: application/json\n\n` +
      `${JSON.stringify(action.object)}\n`;

    if (action.file) {
      file =
        `${ddb}\n` +
        `Content-Disposition: form-data; name="file"; filename="eventStreamAvatar"\n` +
        `Content-Type: ${action.file.mime}\n\n` +
        `${action.file.data}\n`;
    }
    body = jsonData;
    if (file) {
      body = body + file + ending;
    } else {
      body = body + ending;
    }
    response = yield fetch(`${action.server}api/vume/event/stream`, {
      method: "PUT",
      headers: {
        "Content-type": `multipart/form-data; boundary=${action.object.avatar.key}`,
        "X-Auth-Token": action.token
      },
      body
    })
      .then(res => {
        return res.json();
      })
      .then(json => {
        const newResponse = {
          data: json,
          status: json.statusCode ? json.statusCode : 200
        };
        return newResponse;
      })
      .catch(err => {
        return err;
      });
  } else {
    const streamData = new FormData();
    streamData.append(
      "data",
      new Blob([JSON.stringify(action.object)], { type: "application/json" })
    );
    const headers = {
      "X-Auth-Token": action.token
    };
    response = yield PUT(
      `${action.server}api/vume/event/stream`,
      streamData,
      headers
    );
  }

  if (response && response === 503) {
    yield put(actions.serverErrorSuccess(response));
  } else if (response && response.status === 200) {
    yield put(actions.updateStreamSuccess(response.data));
  } else if (response && (response.status === 403 || response.status === 401)) {
    yield put(actions.userLogout(action.token, action.server));
    yield put(actions.updateStreamFail(response.data));
  } else {
    yield put(actions.updateStreamFail(response.data));
  }
}

export function* deleteStreamSaga(action) {
  yield put(actions.deleteStreamStart());
  const headers = {
    "X-Auth-Token": action.token,
    "content-type": "application/json"
  };
  const response = yield DELETE(
    `${action.server}api/vume/event/stream/${action.eventId}/${action.streamId}`,
    headers
  );
  if (response === 503) {
    yield put(actions.serverErrorSuccess(response));
  } else if (response.status === 200) {
    yield put(actions.deleteStreamSuccess());
  } else if (response && (response.status === 403 || response.status === 401)) {
    yield put(actions.userLogout(action.token, action.server));
    yield put(actions.deleteStreamFail(response.data));
  } else {
    yield put(actions.deleteStreamFail(response.data));
  }
}
