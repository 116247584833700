
import * as actionTypes from './actionTypes'

export const changePassword = (token,formInfo,server) => {
    return {
      type: actionTypes.CHANGE_PASSWORD,
      formInfo:formInfo,
      server:server,
      token:token
    };
  };
  
  export const changePasswordStart = () => {
    return {
      type: actionTypes.CHANGE_PASSWORD_START
    };
  };
  
  export const changePasswordSuccess = response => {
    return {
      type: actionTypes.CHANGE_PASSWORD_SUCCESS,
      response:response
    };
  };
  export const changePasswordFail = error => {
    return {
      type: actionTypes.CHANGE_PASSWORD_FAIL,
      error: error
    };
  };