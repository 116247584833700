import axios, { CancelToken } from 'axios'
import { CANCEL } from 'redux-saga'

export const POST = (url, data, headers) => {
  const source = CancelToken.source()

  const call = axios
    .post(url, data, { headers: headers, cancelToken: source.token })
    .then((response) => {
      return response
    })
    .catch((error) => {
      if (error.message.includes('Network Error')) {
        return 503
      } else if (error.response && error.response.status >= 500) {
        return 503
      } else if (error.response && error.response.status === 451) {
        return 451
      } else {
        return error.response
      }
    })
  call[CANCEL] = () => source.cancel()
  return call
}

export const PUT = (url, data, headers) => {
  const source = CancelToken.source()

  const call = axios
    .put(url, data, { headers: headers, cancelToken: source.token })
    .then((response) => {
      return response
    })
    .catch((error) => {
      if (error.message.includes('Network Error')) {
        return 503
      } else if (error.response && error.response.status >= 500) {
        return 503
      } else if (error.response && error.response.status === 451) {
        return 451
      } else {
        return error.response
      }
    })
  call[CANCEL] = () => source.cancel()

  return call
}

export const GET = (url, headers, auth) => {
  const source = CancelToken.source()

  const call = axios
    .get(url, {
      headers: headers,
      cancelToken: source.token,
      data: null,
      auth: auth,
    })
    .then((response) => {
      return response
    })
    .catch((error) => {
      if (error.message.includes('Network Error')) {
        return 503
      } else if (error.response && error.response.status >= 500) {
        return 503
      } else if (error.response && error.response.status === 451) {
        return 451
      } else {
        return error.response
      }
    })
  call[CANCEL] = () => source.cancel()

  return call
}

export const DELETE = (url, headers, data) => {
  const source = CancelToken.source()

  const call = axios
    .delete(url, {
      headers: headers,
      cancelToken: source.token,
      data: data || null
    })
    .then((response) => {
      return response
    })
    .catch((error) => {
      if (error.message.includes('Network Error')) {
        return 503
      } else if (error.response && error.response.status >= 500) {
        return 503
      } else if (error.response && error.response.status === 451) {
        return 451
      } else {
        return error.response
      }
    })
  call[CANCEL] = () => source.cancel()

  return call
}
