import { put } from "redux-saga/effects";
import * as actions from "../actions/index";
import { POST, DELETE } from "../callApiWrapper";

export function* addTotalViewSaga(action) {
  yield put(actions.addTotalViewStart());
  const headers = {
    "X-Auth-Token": action.token,
    "Content-type": "application/json"
  };
  const response = yield POST(
    `${action.server}api/vume/eventView`,
    action.object,
    headers
  );
  if (response && response === 503) {
    yield put(actions.serverErrorSuccess(response));
  } else if (response && response === 451) {
    yield put(actions.permissionErrorSuccess(Math.random()));
  } else if (response && response.status === 200) {
    yield put(actions.addTotalViewSuccess(response.data));
  } else if (response && (response.status === 403 || response.status === 401)) {
    yield put(actions.userLogout(action.token, action.server));
  } else {
    yield put(actions.addTotalViewFail(response));
  }
}

// ===============

export function* addLiveViewSaga(action) {
  yield put(actions.addLiveViewStart());
  const headers = {
    "X-Auth-Token": action.token,
    "Content-type": "application/json"
  };
  const response = yield POST(
    `${action.server}api/vume/eventLiveView`,
    action.object,
    headers
  );
  if (response && response === 503) {
    yield put(actions.serverErrorSuccess(response));
  } else if (response && response.status === 200) {
    yield put(actions.addLiveViewSuccess(response.data));
  } else if (response && (response.status === 403 || response.status === 401)) {
    yield put(actions.userLogout(action.token, action.server));
  } else {
    yield put(actions.addLiveViewFail(response));
  }
}

// ==================

export function* deleteLiveViewSaga(action) {
  yield put(actions.deleteLiveViewStart());
  const headers = {
    "X-Auth-Token": action.token
  };
  const response = yield DELETE(
    `${action.server}api/vume/eventLiveView`,
    headers,
    action.object
  );
  if (response && response === 503) {
    yield put(actions.serverErrorSuccess(response));
  } else if (response && response.status === 200) {
    yield put(actions.deleteLiveViewSuccess(response.status));
  } else if (response && (response.status === 403 || response.status === 401)) {
    yield put(actions.userLogout(action.token, action.server));
  } else {
    yield put(actions.deleteLiveViewFail(response));
  }
}
