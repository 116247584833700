import  cryptoAES from 'browserify-aes'
import crypto from 'crypto';

export const decryptionAES256 = (data,key) =>{
    const inEncData = data
    const inKey = key
    const algorithm = "aes-256-cbc";
    try {
        let buff = new Buffer(inEncData, 'base64');
        let currentIV = buff.subarray(0, 16);
        let hasher = crypto.createHash("sha256");
        let keyForEnc = hasher.update(inKey).digest();
      let decipher = cryptoAES.createDecipheriv(algorithm, keyForEnc, currentIV);
        let decrypted = decipher.update(Buffer.from(buff.subarray(16, buff.length)), 'binary', 'utf8');
        let decryptedFinal = decipher.final('utf8');
        return decrypted + decryptedFinal
      }
      catch(e){
        return e
      }
}