import * as actionTypes from './actionTypes'

export const getSearchRoles = (token,searchModel,server) => {
    return {
      type: actionTypes.GET_SEARCH_ROLES,
      token:token,
      searchModel: searchModel,
      server:server,
    };
  };

  export const getSearchRolesStart = (loadMore) => {
    return {
      type: actionTypes.GET_SEARCH_ROLES_START,
    };
  };
  
  export const getSearchRolesSuccess = roles => {
    return {
      type: actionTypes.GET_SEARCH_ROLES_SUCCESS,
      roles: roles,
    };
  };
  
  export const getSearchRolesFail = error => {

    return {
      type: actionTypes.GET_SEARCH_ROLES_FAIL,
      error: error
    };
  };


// ============================================= SEARCH ROLES

export const getRoleById = (token,id,server) => {
    return {
      type: actionTypes.GET_ROLE_BY_ID,
      id: id,
      server:server,
      token:token
    };
  };
  
  export const getRoleByIdStart = () => {
    return {
      type: actionTypes.GET_ROLE_BY_ID_START,
    };
  };
  
  export const getRoleByIdSuccess = role => {
    return {
      type: actionTypes.GET_ROLE_BY_ID_SUCCESS,
      role: role,
    };
  };
  
  export const getRoleByIdFail = error => {
    return {
      type: actionTypes.CHECK_EVENT_BY_ID_FAIL,
      error: error
    };
  };
  // ============================================= ROLE BY ID

  export const deleteRole = (token,id,server) => {
    return {
      type: actionTypes.DELETE_ROLE,
      id: id,
      server:server,
      token:token
    };
  };
  
  export const deleteRoleStart = () => {
    return {
      type: actionTypes.DELETE_ROLE_START,
    };
  };
  
  export const deleteRoleSuccess = response => {
    return {
      type: actionTypes.DELETE_ROLE_SUCCESS,
      response: response,
    };
  };
  
  export const deleteRoleFail = error => {
    return {
      type: actionTypes.DELETE_ROLE_FAIL,
      error: error
    };
  };
  // ============================================= DELETE ROLE


  export const editRole = (token,data,server) => {
    return {
      type: actionTypes.EDIT_ROLE,
      data: data,
      server:server,
      token:token
    };
  };
  
  export const editRoleStart = () => {
    return {
      type: actionTypes.EDIT_ROLE_START,
    };
  };
  
  export const editRoleSuccess = response => {
    return {
      type: actionTypes.EDIT_ROLE_SUCCESS,
      response: response,
    };
  };
  
  export const editRoleFail = error => {
    return {
      type: actionTypes.EDIT_ROLE_FAIL,
      error: error
    };
  };
  // ============================================= EDIT ROLE


  export const addRole = (token,data,server) => {
    return {
      type: actionTypes.ADD_ROLE,
      data: data,
      server:server,
      token:token
    };
  };
  
  export const addRoleStart = () => {
    return {
      type: actionTypes.ADD_ROLE_START,
    };
  };
  
  export const addRoleSuccess = response => {
    return {
      type: actionTypes.ADD_ROLE_SUCCESS,
      response: response,
    };
  };
  
  export const addRoleFail = error => {
    return {
      type: actionTypes.ADD_ROLE_FAIL,
      error: error
    };
  };
  // ============================================= EDIT ROLE

