import * as actionTypes from "../actions/actionTypes";

const initialState = {
  response: null,
  comments: null,
  loading: false,
  error: null,
  replies: null
};

const addCommentStart = state => {
  const newState = {
    ...state,
    loading: true,
    error: null,
    response: null
  };
  return newState;
};
const addCommentSuccess = (state, action) => {
  const newState = {
    ...state,
    loading: false,
    response: action.response,
    error: null
  };
  return newState;
};

const addCommentFail = (state, action) => {
  const newState = {
    ...state,
    response: null,
    loading: false,
    error: action.error
  };
  return newState;
};

// ==================================== Search comments

const searchCommentsStart = state => {
  const newState = {
    ...state,
    loading: true,
    error: null,
    comments: null
  };
  return newState;
};
const searchCommentsSuccess = (state, action) => {
  const newState = {
    ...state,
    loading: false,
    comments: action.comments,
    error: null
  };
  return newState;
};

const searchCommentsFail = (state, action) => {
  const newState = {
    ...state,
    comments: null,
    loading: false,
    error: action.error
  };
  return newState;
};

// ==================================== Delete comments
const deleteCommentStart = state => {
  const newState = {
    ...state,
    loading: true,
    error: null,
    response: null
  };
  return newState;
};
const deleteCommentSuccess = (state, action) => {
  const newState = {
    ...state,
    loading: false,
    response: action.response,
    error: null
  };
  return newState;
};

const deleteCommentFail = (state, action) => {
  const newState = {
    ...state,
    response: null,
    loading: false,
    error: action.error
  };
  return newState;
};

// ====================================================== EDIT COMMENT

const editCommentStart = state => {
  const newState = {
    ...state,
    loading: true,
    error: null,
    response: null
  };
  return newState;
};
const editCommentSuccess = (state, action) => {
  const newState = {
    ...state,
    loading: false,
    response: action.response,
    error: null
  };
  return newState;
};

const editCommentFail = (state, action) => {
  const newState = {
    ...state,
    response: null,
    loading: false,
    error: action.error
  };
  return newState;
};

// ==================================== Search comment Replies

const searchCommentRepliesStart = state => {
  const newState = {
    ...state,
    loading: true,
    error: null,
    replies: null
  };
  return newState;
};
const searchCommentRepliesSuccess = (state, action) => {
  const newState = {
    ...state,
    loading: false,
    replies: action.replies,
    error: null
  };
  return newState;
};

const searchCommentRepliesFail = (state, action) => {
  const newState = {
    ...state,
    replies: null,
    loading: false,
    error: action.error
  };
  return newState;
};
// ====================================  BLOCK REPORTED COMMENT

const blockCommentStart = (state, action) => {
  const newState = {
    ...state,
    response: null,
    loading: false
  };
  return newState;
};
const blockCommentSuccess = (state, action) => {
  const newState = {
    ...state,
    loading: false,
    response: action.response,
    error: null
  };
  return newState;
};

const blockCommentFail = (state, action) => {
  const newState = {
    ...state,
    loading: false,
    response: null,
    error: action.error
  };
  return newState;
};
export const commentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ADD_COMMENT_START:
      return addCommentStart(state);
    case actionTypes.ADD_COMMENT_SUCCESS:
      return addCommentSuccess(state, action);
    case actionTypes.ADD_COMMENT_FAIL:
      return addCommentFail(state, action);

    case actionTypes.SEARCH_COMMENTS_START:
      return searchCommentsStart(state);
    case actionTypes.SEARCH_COMMENTS_SUCCESS:
      return searchCommentsSuccess(state, action);
    case actionTypes.SEARCH_COMMENTS_FAIL:
      return searchCommentsFail(state, action);

    case actionTypes.SEARCH_COMMENT_REPLIES_START:
      return searchCommentRepliesStart(state);
    case actionTypes.SEARCH_COMMENT_REPLIES_SUCCESS:
      return searchCommentRepliesSuccess(state, action);
    case actionTypes.SEARCH_COMMENT_REPLIES_FAIL:
      return searchCommentRepliesFail(state, action);

    case actionTypes.DELETE_COMMENT_START:
      return deleteCommentStart(state);
    case actionTypes.DELETE_COMMENT_SUCCESS:
      return deleteCommentSuccess(state, action);
    case actionTypes.DELETE_COMMENT_FAIL:
      return deleteCommentFail(state, action);

    case actionTypes.EDIT_COMMENT_START:
      return editCommentStart(state);
    case actionTypes.EDIT_COMMENT_SUCCESS:
      return editCommentSuccess(state, action);
    case actionTypes.EDIT_COMMENT_FAIL:
      return editCommentFail(state, action);

      case actionTypes.BLOCK_COMMENT_START:
        return blockCommentStart(state, action);
      case actionTypes.BLOCK_COMMENT_SUCCESS:
        return blockCommentSuccess(state, action);
      case actionTypes.BLOCK_COMMENT_FAIL:
        return blockCommentFail(state, action);
    default:
      return state;
  }
};
