import { put, cancelled } from "redux-saga/effects";
import * as actions from "../actions/index";
import { GET, POST, DELETE } from "../callApiWrapper";

export function* getUserProfileSaga(action) {
  yield put(actions.getUserProfileStart());
  const headers = {
    "X-Auth-Token": action.token,
    "Content-type": "application/json"
  };
  const response = yield GET(
    `${action.server}api/user/profile/get/${action.id}`,
    headers
  );
  if (response && response === 503) {
    yield put(actions.serverErrorSuccess(response));
  } else if (response && response === 451) {
    yield put(actions.permissionErrorSuccess(Math.random()));
  } else if (response && response.status === 200) {
    yield put(actions.getUserProfileSuccess(response.data));
  } else if (response && (response.status === 403 || response.status === 401)) {
    yield put(actions.userLogout(action.token, action.server));
  } else {
    yield put(actions.getUserProfileFail(response));
  }
}

// ======================================

export function* followUserSaga(action) {
  yield put(actions.followUserStart());
  const headers = {
    "X-Auth-Token": action.token
  };
  const response = yield POST(
    `${action.server}api/socializing/userFollow`,
    action.object,
    headers
  );
  if (response && response === 503) {
    yield put(actions.serverErrorSuccess(response));
  } else if (response && response === 451) {
    yield put(actions.permissionErrorSuccess(Math.random()));
  } else if (response && response.status === 200) {
    yield put(actions.followUserSuccess(response.data));
  } else if (response && (response.status === 403 || response.status === 401)) {
    yield put(actions.userLogout(action.token, action.server));
  } else {
    yield put(actions.followUserFail(response));
  }
}

// ======================================

export function* unfollowUserSaga(action) {
  yield put(actions.unfollowUserStart());
  const headers = {
    "X-Auth-Token": action.token
  };
  const response = yield DELETE(
    `${action.server}api/socializing/userFollow`,
    headers,
    action.object
  );
  if (response === 503) {
    yield put(actions.serverErrorSuccess(response));
  } else if (response && response === 451) {
    yield put(actions.permissionErrorSuccess(Math.random()));
  } else if (response && response.status === 200) {
    yield put(actions.unfollowUserSuccess(response.status));
  } else if (response && (response.status === 403 || response.status === 401)) {
    yield put(actions.userLogout(action.token, action.server));
  } else {
    yield put(actions.unfollowUserFail(response));
  }
}

// ======================================

export function* blockUserSaga(action) {
  yield put(actions.blockUserStart());
  const headers = {
    "X-Auth-Token": action.token
  };
  const response = yield POST(
    `${action.server}api/userBlockedUser`,
    {
      blockedUserId: action.id
    },
    headers
  );
  if (response === 503) {
    yield put(actions.serverErrorSuccess(response));
  } else if (response && response === 451) {
    yield put(actions.permissionErrorSuccess(Math.random()));
  } else if (response && response.status === 200) {
    yield put(actions.blockUserSuccess(response.data));
  } else if (response && (response.status === 403 || response.status === 401)) {
    yield put(actions.userLogout(action.token, action.server));
  } else {
    yield put(actions.blockUserFail(response));
  }
}

// ======================================

export function* unblockUserSaga(action) {
  yield put(actions.unblockUserStart());
  const headers = {
    "X-Auth-Token": action.token
  };
  const response = yield DELETE(
    `${action.server}api/userBlockedUser`,
    headers,
    {
      blockedUserId: action.userId,
      id: action.id
    }
  );
  if (response && response === 503) {
    yield put(actions.serverErrorSuccess(response));
  } else if (response && response === 451) {
    yield put(actions.permissionErrorSuccess(Math.random()));
  } else if (response && response.status === 200) {
    yield put(actions.unblockUserSuccess(response.status));
  } else if (response && (response.status === 403 || response.status === 401)) {
    yield put(actions.userLogout(action.token, action.server));
  } else {
    yield put(actions.unblockUserFail(response));
  }
}

// ======================================

export function* getBlockedUsersSaga(action) {
  yield put(actions.getBlockedUsersStart());
  let data = {
    pageModel: {
      orders: [
        {
          dir: action.searchModel.dir ? action.searchModel.dir : "DESC",
          property: action.searchModel.property
            ? action.searchModel.property
            : "id"
        }
      ],
      pageNumber: action.searchModel.pageNumber,
      pageSize: action.searchModel.pageSize
    },
    searchModel: {
      blockedUserId: action.searchModel.blockedUserId
        ? action.searchModel.blockedUserId
        : "",
      userId: action.searchModel.userId ? action.searchModel.userId : ""
    }
  };
  const headers = {
    "content-type": "application/json",
    "X-Auth-Token": action.token
  };

  const response = yield POST(
    `${action.server}api/userBlockedUser/search`,
    data,
    headers
  );
  if (response && response === 503) {
    yield put(actions.serverErrorSuccess(response));
  }
  if (response && response.status === 200) {
    yield put(actions.getBlockedUsersSuccess(response.data));
  } else if (response && (response.status === 403 || response.status === 401)) {
    yield put(actions.userLogout(action.token, action.server));
  } else {
    yield put(actions.getBlockedUsersFail(response));
  }
}

// ====================================== GET FOLLOWERS

export function* getFollowersSaga(action) {
  try {
    yield put(actions.getFollowersStart());
    let data = {
      pageModel: {
        orders: [
          {
            dir: action.searchModel.order,
            property: action.searchModel.property
              ? action.searchModel.property
              : "id"
          }
        ],
        pageNumber: action.searchModel.pageNumber,
        pageSize: action.searchModel.pageSize
      },
      searchModel: {
        excludeBlockedUsers: action.searchModel.excludeBlockedUsers
          ? action.searchModel.excludeBlockedUsers
          : false,
        followedUserId: action.searchModel.userId
          ? action.searchModel.userId
          : null,
        name: action.searchModel.name ? action.searchModel.name : null,
        userId: action.searchModel.ownerUserId
          ? action.searchModel.ownerUserId
          : null
      }
    };
    const headers = {
      "content-type": "application/json",
      "X-Auth-Token": action.token
    };

    const response = yield POST(
      `${action.server}api/socializing/userFollow/search`,
      data,
      headers
    );
    if (response && response === 503) {
      yield put(actions.serverErrorSuccess(response));
    }
    if (response && response.status === 200) {
      yield put(actions.getFollowersSuccess(response.data));
    } else if (
      response &&
      (response.status === 403 || response.status === 401)
    ) {
      yield put(actions.userLogout(action.token, action.server));
    } else {
      yield put(actions.getFollowersFail(response));
    }
  } finally {
    if (yield cancelled()) yield put(actions.getFollowersCancel());
  }
}

// ====================================== GET FOLLOWINGS

export function* getFollowingsSaga(action) {
  try {
    yield put(actions.getFollowingsStart());
    let data = {
      pageModel: {
        orders: [
          {
            dir: action.searchModel.order,
            property: action.searchModel.property
              ? action.searchModel.property
              : "id"
          }
        ],
        pageNumber: action.searchModel.pageNumber,
        pageSize: action.searchModel.pageSize
      },
      searchModel: {
        excludeBlockedUsers: action.searchModel.excludeBlockedUsers
          ? action.searchModel.excludeBlockedUsers
          : false,
        userId: action.searchModel.userId ? action.searchModel.userId : null,
        name: action.searchModel.name ? action.searchModel.name : null
      }
    };
    const headers = {
      "content-type": "application/json",
      "X-Auth-Token": action.token
    };

    const response = yield POST(
      `${action.server}api/socializing/userFollow/search`,
      data,
      headers
    );
    if (response && response === 503) {
      yield put(actions.serverErrorSuccess(response));
    }
    if (response && response.status === 200) {
      yield put(actions.getFollowingsSuccess(response.data));
    } else if (
      response &&
      (response.status === 403 || response.status === 401)
    ) {
      yield put(actions.userLogout(action.token, action.server));
    } else {
      yield put(actions.getFollowingsFail(response));
    }
  } finally {
    if (yield cancelled()) yield put(actions.getFollowingsCancel());
  }
}
