import * as actionTypes from "./actionTypes";

// ================================================================================= CREATE INVOICE
export const createInvoice = (token, req, server) => {
  return {
    type: actionTypes.CREATE_INVOICE,
    token: token,
    req: req,
    server: server
  };
};

export const createInvoiceStart = () => {
  return {
    type: actionTypes.CREATE_INVOICE_START
  };
};

export const createInvoiceSuccess = response => {
  return {
    type: actionTypes.CREATE_INVOICE_SUCCESS,
    response: response
  };
};

export const createInvoiceFail = error => {
  return {
    type: actionTypes.CREATE_INVOICE_FAIL,
    error: error
  };
};

// ================================================================================= CHECK PAID

export const checkPaid = (token, req, server) => {
  return {
    type: actionTypes.CHECK_PAID,
    token: token,
    req: req,
    server: server
  };
};

export const checkPaidStart = () => {
  return {
    type: actionTypes.CHECK_PAID_START
  };
};

export const checkPaidClear = () => {
  return {
    type: actionTypes.CHECK_PAID_CLEAR
  };
};

export const checkPaidSuccess = paymentResponse => {
  return {
    type: actionTypes.CHECK_PAID_SUCCESS,
    paymentResponse: paymentResponse
  };
};
export const checkPaidFail = error => {
  return {
    type: actionTypes.CHECK_PAID_FAIL,
    error: error
  };
};

// ================================================================================= SEARCH INVOICE
export const searchInvoiceHistory = (token, req, server) => {
  return {
    type: actionTypes.SEARCH_INVOICE_HISTORY,
    token: token,
    req: req,
    server: server
  };
};

export const searchInvoiceHistoryStart = () => {
  return {
    type: actionTypes.SEARCH_INVOICE_HISTORY_START
  };
};

export const searchInvoiceHistorySuccess = history => {
  return {
    type: actionTypes.SEARCH_INVOICE_HISTORY_SUCCESS,
    history: history
  };
};

export const searchInvoiceHistoryFail = error => {
  return {
    type: actionTypes.SEARCH_INVOICE_HISTORY_FAIL,
    error: error
  };
};

// ================================================================================= GET INVOICE BY ID
export const getInvoiceById = (token, id, server) => {
  return {
    type: actionTypes.GET_INVOICE_BY_ID,
    token: token,
    id: id,
    server: server
  };
};

export const getInvoiceByIdStart = () => {
  return {
    type: actionTypes.GET_INVOICE_BY_ID_START
  };
};

export const getInvoiceByIdSuccess = invoice => {
  return {
    type: actionTypes.GET_INVOICE_BY_ID_SUCCESS,
    invoice: invoice
  };
};

export const getInvoiceByIdFail = error => {
  return {
    type: actionTypes.GET_INVOICE_BY_ID_FAIL,
    error: error
  };
};
