import * as actionTypes from './actionTypes'

export const addTotalView = (token,object,server) => {
    return {
      type: actionTypes.ADD_TOTAL_VIEW,
      token:token,
      object:object,
      server:server
    };
  };
  
  export const addTotalViewStart = () => {
    return {
      type: actionTypes.ADD_TOTAL_VIEW_START
    };
  };
  
  export const addTotalViewSuccess = response => {
    return {
      type: actionTypes.ADD_TOTAL_VIEW_SUCCESS,
      response:response
    };
  };
  export const addTotalViewFail = error => {
    return {
      type: actionTypes.ADD_TOTAL_VIEW_FAIL,
      error: error
    };
  };


  // ==================== LIVE VIEW

  export const addLiveView = (token,object,server) => {
    return {
      type: actionTypes.ADD_LIVE_VIEW,
      token:token,
      object:object,
      server:server
    };
  };
  
  export const addLiveViewStart = () => {
    return {
      type: actionTypes.ADD_LIVE_VIEW_START
    };
  };
  
  export const addLiveViewSuccess = liveResponse => {
    return {
      type: actionTypes.ADD_LIVE_VIEW_SUCCESS,
      liveResponse:liveResponse
    };
  };
  export const addLiveViewFail = error => {
    return {
      type: actionTypes.ADD_LIVE_VIEW_FAIL,
      error: error
    };
  };

  // ============

  export const deleteLiveView = (token,object,server) => {
    return {
      type: actionTypes.DELETE_LIVE_VIEW,
      token:token,
      object:object,
      server:server
    };
  };
  
  export const deleteLiveViewStart = () => {
    return {
      type: actionTypes.DELETE_LIVE_VIEW_START
    };
  };
  
  export const deleteLiveViewSuccess = liveResponse => {
    return {
      type: actionTypes.DELETE_LIVE_VIEW_SUCCESS,
      liveResponse:liveResponse
    };
  };
  export const deleteLiveViewFail = error => {
    return {
      type: actionTypes.DELETE_LIVE_VIEW_FAIL,
      error: error
    };
  };
