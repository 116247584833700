import * as actionTypes from "../actions/actionTypes";

const initialState = {
  response: null,
  loading: false,
  error: null
};

const changePasswordStart = state => {
  const newState = {
    ...state,
    loading: true,
    error: null,
    response: null
  };
  return newState;
};
const changePasswordSuccess = (state, action) => {
  const newState = {
    ...state,
    loading: false,
    response: action.response,
    error: null
  };
  return newState;
};

const changePasswordFail = (state, action) => {
  const newState = {
    ...state,
    response: null,
    loading: false,
    error: action.error
  };
  return newState;
};

export const changePasswordReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CHANGE_PASSWORD_START:
      return changePasswordStart(state);
    case actionTypes.CHANGE_PASSWORD_SUCCESS:
      return changePasswordSuccess(state, action);
    case actionTypes.CHANGE_PASSWORD_FAIL:
      return changePasswordFail(state, action);
    default:
      return state;
  }
};
