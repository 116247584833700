import moment from "moment";

export const calcDuration = (startDate, endDate) => {
  const start = new Date(startDate);
  const end = new Date(endDate);
  let seconds = Math.floor((end - start) / 1000);
  let minutes = Math.floor(seconds / 60);
  let hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  hours = hours - days * 24;
  minutes = minutes - days * 24 * 60 - hours * 60;
  seconds = seconds - days * 24 * 60 * 60 - hours * 60 * 60 - minutes * 60;
  let finalDate = "";
  if (days > 0) {
    finalDate += days + " Days, ";
  }
  if (hours > 0) {
    finalDate +=
      hours.toString().length === 1 ? "0" + hours + ":" : hours + ":";
  }
  finalDate +=
    minutes.toString().length === 1 ? "0" + minutes + ":" : minutes + ":";
  finalDate += seconds.toString().length === 1 ? "0" + seconds : seconds;
  return finalDate;
};

// ========================================================

export const calcEndDate = (startDate, timeDuration) => {
  const duration = timeDuration.split(":");
  const hours = parseInt(duration[0]);
  const minutes = parseInt(duration[1]);
  const date = new Date(startDate);
  date.setMinutes(date.getMinutes() + minutes);
  date.setHours(date.getHours() + hours);
  const endDate =
    date.getFullYear() +
    "/" +
    ("0" + (date.getMonth() + 1)).slice(-2) +
    "/" +
    ("0" + date.getDate()).slice(-2) +
    " " +
    ("0" + date.getHours()).slice(-2) +
    ":" +
    ("0" + date.getMinutes()).slice(-2);
  return endDate;
};

// ========================================================

export const localToUTC = date => {
  const localDate = new Date(date);
  const formattedDate =
    localDate.getUTCFullYear() +
    "/" +
    ("0" + (localDate.getUTCMonth() + 1)).slice(-2) +
    "/" +
    ("0" + localDate.getUTCDate()).slice(-2) +
    " " +
    ("0" + localDate.getUTCHours()).slice(-2) +
    ":" +
    ("0" + localDate.getUTCMinutes()).slice(-2);
  return formattedDate;
};

// ===========================================================

export const utcToLocal = (date, format) => {
  const justDate = date.split(" ")[0];
  const time = date.split(" ")[1];
  const splitTime = time.split(":");
  const splitDate = justDate.split("/");
  const utcDate = new Date(
    Date.UTC(
      splitDate[0],
      splitDate[1] - 1,
      splitDate[2],
      splitTime[0],
      splitTime[1]
    )
  );
  const localDate = new Date(utcDate);
  const formattedDate =
    localDate.getFullYear() +
    "/" +
    ("0" + (localDate.getMonth() + 1)).slice(-2) +
    "/" +
    ("0" + localDate.getDate()).slice(-2) +
    " " +
    ("0" + localDate.getHours()).slice(-2) +
    ":" +
    ("0" + localDate.getMinutes()).slice(-2);
  if (format === "date") {
    return formattedDate.split(" ")[0];
  } else if (format === "time") {
    return formattedDate.split(" ")[1];
  } else {
    return formattedDate;
  }
};

// ============================================================== Convert millisecond to time
export const msToTime = duration => {
  let seconds = Math.floor((duration / 1000) % 60),
    minutes = Math.floor((duration / (1000 * 60)) % 60),
    hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

  hours = hours < 10 ? "0" + hours : hours;
  minutes = minutes < 10 ? "0" + minutes : minutes;
  seconds = seconds < 10 ? "0" + seconds : seconds;
  if (hours === "00") {
    return minutes + ":" + seconds;
  } else {
    return hours + ":" + minutes + ":" + seconds;
  }
};
// ============================================================= Normalization, Float to duration Time
export const floatToDuration = seconds => {
  const date = new Date(seconds * 1000);
  const hh = date.getUTCHours();
  const mm = date.getUTCMinutes();
  const ss = pad(date.getUTCSeconds());
  if (hh) {
    return `${hh}:${pad(mm)}:${ss}`;
  }
  return `${mm}:${ss}`;
};

const pad = string => {
  return ("0" + string).slice(-2);
};

// =============================================================== Normalization, Duration to float
export const durationToFloat = (val, min, max) => {
  return (val - min) / (max - min);
};
// =============================================================== Normalization, Duration to float
export const unNormilize = (val, min, max) => {
  return (val + min) * (max + min);
};
// ===============================================================
export const dateFromNow = date => {
  const todayDate = new Date();
  const exactDate = new Date(date);
  const difference_In_Time = todayDate.getTime() - exactDate.getTime();
  const difference_In_Days = difference_In_Time / (1000 * 3600 * 24);
  if (difference_In_Days < 6.5) {
    return moment(date.replace(/\//g, "-")).fromNow();
  } else {
    const month = moment(exactDate).format("MMM");
    const day = exactDate.getDate();
    let res = `${month} ${day}`;
    if (todayDate.getFullYear() > exactDate.getFullYear()) {
      res += `, ${exactDate.getFullYear()}`;
    }
    return res;
  }
};

// ================================================================
export const updateObject = (oldObject, updatedProperties) => {
  return {
    ...oldObject,
    ...updatedProperties
  };
};

// ==============================================

export const formatLongNumber = value => {
  if (value == 0) {
    return 0;
  } else {
    // hundreds
    if (value <= 999) {
      return value;
    }
    // thousands
    else if (value >= 1000 && value <= 999999) {
      return (value / 1000).toFixed(2) + "K";
    }
    // millions
    else if (value >= 1000000 && value <= 999999999) {
      return (value / 1000000).toFixed(2) + "M";
    }
    // billions
    else if (value >= 1000000000 && value <= 999999999999) {
      return (value / 1000000000).toFixed(2) + "B";
    } else return value;
  }
};
