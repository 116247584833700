export const errors = {
  joinEventManyTimes:
    'You have already joined this event; please proceed to your Profile and select the Videos tap',
  promotionCode: 'The promotion code does not exist.',
  currentPassword: 'Current password is not correct!',
  emailNotExist: 'You have entered an invalid email address. Please try again.',
  verificationCode: 'Invalid verification code.',
  authFailed: 'Incorrect username or password',
  duplicatedEmail: 'A user with this email address already exists.',
  joinCode: 'The code you entered is incorrect. Please try again.',
  eventCapacity: 'Fail to join, Video capacity is full!',
  joinOnPastEvent:
    'Sorry, the stream you are trying to join is no longer available.',
  connectingToServer: 'Cannot connect to the server. Please try again.',
  noSelectedCamera: 'There is no connected camera',
  tokenExpired: 'Your session has expired. Please log in again.',
  noCameraForEvent: 'This event does not have any camera selected',
  noOtherCameraAngle: 'There is no other camera angle available.',
  noStreamBroadcasting: 'No Signal, Please select another video camera ',
  serverError: 'Server Error',
  serverErrorDetail: 'Server not responding, Please try again later.',
  noCameraFound: 'This event has no signal.',
  dataDependancy:
    'Data dependency error please remove any dependencies for this user',
  emptyForm: 'Please fill out this field.',
  dublicatedPlans: 'This plan has already been added please change the key',
  dublicatedUserRole:
    'This User has been assigned this roles already, please change the key',
  areYouSureYouWantToLogout: 'Are you sure you want to logout ?',
  locationPermissionDenied: 'Location Access Required',
  locationPermissionDeniedDetail:
    'Please grant your web browser permission to use your webcam.',
  cacheFailed: 'Failed to clear cache!',
  invalidCamera: 'This video event was configured for {{device}} broadcast.',
  invalidCameraDetail: 'Please broadcast with your {{device}}.',
  cameraLimitation: "You can't select more than {{cameraCount}} streams",
  blockedByOtp: 'Invalid code. Try again.',
  userJoinedHisEventMessage:
    'Join code is for other group members to join an existing event, if you intend to add a new stream to your existing event just Quick Live again and follow the steps.',
  multiDetectedBroadcast: 'You are currently streaming live.',
  multiDetectedBroadcastDetail:
    'You already have a live streaming in process on another device, to start a new stream on this device create a new event.',
  tagsLimitation: 'The character limit for hashtags is 50.',
  pastTime: 'You cannot select the past time!',
  gifNotSupported: 'GIF is not supported',
  networkError: 'Network Error',
  networkErrorDetail: 'Please check your network connection',
  thisVideoHasCanceled: 'This Video has canceled!',
  commentsLimitation:
    'Text exceeds the limit of 300 characters. Please reduce the number of characters before submitting.',
  enableCameraAndAudio:
    'Please go to settings and give permission for camera and microphone in order to start an event.',
  failedToLoadCamera:
    'The browser was unable to load the camera preview. It may be helpful to try a different browser.',
  cameraConnection: 'A camera has been detected, but no camera signal',
  premiumComingSoon: 'You have reached the limit of your current plan.',
  newVersionAvailable: 'New Version Available',
  newVersionAvailableDetail:
    'There is a newer version available for download! Please update your app to continue.',
  noAudioInput:
    'ViuLive Can’t detect your microphone. Please make sure your microphone is properly connected.',
  passwordShouldBeDifferent:
    'Your new password cannot be the same as your old password',
  enablePicturePermission:
    'Please Allow Photo Access, Please go to the Setting and give permission.'
}
