import * as actionTypes from '../actions/actionTypes'

const initialState = {
  notifications: null,
  loading: false,
  error: null,
  unreadNotification: null
}

const getNotificationsStart = state => {
  const newState = {
    ...state,
    notifications: null,
    loading: true,
    error: null
  }
  return newState
}
const getNotificationsSuccess = (state, action) => {
  const newState = {
    ...state,
    notifications: action.payload,
    loading: false,
    error: null
  }
  return newState
}
const getNotificationsNumberSuccess = (state, action) => {
  const newState = {
    ...state,
    unreadNotification: action.payload
  }
  return newState
}

const getNotificationsFail = (state, action) => {
  const newState = {
    ...state,
    notifications: null,
    loading: false,
    error: action.payload
  }
  return newState
}
const getNotificationsClear = (state, action) => {
  const newState = {
    ...state,
    notifications: null,
    loading: false,
    error: null
  }
  return newState
}

export const profileNotificationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_MY_NOTIFICATIONS_START:
      return getNotificationsStart(state)
    case actionTypes.GET_MY_NOTIFICATIONS_SUCCESS:
      return getNotificationsSuccess(state, action)
    case actionTypes.GET_MY_NOTIFICATIONS_ERROR:
      return getNotificationsFail(state, action)
    case actionTypes.GET_MY_NOTIFICATIONS_CLEAR:
      return getNotificationsClear(state)
    case actionTypes.GET_MY_NOTIFICATIONS_NUMBER:
      return getNotificationsNumberSuccess(state, action)
    default:
      return state
  }
}
