import { put } from "redux-saga/effects";
import * as actions from "../actions/index";
import { GET } from "../callApiWrapper";

export function* getRandomTagsSaga(action) {
  yield put(actions.getRandomTagsStart());
  const headers = {
    "content-type": "application/json"
  };
  const response = yield GET(
    `${action.server}api/vume/event/randomlyTags`,
    headers
  );
  if (response && response === 503) {
    yield put(actions.serverErrorSuccess(response));
  } else if (response && response.status === 200) {
    yield put(actions.getRandomTagsSuccess(response));
  } else if (response && (response.status === 403 || response.status === 401)) {
    yield put(actions.userLogout(action.token, action.server));
  } else {
    yield put(actions.getRandomTagsFail(response));
  }
}
