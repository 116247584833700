import * as actionTypes from "../actions/actionTypes";

const initialState = {
  locations: null
};

const getLocationsStart = state => {
  const newState = {
    ...state
  };
  return newState;
};
const getLocationsSuccess = (state, action) => {
  const newState = {
    ...state,
    locations: action.locations
  };
  return newState;
};

const getLocationsFail = (state, action) => {
  const newState = {
    ...state
  };
  return newState;
};

export const locationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_LOCATIONS_START:
      return getLocationsStart(state);
    case actionTypes.GET_LOCATIONS_SUCCESS:
      return getLocationsSuccess(state, action);
    case actionTypes.GET_LOCATIONS_FAIL:
      return getLocationsFail(state, action);
    default:
      return state;
  }
};
