import { put } from "redux-saga/effects";
import * as actions from "../actions/index";
import { POST, DELETE } from "../callApiWrapper";

export function* addNotificationsTokenSaga(action) {
  yield put(actions.addNotificationTokenStart());
  const headers = {
    "X-Auth-Token": action.token,
    "Content-type": "application/json"
  };
  const response = yield POST(
    `${action.server}api/messaging/userNotification`,
    { mobileNotificationToken: action.fcmToken },
    headers
  ); 

  if (response && response === 503) {
    yield put(actions.serverErrorSuccess(response));
  } else if (response && response.status === 200) {
    yield put(actions.addNotificationTokenSuccess(response.data));
  } else if (response && (response.status === 403 || response.status === 401)) {
    yield put(actions.userLogout(action.token, action.server));
  } else {
    yield put(actions.addNotificationTokenFail(response));
  }
}

export function* deleteNotificationsTokenSaga(action) {
  yield put(actions.deleteNotificationTokenStart());
  const headers = {
    "X-Auth-Token": action.token,
    "Content-type": "application/json"
  };
  const response = yield DELETE(
    `${action.server}api/messaging/userNotification/${action.fcmToken}`,
    headers
  );

  if (response === 503) {
    yield put(actions.serverErrorSuccess(response));
  } else if (response.status === 200) {
    yield put(actions.deleteNotificationTokenSuccess(response.status));
  } else if (response && (response.status === 403 || response.status === 401)) {
    yield put(actions.userLogout(action.token, action.server));
  } else {
    yield put(actions.deleteNotificationTokenFail(response));
  }
}
