import * as actionTypes from "./actionTypes";

export const getUserProfile = (token, id, server) => {
  return {
    type: actionTypes.GET_USER_PROFILE,
    token: token,
    id: id,
    server: server
  };
};

export const getUserProfileStart = () => {
  return {
    type: actionTypes.GET_USER_PROFILE_START
  };
};

export const getUserProfileSuccess = user => {
  return {
    type: actionTypes.GET_USER_PROFILE_SUCCESS,
    user: user
  };
};
export const getUserProfileFail = error => {
  return {
    type: actionTypes.GET_USER_PROFILE_FAIL,
    error: error
  };
};

// ================================================ FOLLOW

export const followUser = (token, object, server) => {
  return {
    type: actionTypes.FOLLOW_USER,
    token: token,
    object: object,
    server: server
  };
};

export const followUserStart = () => {
  return {
    type: actionTypes.FOLLOW_USER_START
  };
};

export const followUserSuccess = followResponse => {
  return {
    type: actionTypes.FOLLOW_USER_SUCCESS,
    followResponse: followResponse
  };
};
export const followUserFail = error => {
  return {
    type: actionTypes.FOLLOW_USER_FAIL,
    error: error
  };
};

// ================================================ UNFOLLOW

export const unfollowUser = (token, object, server) => {
  return {
    type: actionTypes.UNFOLLOW_USER,
    token: token,
    object: object,
    server: server
  };
};

export const unfollowUserStart = () => {
  return {
    type: actionTypes.UNFOLLOW_USER_START
  };
};

export const unfollowUserSuccess = followResponse => {
  return {
    type: actionTypes.UNFOLLOW_USER_SUCCESS,
    followResponse: followResponse
  };
};
export const unfollowUserFail = error => {
  return {
    type: actionTypes.UNFOLLOW_USER_FAIL,
    error: error
  };
};

// ================================================ BLOCK USER

export const blockUser = (token, id, server) => {
  return {
    type: actionTypes.BLOCK_USER,
    token: token,
    id: id,
    server: server
  };
};

export const blockUserStart = () => {
  return {
    type: actionTypes.BLOCK_USER_START
  };
};

export const blockUserSuccess = blockResponse => {
  return {
    type: actionTypes.BLOCK_USER_SUCCESS,
    blockResponse: blockResponse
  };
};
export const blockUserFail = error => {
  return {
    type: actionTypes.BLOCK_USER_FAIL,
    error: error
  };
};

// ================================================ UNBLOCK USER

export const unblockUser = (token, id, userId, server) => {
  return {
    type: actionTypes.UNBLOCK_USER,
    token: token,
    id: id,
    userId: userId,
    server: server
  };
};

export const unblockUserStart = () => {
  return {
    type: actionTypes.UNBLOCK_USER_START
  };
};

export const unblockUserSuccess = blockResponse => {
  return {
    type: actionTypes.UNBLOCK_USER_SUCCESS,
    blockResponse: blockResponse
  };
};
export const unblockUserFail = error => {
  return {
    type: actionTypes.UNBLOCK_USER_FAIL,
    error: error
  };
};

// ================================================ BLOCKED USERS

export const getBlockedUsers = (token, searchModel, server) => {
  return {
    type: actionTypes.GET_BLOCKED_USERS,
    token: token,
    searchModel: searchModel,
    server: server
  };
};

export const getBlockedUsersStart = () => {
  return {
    type: actionTypes.GET_BLOCKED_USERS_START
  };
};

export const getBlockedUsersSuccess = blockedUsers => {
  return {
    type: actionTypes.GET_BLOCKED_USERS_SUCCESS,
    blockedUsers: blockedUsers
  };
};
export const getBlockedUsersFail = error => {
  return {
    type: actionTypes.GET_BLOCKED_USERS_FAIL,
    error: error
  };
};

// ================================================ GET FOLLOWERS

export const getFollowers = (token, searchModel, server) => {
  return {
    type: actionTypes.GET_FOLLOWERS,
    token: token,
    searchModel: searchModel,
    server: server
  };
};

export const getFollowersStart = () => {
  return {
    type: actionTypes.GET_FOLLOWERS_START
  };
};

export const getFollowersSuccess = followers => {
  return {
    type: actionTypes.GET_FOLLOWERS_SUCCESS,
    followers: followers
  };
};
export const getFollowersFail = error => {
  return {
    type: actionTypes.GET_FOLLOWERS_FAIL,
    error: error
  };
};
export const getFollowersCancel = () => {
  return {
    type: actionTypes.GET_FOLLOWERS_CANCEL
  };
};

export const getFollowersClear = () => {
  console.log("clear data");
  return {
    type: actionTypes.GET_FOLLOWERS_CLEAR
  };
};
// ================================================ GET FOLLOWINGS

export const getFollowings = (token, searchModel, server) => {
  return {
    type: actionTypes.GET_FOLLOWINGS,
    token: token,
    searchModel: searchModel,
    server: server
  };
};

export const getFollowingsStart = () => {
  return {
    type: actionTypes.GET_FOLLOWINGS_START
  };
};

export const getFollowingsSuccess = followings => {
  return {
    type: actionTypes.GET_FOLLOWINGS_SUCCESS,
    followings: followings
  };
};
export const getFollowingsFail = error => {
  return {
    type: actionTypes.GET_FOLLOWINGS_FAIL,
    error: error
  };
};

export const getFollowingsCancel = () => {
  return {
    type: actionTypes.GET_FOLLOWINGS_CANCEL
  };
};
export const getFollowingsClear = () => {
  console.log("clear data");
  return {
    type: actionTypes.GET_FOLLOWINGS_CLEAR
  };
};
