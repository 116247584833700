import * as actionTypes from './actionTypes'


export const getRandomTags = (server) => {
    return {
      type: actionTypes.GET_RANDOM_TAGS,
      server:server,
    };
  };
  export const getRandomTagsStart = () => {
    return {
      type: actionTypes.GET_RANDOM_TAGS_START,
    };
  };
  export const getRandomTagsSuccess = tags => {
    return {
      type: actionTypes.GET_RANDOM_TAGS_SUCCESS,
      tags: tags,
    };
  };
  
  export const getRandomTagsFail = error => {
    return {
      type: actionTypes.GET_RANDOM_TAGS_FAIL,
      error: error
    };
  };