import React from "react";
import moment from "moment";
import { DatePicker as DateSelector } from "antd";
import PropTypes from "prop-types";

export const DatePicker = props => {
  const dateFormat = props.format ? props.format : "YYYY/MM/DD";
  const disabledDate = startValue => {
    const endValue = props.maximumDay;
    if (!startValue || !endValue) {
      return false;
    }
    return startValue.valueOf() > endValue.valueOf();
  };
  return (
    <DateSelector
      className={`MSD-DatePicker ${props.className}`}
      style={props.style}
      format={dateFormat}
      disabledDate={disabledDate}
      defaultValue={
        props.defaultValue ? moment(props.defaultValue, dateFormat) : null
      }
      onChange={(date, dateString) =>
        props.onChange(dateString.replace(/-/g, "/"))
      }
      inputReadOnly={props.inputReadOnly ? props.inputReadOnly : false}
    />
  );
};

DatePicker.propTypes = {
  onChange: PropTypes.func.isRequired,
  format: PropTypes.string,
  className: PropTypes.string,
  defaultValue: PropTypes.string,
  style: PropTypes.object,
  inputReadOnly: PropTypes.bool
};
