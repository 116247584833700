import * as actionTypes from './actionTypes'


export const userLogin = (username,password,server) => {
    return {
      type: actionTypes.USER_LOGIN,
      username: username,
      password: password,
      server:server
    };
  };

  export const userLoginStart = () => {
    return {
      type: actionTypes.USER_LOGIN_START
    };
  };
  
  export const userLoginSuccess = user => {
    return {
      type: actionTypes.USER_LOGIN_SUCCESS,
      user:user
    };
  };
  export const userLoginFail = error => {
    return {
      type: actionTypes.USER_LOGIN_FAIL,
      error: error
    };
  };
  
 

// ============================================================

export const userLogout = (token,server) =>{
  return{
    type:actionTypes.USER_LOGOUT,
    token:token,
    server:server
  }
}
export const userLogoutSuccess = (logout) =>{
  return{
    type:actionTypes.USER_LOGOUT_SUCCESS,
    logout:logout
  }
}
// ============================================================= FORGOT PASSWORD


export const forgotPasswordSendCode = (email,server) => {
  return {
    type: actionTypes.FORGOT_PASSWORD_SEND_CODE,
    email: email,
    server:server
  };
};

export const forgotPasswordSendCodeStart = () => {
  return {
    type: actionTypes.FORGOT_PASSWORD_SEND_CODE_START
  };
};

export const forgotPasswordSendCodeSuccess = response => {
  return {
    type: actionTypes.FORGOT_PASSWORD_SEND_CODE_SUCCESS,
    response:response
  };
};
export const forgotPasswordSendCodeFail = error => {
  return {
    type: actionTypes.FORGOT_PASSWORD_SEND_CODE_FAIL,
    error: error
  };
};


// ========================= CHECK CODE

export const forgotPasswordCheckCode = (email,code,server) => {
  return {
    type: actionTypes.FORGOT_PASSWORD_CHECK_CODE,
    email: email,
    code:code,
    server:server
  };
};

export const forgotPasswordCheckCodeStart = () => {
  return {
    type: actionTypes.FORGOT_PASSWORD_CHECK_CODE_START
  };
};

export const forgotPasswordCheckCodeSuccess = response => {
  return {
    type: actionTypes.FORGOT_PASSWORD_CHECK_CODE_SUCCESS,
    response:response
  };
};
export const forgotPasswordCheckCodeFail = error => {
  return {
    type: actionTypes.FORGOT_PASSWORD_CHECK_CODE_FAIL,
    error: error
  };
};



// ============= FORGOT PASSWORD CHANGE PASSWORD

export const forgotPasswordChangePassword = (email,code,newPassword,server) => {
  return {
    type: actionTypes.FORGOT_PASSWORD_CHANGE_PASSWORD,
    email: email,
    code:code,
    newPassword:newPassword,
    server:server
  };
};

export const forgotPasswordChangePasswordStart = () => {
  return {
    type: actionTypes.FORGOT_PASSWORD_CHANGE_PASSWORD_START
  };
};

export const forgotPasswordChangePasswordSuccess = response => {
  return {
    type: actionTypes.FORGOT_PASSWORD_CHANGE_PASSWORD_SUCCESS,
    response:response
  };
};
export const forgotPasswordChangePasswordFail = error => {
  return {
    type: actionTypes.FORGOT_PASSWORD_CHANGE_PASSWORD_FAIL,
    error: error
  };
};


// ============================================================= USER REGISTER SENDCODE


export const userRegisterSendCode = (object,server) => {
  return {
    type: actionTypes.USER_REGISTER_SEND_CODE,
    object: object,
    server:server
  };
};

export const userRegisterSendCodeStart = () => {
  return {
    type: actionTypes.USER_REGISTER_SEND_CODE_START
  };
};

export const userRegisterSendCodeSuccess = response => {
  return {
    type: actionTypes.USER_REGISTER_SEND_CODE_SUCCESS,
    response:response
  };
};
export const userRegisterSendCodeFail = error => {
  return {
    type: actionTypes.USER_REGISTER_SEND_CODE_FAIL,
    error: error
  };
};


// ============================================================= USER REGISTER REGISTER


export const userRegisterRegister = (object,server) => {
  return {
    type: actionTypes.USER_REGISTER_REGISTER,
    object: object,
    server:server
  };
};

export const userRegisterRegisterStart = () => {
  return {
    type: actionTypes.USER_REGISTER_REGISTER_START
  };
};

export const userRegisterRegisterSuccess = response => {
  return {
    type: actionTypes.USER_REGISTER_REGISTER_SUCCESS,
    response:response
  };
};
export const userRegisterRegisterFail = error => {
  return {
    type: actionTypes.USER_REGISTER_REGISTER_FAIL,
    error: error
  };
};


// ====================================================== USER SOCIAL LOGIN
export const userSocialLogin = (object,server) => {
  return {
    type: actionTypes.USER_SOCIAL_LOGIN,
    object: object,
    server:server
  };
};
export const userSocialLoginStart = () => {
  return {
    type: actionTypes.USER_SOCIAL_LOGIN_START
  };
};

export const userSocialLoginSuccess = response => {
  return {
    type: actionTypes.USER_SOCIAL_LOGIN_SUCCESS,
    response:response
  };
};
export const userSocialLoginFail = error => {
  return {
    type: actionTypes.USER_SOCIAL_LOGIN_FAIL,
    error: error
  };
};