import * as actionTypes from "./actionTypes";

// ================================================================================= GET VIUBIT BALANCE
export const getViubitBalance = (token, server) => {
  return {
    type: actionTypes.GET_VIUBIT_BALANCE,
    token: token,
    server: server
  };
};

export const getViubitBalanceStart = () => {
  return {
    type: actionTypes.GET_VIUBIT_BALANCE_START
  };
};

export const getViubitBalanceSuccess = balance => {
  return {
    type: actionTypes.GET_VIUBIT_BALANCE_SUCCESS,
    balance: balance
  };
};

export const getViubitBalanceFail = error => {
  return {
    type: actionTypes.GET_VIUBIT_BALANCE_FAIL,
    error: error
  };
};

// ================================================================================= SEND GIFT
export const sendGift = (token, req, server) => {
  return {
    type: actionTypes.SEND_GIFT,
    token: token,
    req: req,
    server: server
  };
};

export const sendGiftStart = () => {
  return {
    type: actionTypes.SEND_GIFT_START
  };
};

export const sendGiftSuccess = response => {
  return {
    type: actionTypes.SEND_GIFT_SUCCESS,
    response: response
  };
};

export const sendGiftFail = error => {
  return {
    type: actionTypes.SEND_GIFT_FAIL,
    error: error
  };
};

// ================================================================================= GET ALL VIUBIT PACKAGES
export const getAllViubitPackages = (token, server) => {
  return {
    type: actionTypes.GET_ALL_VIUBIT_PACKAGES,
    token: token,
    server: server
  };
};

export const getAllViubitPackagesStart = () => {
  return {
    type: actionTypes.GET_ALL_VIUBIT_PACKAGES_START
  };
};

export const getAllViubitPackagesSuccess = packages => {
  return {
    type: actionTypes.GET_ALL_VIUBIT_PACKAGES_SUCCESS,
    packages: packages
  };
};

export const getAllViubitPackagesFail = error => {
  return {
    type: actionTypes.GET_ALL_VIUBIT_PACKAGES_FAIL,
    error: error
  };
};

// ================================================================================= VIUBIT HISTORY
export const searchViubitHistory = (token, req, server) => {
  return {
    type: actionTypes.SEARCH_VIUBIT_HISTORY,
    token: token,
    req: req,
    server: server
  };
};

export const searchViubitHistoryStart = () => {
  return {
    type: actionTypes.SEARCH_VIUBIT_HISTORY_START
  };
};

export const searchViubitHistorySuccess = history => {
  return {
    type: actionTypes.SEARCH_VIUBIT_HISTORY_SUCCESS,
    history: history
  };
};

export const searchViubitHistoryFail = error => {
  return {
    type: actionTypes.SEARCH_VIUBIT_HISTORY_FAIL,
    error: error
  };
};


// ================================================================================= VIUBIT SUMMARY OF AN EVENT
export const getViuBitEventSummary = (eventId, token, server) => {
  return {
    type: actionTypes.GET_VIUBIT_EVENT_SUMMARY,
    token: token,
    server: server,
    eventId: eventId
  }
}

export const getViuBitEventSummaryStart = () => {
  return {
    type: actionTypes.GET_VIUBIT_EVENT_SUMMARY_START,
  }
}

export const getViuBitEventSummarySuccess = (summary) => {
  return {
    type: actionTypes.GET_VIUBIT_EVENT_SUMMARY_SUCCESS,
    summary:summary
  }
}

export const getViuBitEventSummaryFail = (error) => {
  return {
    type: actionTypes.GET_VIUBIT_EVENT_SUMMARY_FAIL,
    error:error
  }
}
