import * as actionTypes from "../actions/actionTypes";

const initialState = {
  error: null,
  loading: false,
  response: null,
  searchedEvents: null,
  searchError: null,
  reportedComments: null
};

// =========
const reportEventStart = state => {
  const newState = {
    ...state,
    loading: false,
    response: null
  };
  return newState;
};
const reportEventSuccess = (state, action) => {
  const newState = {
    ...state,
    response: action.response,
    loading: false,
    error: null
  };
  return newState;
};

const reportEventFail = (state, action) => {
  const newState = {
    ...state,
    response: null,
    loading: false,
    error: action.error
  };
  return newState;
};

// =========

const getReportedEventsStart = (state, action) => {
  const newState = {
    ...state,
    loading: true
  };
  if (!action.loadMore) {
    const eraseEvents = {
      ...newState,
      searchedEvents: null
    };
    return eraseEvents;
  }
  return newState;
};
const getReportedEventsSuccess = (state, action) => {
  const newState = {
    ...state,
    loading: false,
    response: action.response,
    searchError: null
  };
  return newState;
};

const getReportedEventsFail = (state, action) => {
  const newState = {
    ...state,
    loading: false,
    searchedEvents: null,
    searchError: action.error
  };
  return newState;
};

// ====================================================== COMMENTS

const reportCommentStart = state => {
  const newState = {
    ...state,
    loading: false,
    response: null
  };
  return newState;
};
const reportCommentSuccess = (state, action) => {
  const newState = {
    ...state,
    response: action.response,
    loading: false,
    error: null
  };
  return newState;
};

const reportCommentFail = (state, action) => {
  const newState = {
    ...state,
    response: null,
    loading: false,
    error: action.error
  };
  return newState;
};

// =========

const getReportedCommentsStart = state => {
  const newState = {
    ...state,
    loading: true,
    reportedComments: null
  };
  return newState;
};
const getReportedCommentsSuccess = (state, action) => {
  const newState = {
    ...state,
    reportedComments: action.reportedComments,
    loading: false,
    error: null
  };
  return newState;
};

const getReportedCommentsFail = (state, action) => {
  const newState = {
    ...state,
    reportedComments: null,
    loading: false,
    error: action.error
  };
  return newState;
};

// ============================== SHOULD GO TO SEPARATE FILE

const blockEventStart = (state, action) => {
  const newState = {
    ...state,
    response: null,
    loading: false
  };
  return newState;
};
const blockEventSuccess = (state, action) => {
  const newState = {
    ...state,
    loading: false,
    response: action.response,
    error: null
  };
  return newState;
};

const blockEventFail = (state, action) => {
  const newState = {
    ...state,
    loading: false,
    response: null,
    error: action.error
  };
  return newState;
};


// ==============================

export const reportsReducer = (state = initialState, action) => {
  switch (action.type) {
    // ==============================
    case actionTypes.REPORT_EVENT_START:
      return reportEventStart(state);
    case actionTypes.REPORT_EVENT_SUCCESS:
      return reportEventSuccess(state, action);
    case actionTypes.REPORT_EVENT_FAIL:
      return reportEventFail(state, action);

    case actionTypes.GET_REPORT_EVENT_START:
      return getReportedEventsStart(state, action);
    case actionTypes.GET_REPORT_EVENT_SUCCESS:
      return getReportedEventsSuccess(state, action);
    case actionTypes.GET_REPORT_EVENT_FAIL:
      return getReportedEventsFail(state, action);
    // ============================== COMMENTS

    case actionTypes.REPORT_COMMENT_START:
      return reportCommentStart(state);
    case actionTypes.REPORT_COMMENT_SUCCESS:
      return reportCommentSuccess(state, action);
    case actionTypes.REPORT_COMMENT_FAIL:
      return reportCommentFail(state, action);

    case actionTypes.GET_REPORTED_COMMENTS_START:
      return getReportedCommentsStart(state, action);
    case actionTypes.GET_REPORTED_COMMENTS_SUCCESS:
      return getReportedCommentsSuccess(state, action);
    case actionTypes.GET_REPORTED_COMMENTS_FAIL:
      return getReportedCommentsFail(state, action);

    // ============================== SHOULD GO TO SEPARATE FILE
    case actionTypes.BLOCK_EVENTS_START:
      return blockEventStart(state, action);
    case actionTypes.BLOCK_EVENTS_SUCCESS:
      return blockEventSuccess(state, action);
    case actionTypes.BLOCK_EVENTS_FAIL:
      return blockEventFail(state, action);
      
    // ==============================
    default:
      return state;
  }
};
