import * as actionTypes from "../actions/actionTypes";

const initialState = {
  user: null,
  loading: false,
  error: null,
  followResponse: null,
  blockResponse: null,
  blockedUsers: null,
  followers: null,
  followings: null
};

const getUserProfileStart = state => {
  const newState = {
    ...state,
    loading: true,
    error: null,
    user: null
  };
  return newState;
};
const getUserProfileSuccess = (state, action) => {
  const newState = {
    ...state,
    loading: false,
    user: action.user,
    error: null
  };
  return newState;
};

const getUserProfileFail = (state, action) => {
  const newState = {
    ...state,
    user: null,
    loading: false,
    error: action.error
  };
  return newState;
};

// =============================================================== FOLLOW

const followUserStart = state => {
  const newState = {
    ...state,
    error: null,
    followResponse: null,
    loading: true
  };
  return newState;
};
const followUserSuccess = (state, action) => {
  const newState = {
    ...state,
    loading: false,
    followResponse: action.followResponse,
    error: null
  };
  return newState;
};

const followUserFail = (state, action) => {
  const newState = {
    ...state,
    followResponse: null,
    loading: false,
    error: action.error
  };
  return newState;
};

// =============================================================== UNFOLLOW

const unfollowUserStart = state => {
  const newState = {
    ...state,
    loading: true,
    followResponse: null,
    error: null
  };
  return newState;
};
const unfollowUserSuccess = (state, action) => {
  const newState = {
    ...state,
    loading: false,
    followResponse: action.followResponse,
    error: null
  };
  return newState;
};

const unfollowUserFail = (state, action) => {
  const newState = {
    ...state,
    followResponse: null,
    loading: false,
    error: action.error
  };
  return newState;
};

// =============================================================== BLOCK

const blockUserStart = state => {
  const newState = {
    ...state,
    error: null,
    loading: true,
    blockResponse: null
  };
  return newState;
};
const blockUserSuccess = (state, action) => {
  const newState = {
    ...state,
    loading: false,
    blockResponse: action.blockResponse,
    error: null
  };
  return newState;
};

const blockUserFail = (state, action) => {
  const newState = {
    ...state,
    blockResponse: null,
    loading: false,
    error: action.error
  };
  return newState;
};

// =============================================================== UNBLOCK

const unblockUserStart = state => {
  const newState = {
    ...state,
    loading: true,
    blockResponse: null,
    error: null
  };
  return newState;
};
const unblockUserSuccess = (state, action) => {
  const newState = {
    ...state,
    loading: false,
    blockResponse: action.blockResponse,
    error: null
  };
  return newState;
};

const unblockUserFail = (state, action) => {
  const newState = {
    ...state,
    blockResponse: null,
    loading: false,
    error: action.error
  };
  return newState;
};

// =============================================================== BLOCKED USERS

const getBlockedUsersStart = state => {
  const newState = {
    ...state,
    loading: true,
    blockedUsers: null,
    error: null
  };
  return newState;
};
const getBlockedUsersSuccess = (state, action) => {
  const newState = {
    ...state,
    loading: false,
    blockedUsers: action.blockedUsers,
    error: null
  };
  return newState;
};

const getBlockedUsersFail = (state, action) => {
  const newState = {
    ...state,
    blockedUsers: null,
    loading: false,
    error: action.error
  };
  return newState;
};

// =============================================================== GET FOLLOWERS

const getFollowersStart = state => {
  const newState = {
    ...state,
    error: null,
    loading: true
  };
  return newState;
};
const getFollowersSuccess = (state, action) => {
  const newState = {
    ...state,
    loading: false,
    followers: action.followers,
    error: null
  };
  return newState;
};

const getFollowersFail = (state, action) => {
  const newState = {
    ...state,
    followers: null,
    loading: false,
    error: action.error
  };
  return newState;
};
const getFollowersCancel = state => {
  const newState = {
    ...state,
    error: null,
    loading: false,
    followers: null
  };
  return newState;
};
const getFollowersClear = state => {
  const newState = {
    ...state,
    error: null,
    loading: true,
    followers: null
  };
  return newState;
};
// =============================================================== GET FOLLOWINGS

const getFollowingsStart = state => {
  const newState = {
    ...state,
    error: null,
    loading: true
  };
  return newState;
};
const getFollowingsSuccess = (state, action) => {
  const newState = {
    ...state,
    loading: false,
    followings: action.followings,
    error: null
  };
  return newState;
};

const getFollowingsFail = (state, action) => {
  const newState = {
    ...state,
    followings: null,
    loading: false,
    error: action.error
  };
  return newState;
};
const getFollowingsCancel = state => {
  const newState = {
    ...state,
    error: null,
    loading: false,
    followings: null
  };
  return newState;
};
const getFollowingsClear = state => {
  const newState = {
    ...state,
    error: null,
    loading: true,
    followings: null
  };
  return newState;
};

export const socializingReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_USER_PROFILE_START:
      return getUserProfileStart(state);
    case actionTypes.GET_USER_PROFILE_SUCCESS:
      return getUserProfileSuccess(state, action);
    case actionTypes.GET_USER_PROFILE_FAIL:
      return getUserProfileFail(state, action);

    case actionTypes.FOLLOW_USER_START:
      return followUserStart(state);
    case actionTypes.FOLLOW_USER_SUCCESS:
      return followUserSuccess(state, action);
    case actionTypes.FOLLOW_USER_FAIL:
      return followUserFail(state, action);

    case actionTypes.UNFOLLOW_USER_START:
      return unfollowUserStart(state);
    case actionTypes.UNFOLLOW_USER_SUCCESS:
      return unfollowUserSuccess(state, action);
    case actionTypes.UNFOLLOW_USER_FAIL:
      return unfollowUserFail(state, action);

    case actionTypes.BLOCK_USER_START:
      return blockUserStart(state);
    case actionTypes.BLOCK_USER_SUCCESS:
      return blockUserSuccess(state, action);
    case actionTypes.BLOCK_EVENTS_FAIL:
      return blockUserFail(state, action);

    case actionTypes.UNBLOCK_USER_START:
      return unblockUserStart(state);
    case actionTypes.UNBLOCK_USER_SUCCESS:
      return unblockUserSuccess(state, action);
    case actionTypes.UNBLOCK_USER_FAIL:
      return unblockUserFail(state, action);

    case actionTypes.GET_BLOCKED_USERS_START:
      return getBlockedUsersStart(state);
    case actionTypes.GET_BLOCKED_USERS_SUCCESS:
      return getBlockedUsersSuccess(state, action);
    case actionTypes.GET_BLOCKED_USERS_FAIL:
      return getBlockedUsersFail(state, action);

    case actionTypes.GET_FOLLOWERS_START:
      return getFollowersStart(state);
    case actionTypes.GET_FOLLOWERS_SUCCESS:
      return getFollowersSuccess(state, action);
    case actionTypes.GET_FOLLOWERS_FAIL:
      return getFollowersFail(state, action);
    case actionTypes.GET_FOLLOWERS_CANCEL:
      return getFollowersCancel(state);
    case actionTypes.GET_FOLLOWERS_CLEAR:
      return getFollowersClear(state);

    case actionTypes.GET_FOLLOWINGS_START:
      return getFollowingsStart(state);
    case actionTypes.GET_FOLLOWINGS_SUCCESS:
      return getFollowingsSuccess(state, action);
    case actionTypes.GET_FOLLOWINGS_FAIL:
      return getFollowingsFail(state, action);
    case actionTypes.GET_FOLLOWINGS_CANCEL:
      return getFollowingsCancel(state);
    case actionTypes.GET_FOLLOWINGS_CLEAR:
      return getFollowingsClear(state);

    default:
      return state;
  }
};
