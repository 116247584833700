import * as actionTypes from "../actions/actionTypes";

const initialState = {
  tags: null
};

// =============================================================== ADD STREAM
const getRandomTagsStart = state => {
  const newState = {
    ...state,
    tags: null
  };
  return newState;
};
const getRandomTagsSuccess = (state, action) => {
  const newState = {
    ...state,
    tags: action.tags
  };
  return newState;
};

const getRandomTagsFail = (state, action) => {
  const newState = {
    ...state,
    tags: null
  };
  return newState;
};

export const tagsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_RANDOM_TAGS_START:
      return getRandomTagsStart(state);
    case actionTypes.GET_RANDOM_TAGS_SUCCESS:
      return getRandomTagsSuccess(state, action);
    case actionTypes.GET_RANDOM_TAGS_FAIL:
      return getRandomTagsFail(state, action);

    default:
      return state;
  }
};
