export const GET_EVENTS = 'GET_EVENTS'
export const GET_EVENTS_START = 'GET_EVENTS_START'
export const GET_EVENTS_SUCCESS = 'GET_EVENTS_SUCCESS'
export const GET_EVENTS_FAIL = 'GET_EVENTS_FAIL'
export const GET_EVENTS_CANCEL = 'GET_EVENTS_CANCEL'
export const GET_TOP_EVENTS_CANCEL = 'GET_TOP_EVENTS_CANCEL'
export const GET_EVENTS_CLEAR = 'GET_EVENTS_CLEAR'
export const SEARCH_TOP_EVENTS = 'SEARCH_TOP_EVENTS'
export const SEARCH_TOP_EVENTS_START = 'SEARCH_TOP_EVENTS_START'
export const SEARCH_TOP_EVENTS_SUCCESS = 'SEARCH_TOP_EVENTS_SUCCESS'
export const SEARCH_TOP_EVENTS_FAIL = 'SEARCH_TOP_EVENTS_FAIL'

export const SEARCH_EVENTS = 'SEARCH_EVENTS'
export const SEARCH_EVENTS_START = 'SEARCH_EVENTS_START'
export const SEARCH_EVENTS_SUCCESS = 'SEARCH_EVENTS_SUCCESS'
export const SEARCH_EVENTS_FAIL = 'SEARCH_EVENTS_FAIL'

export const USER_LAST_EVENTS = 'USER_LAST_EVENTS'
export const USER_LAST_EVENTS_START = 'USER_LAST_EVENTS_START'
export const USER_LAST_EVENTS_SUCCESS = 'USER_LAST_EVENTS_SUCCESS'
export const USER_LAST_EVENTS_FAIL = 'USER_LAST_EVENTS_FAIL'

export const USER_PROFILE_EVENTS = 'USER_PROFILE_EVENTS'
export const USER_PROFILE_EVENTS_START = 'USER_PROFILE_EVENTS_START'
export const USER_PROFILE_EVENTS_SUCCESS = 'USER_PROFILE_EVENTS_SUCCESS'
export const USER_PROFILE_EVENTS_FAIL = 'USER_PROFILE_EVENTS_FAIL'
export const GET_USER_EVENTS_CANCEL = 'GET_USER_EVENTS_CANCEL'

export const DELETE_EVENTS = 'DELETE_EVENTS'
export const DELETE_EVENTS_START = 'DELETE_EVENTS_START'
export const DELETE_EVENTS_SUCCESS = 'DELETE_EVENTS_SUCCESS'
export const DELETE_EVENTS_FAIL = 'DELETE_EVENTS_FAIL'

export const GET_EVENT_BY_ID = 'GET_EVENT_BY_ID'
export const GET_EVENT_BY_ID_START = 'GET_EVENT_BY_ID_START'
export const GET_EVENT_BY_ID_SUCCESS = 'GET_EVENT_BY_ID_SUCCESS'
export const GET_EVENT_BY_ID_FAIL = 'GET_EVENT_BY_ID_FAIL'

export const CHECK_EVENT_BY_ID = 'CHECK_EVENT_BY_ID'
export const CHECK_EVENT_BY_ID_START = 'CHECK_EVENT_BY_ID_START'
export const CHECK_EVENT_BY_ID_SUCCESS = 'CHECK_EVENT_BY_ID_SUCCESS'
export const CHECK_EVENT_BY_ID_FAIL = 'CHECK_EVENT_BY_ID_FAIL'

export const GET_MY_BROADCAST = 'GET_MY_BROADCAST'
export const GET_MY_BROADCAST_START = 'GET_MY_BROADCAST_START'
export const GET_MY_BROADCAST_SUCCESS = 'GET_MY_BROADCAST_SUCCESS'
export const GET_MY_BROADCAST_FAIL = 'GET_MY_BROADCAST_FAIL'

export const CREATE_EVENT = 'CREATE_EVENT'
export const CREATE_EVENT_START = 'CREATE_EVENT_START'
export const CREATE_EVENT_SUCCESS = 'CREATE_EVENT_SUCCESS'
export const CREATE_EVENT_FAIL = 'CREATE_EVENT_FAIL'

export const CREATE_AND_START_EVENT = 'CREATE_AND_START_EVENT'
export const CREATE_AND_START_EVENT_START = 'CREATE_AND_START_EVENT_START'
export const CREATE_AND_START_EVENT_SUCCESS = 'CREATE_AND_START_EVENT_SUCCESS'
export const CREATE_AND_START_EVENT_FAIL = 'CREATE_AND_START_EVENT_FAIL'

export const CREATE_AND_PREVIEW_EVENT = 'CREATE_AND_PREVIEW_EVENT'
export const CREATE_AND_PREVIEW_EVENT_START = 'CREATE_AND_PREVIEW_EVENT_START'
export const CREATE_AND_PREVIEW_EVENT_SUCCESS = 'CREATE_AND_PREVIEW_EVENT_SUCCESS'
export const CREATE_AND_PREVIEW_EVENT_FAIL = 'CREATE_AND_PREVIEW_EVENT_FAIL'

export const EDIT_EVENT = 'EDIT_EVENT'
export const EDIT_EVENT_START = 'EDIT_EVENT_START'
export const EDIT_EVENT_SUCCESS = 'EDIT_EVENT_SUCCESS'
export const EDIT_EVENT_FAIL = 'EDIT_EVENT_FAIL'

export const EDIT_EVENT_INFO = 'EDIT_EVENT_INFO'
export const EDIT_EVENT_INFO_START = 'EDIT_EVENT_INFO_START'
export const EDIT_EVENT_INFO_SUCCESS = 'EDIT_EVENT_INFO_SUCCESS'
export const EDIT_EVENT_INFO_FAIL = 'EDIT_EVENT_INFO_FAIL'

export const EVENT_CHANGE_STATE = 'EVENT_CHANGE_STATE'
export const EVENT_CHANGE_STATE_START = 'EVENT_CHANGE_STATE_START'
export const EVENT_CHANGE_STATE_SUCCESS = 'EVENT_CHANGE_STATE_SUCCESS'
export const EVENT_CHANGE_STATE_FAIL = 'EVENT_CHANGE_STATE_FAIL'

export const BIND_EVENT_STREAM = 'BIND_EVENT_STREAM'
export const BIND_EVENT_STREAM_START = 'BIND_EVENT_STREAM_START'
export const BIND_EVENT_STREAM_SUCCESS = 'BIND_EVENT_STREAM_SUCCESS'
export const BIND_EVENT_STREAM_FAIL = 'BIND_EVENT_STREAM_FAIL'

export const LATEST_EVENTS_CATEGORY = 'LATEST_EVENTS_CATEGORY'
export const LATEST_EVENTS_CATEGORY_START = 'LATEST_EVENTS_CATEGORY_START'
export const LATEST_EVENTS_CATEGORY_SUCCESS = 'LATEST_EVENTS_CATEGORY_SUCCESS'
export const LATEST_EVENTS_CATEGORY_FAIL = 'LATEST_EVENTS_CATEGORY_FAIL'

// LOCATION ====================================================================

export const GET_LOCATIONS = 'GET_LOCATIONS'
export const GET_LOCATIONS_START = 'GET_LOCATIONS_START'
export const GET_LOCATIONS_SUCCESS = 'GET_LOCATIONS_SUCCESS'
export const GET_LOCATIONS_FAIL = 'GET_LOCATIONS_FAIL'

// LOGIN ====================================================================

export const USER_LOGIN = 'USER_LOGIN'
export const USER_LOGIN_START = 'USER_LOGIN_START'
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS'
export const USER_LOGIN_FAIL = 'USER_LOGIN_FAIL'

export const USER_SOCIAL_LOGIN = 'USER_SOCIAL_LOGIN'
export const USER_SOCIAL_LOGIN_START = 'USER_SOCIAL_LOGIN_START'
export const USER_SOCIAL_LOGIN_SUCCESS = 'USER_SOCIAL_LOGIN_SUCCESS'
export const USER_SOCIAL_LOGIN_FAIL = 'USER_SOCIAL_LOGIN_FAIL'

// LOGOUT ====================================================================

export const USER_LOGOUT = 'USER_LOGOUT'
export const USER_LOGOUT_SUCCESS = 'USER_LOGOUT_SUCCESS'

// USER REGISTER ====================================================================

export const USER_REGISTER_SEND_CODE = 'USER_REGISTER_SEND_CODE'
export const USER_REGISTER_SEND_CODE_START = 'USER_REGISTER_START'
export const USER_REGISTER_SEND_CODE_SUCCESS =
  'USER_REGISTER_SEND_CODE_SUCCESS'
export const USER_REGISTER_SEND_CODE_FAIL = 'USER_REGISTER_SEND_CODE_FAIL'

export const USER_REGISTER_REGISTER = 'USER_REGISTER_REGISTER'
export const USER_REGISTER_REGISTER_START = 'USER_REGISTER_REGISTER_START'
export const USER_REGISTER_REGISTER_SUCCESS = 'USER_REGISTER_REGISTER_SUCCESS'
export const USER_REGISTER_REGISTER_FAIL = 'USER_REGISTER_REGISTER_FAIL'

// CHANGE PASSWORD ====================================================================

export const CHANGE_PASSWORD = 'CHANGE_PASSWORD'
export const CHANGE_PASSWORD_START = 'CHANGE_PASSWORD_START'
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS'
export const CHANGE_PASSWORD_FAIL = 'CHANGE_PASSWORD_FAIL'

// Profile ====================================================================

export const UPDATE_PROFILE = 'UPDATE_PROFILE'
export const UPDATE_PROFILE_START = 'UPDATE_PROFILE_START'
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS'
export const UPDATE_PROFILE_FAIL = 'UPDATE_PROFILE_FAIL'

export const GET_MY_PROFILE = 'GET_MY_PROFILE'
export const GET_MY_PROFILE_START = 'GET_MY_PROFILE_START'
export const GET_MY_PROFILE_SUCCESS = 'GET_MY_PROFILE_SUCCESS'
export const GET_MY_PROFILE_FAIL = 'GET_MY_PROFILE_FAIL'

export const EDIT_MY_PROFILE = 'EDIT_MY_PROFILE'
export const EDIT_MY_PROFILE_START = 'EDIT_MY_PROFILE_START'
export const EDIT_MY_PROFILE_SUCCESS = 'EDIT_MY_PROFILE_SUCCESS'
export const EDIT_MY_PROFILE_FAIL = 'EDIT_MY_PROFILE_FAIL'
export const DELETE_MY_PROFILE_START = 'DELETE_MY_PROFILE_START'
export const DELETE_MY_PROFILE_SUCCESS = 'DELETE_MY_PROFILE_SUCCESS'
export const DELETE_MY_PROFILE_FAIL = 'DELETE_MY_PROFILE_FAIL'
export const DELETE_MY_PROFILE_CLEAR = 'DELETE_MY_PROFILE_CLEAR'
export const DELETE_MY_PROFILE = 'DELETE_MY_PROFILE'
// JOIN ====================================================================

export const JOIN_ON_EVENT = 'JOIN_ON_EVENT'
export const JOIN_ON_EVENT_START = 'JOIN_ON_EVENT_START'
export const JOIN_ON_EVENT_SUCCESS = 'JOIN_ON_EVENT_SUCCESS'
export const JOIN_ON_EVENT_FAIL = 'JOIN_ON_EVENT_FAIL'

export const LEAVE_EVENT = 'LEAVE_EVENT'
export const LEAVE_EVENT_START = 'LEAVE_EVENT_START'
export const LEAVE_EVENT_SUCCESS = 'LEAVE_EVENT_SUCCESS'
export const LEAVE_EVENT_FAIL = 'LEAVE_EVENT_FAIL'

// STREAMS ====================================================================

export const ADD_STREAMS = 'ADD_STREAMS'
export const ADD_STREAMS_START = 'ADD_STREAMS_START'
export const ADD_STREAMS_SUCCESS = 'ADD_STREAMS_SUCCESS'
export const ADD_STREAMS_FAIL = 'ADD_STREAMS_FAIL'

export const UPDATE_STREAM = 'UPDATE_STREAM'
export const UPDATE_STREAM_START = 'UPDATE_STREAM_START'
export const UPDATE_STREAM_SUCCESS = 'UPDATE_STREAM_SUCCESS'
export const UPDATE_STREAM_FAIL = 'UPDATE_STREAM_FAIL'

export const DELETE_STREAM = 'DELETE_STREAM'
export const DELETE_STREAM_START = 'DELETE_STREAM_START'
export const DELETE_STREAM_SUCCESS = 'DELETE_STREAM_SUCCESS'
export const DELETE_STREAM_FAIL = 'DELETE_STREAM_FAIL'

// FORGOT PASSWORD ====================================================================

export const FORGOT_PASSWORD_SEND_CODE = 'FORGOT_PASSWORD_SEND_CODE'
export const FORGOT_PASSWORD_SEND_CODE_START =
  'FORGOT_PASSWORD_SEND_CODE_START'
export const FORGOT_PASSWORD_SEND_CODE_SUCCESS =
  'FORGOT_PASSWORD_SEND_CODE_SUCCESS'
export const FORGOT_PASSWORD_SEND_CODE_FAIL = 'FORGOT_PASSWORD_SEND_CODE_FAIL'

export const FORGOT_PASSWORD_CHECK_CODE = 'FORGOT_PASSWORD_CHECK_CODE'
export const FORGOT_PASSWORD_CHECK_CODE_START =
  'FORGOT_PASSWORD_CHECK_CODE_START'
export const FORGOT_PASSWORD_CHECK_CODE_SUCCESS =
  'FORGOT_PASSWORD_CHECK_CODE_SUCCESS'
export const FORGOT_PASSWORD_CHECK_CODE_FAIL =
  'FORGOT_PASSWORD_CHECK_CODE_FAIL'

export const FORGOT_PASSWORD_CHANGE_PASSWORD =
  'FORGOT_PASSWORD_CHANGE_PASSWORD'
export const FORGOT_PASSWORD_CHANGE_PASSWORD_START =
  'FORGOT_PASSWORD_CHANGE_PASSWORD_START'
export const FORGOT_PASSWORD_CHANGE_PASSWORD_SUCCESS =
  'FORGOT_PASSWORD_CHANGE_PASSWORD_SUCCESS'
export const FORGOT_PASSWORD_CHANGE_PASSWORD_FAIL =
  'FORGOT_PASSWORD_CHANGE_PASSWORD_FAIL'

// ERRORS ====================================================================

export const SERVER_ERROR_SUCCESS = 'SERVER_ERROR_SUCCESS'

export const PERMISSION_ERROR_SUCCESS = 'PERMISSION_ERROR_SUCCESS'

// META DATA ====================================================================

export const GET_META_DATA = 'GET_META_DATA'
export const GET_META_DATA_START = 'GET_META_DATA_START'
export const GET_META_DATA_SUCCESS = 'GET_META_DATA_SUCCESS'
export const GET_META_DATA_FAIL = 'GET_META_DATA_FAIL'

// TAGS ====================================================================

export const GET_RANDOM_TAGS = 'GET_RANDOM_TAGS'
export const GET_RANDOM_TAGS_START = 'GET_RANDOM_TAGS_START'
export const GET_RANDOM_TAGS_SUCCESS = 'GET_RANDOM_TAGS_SUCCESS'
export const GET_RANDOM_TAGS_FAIL = 'GET_RANDOM_TAGS_FAIL'

// Plans ====================================================================

export const GET_PLANS = 'GET_PLANS'
export const GET_PLANS_START = 'GET_PLANS_START'
export const GET_PLANS_SUCCESS = 'GET_PLANS_SUCCESS'
export const GET_PLANS_FAIL = 'GET_PLANS_FAIL'

export const ADD_PLAN = 'ADD_PLAN'
export const ADD_PLAN_START = 'ADD_PLAN_START'
export const ADD_PLAN_SUCCESS = 'ADD_PLAN_SUCCESS'
export const ADD_PLAN_FAIL = 'ADD_PLAN_FAIL'

export const DELETE_PLAN = 'DELETE_PLAN'
export const DELETE_PLAN_START = 'DELETE_PLAN_START'
export const DELETE_PLAN_SUCCESS = 'DELETE_PLAN_SUCCESS'
export const DELETE_PLAN_FAIL = 'DELETE_PLAN_FAIL'

export const GET_PLAN_BY_ID = 'GET_PLAN_BY_ID'
export const GET_PLAN_BY_ID_START = 'GET_PLAN_BY_ID_START'
export const GET_PLAN_BY_ID_SUCCESS = 'GET_PLAN_BY_ID_SUCCESS'
export const GET_PLAN_BY_ID_FAIL = 'GET_PLAN_BY_ID_FAIL'

export const EDIT_PLAN = 'EDIT_PLAN'
export const EDIT_PLAN_START = 'EDIT_PLAN_START'
export const EDIT_PLAN_SUCCESS = 'EDIT_PLAN_SUCCESS'
export const EDIT_PLAN_FAIL = 'EDIT_PLAN_FAIL'

// USER PLANS ====================================================================

export const GET_MY_PLAN = 'GET_MY_PLAN'
export const GET_MY_PLAN_START = 'GET_MY_PLAN_START'
export const GET_MY_PLAN_SUCCESS = 'GET_MY_PLAN_SUCCESS'
export const GET_MY_PLAN_FAIL = 'GET_MY_PLAN_FAIL'

export const SET_FREE_PREMIUM = 'SET_FREE_PREMIUM'
export const SET_FREE_PREMIUM_START = 'SET_FREE_PREMIUM_START'
export const SET_FREE_PREMIUM_SUCCESS = 'SET_FREE_PREMIUM_SUCCESS'
export const SET_FREE_PREMIUM_FAIL = 'SET_FREE_PREMIUM_FAIL'

export const GET_USER_PLAN_BY_ID = 'GET_USER_PLAN_BY_ID'
export const GET_USER_PLAN_BY_ID_START = 'GET_USER_PLAN_BY_ID_START'
export const GET_USER_PLAN_BY_ID_SUCCESS = 'GET_USER_PLAN_BY_ID_SUCCESS'
export const GET_USER_PLAN_BY_ID_FAIL = 'GET_USER_PLAN_BY_ID_FAIL'

export const ASSIGN_USER_PLANS = 'ASSIGN_USER_PLAN'
export const ASSIGN_USER_PLAN_START = 'ASSIGN_USER_PLAN_START'
export const ASSIGN_USER_PLAN_SUCCESS = 'ASSIGN_USER_PLAN_SUCCESS'
export const ASSIGN_USER_PLAN_FAIL = 'ASSIGN_USER_PLAN_FAIL'
// ROLES ====================================================================

export const GET_SEARCH_ROLES = 'GET_SEARCH_ROLES'
export const GET_SEARCH_ROLES_START = 'GET_SEARCH_ROLES_START'
export const GET_SEARCH_ROLES_SUCCESS = 'GET_SEARCH_ROLES_SUCCESS'
export const GET_SEARCH_ROLES_FAIL = 'GET_SEARCH_ROLES_FAIL'

export const GET_ROLE_BY_ID = 'GET_ROLE_BY_ID'
export const GET_ROLE_BY_ID_START = 'GET_ROLE_BY_ID_START'
export const GET_ROLE_BY_ID_SUCCESS = 'GET_ROLE_BY_ID_SUCCESS'
export const GET_ROLE_BY_ID_FAIL = 'GET_ROLE_BY_ID_FAIL'

export const DELETE_ROLE = 'DELETE_ROLE'
export const DELETE_ROLE_START = 'DELETE_ROLE_START'
export const DELETE_ROLE_SUCCESS = 'DELETE_ROLE_SUCCESS'
export const DELETE_ROLE_FAIL = 'DELETE_ROLE_FAIL'

export const ADD_ROLE = 'ADD_ROLE'
export const ADD_ROLE_START = 'ADD_ROLE_START'
export const ADD_ROLE_SUCCESS = 'ADD_ROLE_SUCCESS'
export const ADD_ROLE_FAIL = 'ADD_ROLE_FAIL'

export const EDIT_ROLE = 'EDIT_ROLE'
export const EDIT_ROLE_START = 'EDIT_ROLE_START'
export const EDIT_ROLE_SUCCESS = 'EDIT_ROLE_SUCCESS'
export const EDIT_ROLE_FAIL = 'EDIT_ROLE_FAIL'

// REACTIONS ====================================================================

export const ADD_REACTION = 'ADD_REACTION'
export const ADD_REACTION_START = 'ADD_REACTION_START'
export const ADD_REACTION_SUCCESS = 'ADD_REACTION_SUCCESS'
export const ADD_REACTION_FAIL = 'ADD_REACTION_FAIL'

export const EDIT_REACTION = 'EDIT_REACTION'
export const EDIT_REACTION_START = 'EDIT_REACTION_START'
export const EDIT_REACTION_SUCCESS = 'EDIT_REACTION_SUCCESS'
export const EDIT_REACTION_FAIL = 'EDIT_REACTION_FAIL'

export const DELETE_REACTION = 'DELETE_REACTION'
export const DELETE_REACTION_START = 'DELETE_REACTION_START'
export const DELETE_REACTION_SUCCESS = 'DELETE_REACTION_SUCCESS'
export const DELETE_REACTION_FAIL = 'DELETE_REACTION_FAIL'

export const SEARCH_REACTIONS = 'SEARCH_REACTIONS'
export const SEARCH_REACTIONS_START = 'SEARCH_REACTIONS_START'
export const SEARCH_REACTIONS_SUCCESS = 'SEARCH_REACTIONS_SUCCESS'
export const SEARCH_REACTIONS_FAIL = 'SEARCH_REACTIONS_FAIL'

// LIVE/TOTAL VIEW ====================================================================

export const ADD_TOTAL_VIEW = 'ADD_TOTAL_VIEW'
export const ADD_TOTAL_VIEW_START = 'ADD_TOTAL_VIEW_START'
export const ADD_TOTAL_VIEW_SUCCESS = 'ADD_TOTAL_VIEW_SUCCESS'
export const ADD_TOTAL_VIEW_FAIL = 'ADD_TOTAL_VIEW_FAIL'

export const ADD_LIVE_VIEW = 'ADD_LIVE_VIEW'
export const ADD_LIVE_VIEW_START = 'ADD_LIVE_VIEW_START'
export const ADD_LIVE_VIEW_SUCCESS = 'ADD_LIVE_VIEW_SUCCESS'
export const ADD_LIVE_VIEW_FAIL = 'ADD_LIVE_VIEW_FAIL'

export const DELETE_LIVE_VIEW = 'DELETE_LIVE_VIEW'
export const DELETE_LIVE_VIEW_START = 'DELETE_LIVE_VIEW_START'
export const DELETE_LIVE_VIEW_SUCCESS = 'DELETE_LIVE_VIEW_SUCCESS'
export const DELETE_LIVE_VIEW_FAIL = 'DELETE_LIVE_VIEW_FAIL'

// Comments ====================================================================

export const ADD_COMMENT = 'ADD_COMMENT'
export const ADD_COMMENT_START = 'ADD_COMMENT_START'
export const ADD_COMMENT_SUCCESS = 'ADD_COMMENT_SUCCESS'
export const ADD_COMMENT_FAIL = 'ADD_COMMENT_FAIL'

export const SEARCH_COMMENTS = 'SEARCH_COMMENTS'
export const SEARCH_COMMENTS_START = 'SEARCH_COMMENTS_START'
export const SEARCH_COMMENTS_SUCCESS = 'SEARCH_COMMENTS_SUCCESS'
export const SEARCH_COMMENTS_FAIL = 'SEARCH_COMMENTS_FAIL'

export const SEARCH_COMMENT_REPLIES = 'SEARCH_COMMENT_REPLIES'
export const SEARCH_COMMENT_REPLIES_START = 'SEARCH_COMMENT_REPLIES_START'
export const SEARCH_COMMENT_REPLIES_SUCCESS = 'SEARCH_COMMENT_REPLIES_SUCCESS'
export const SEARCH_COMMENT_REPLIES_FAIL = 'SEARCH_COMMENT_REPLIES_FAIL'

export const DELETE_COMMENT = 'DELETE_COMMENT'
export const DELETE_COMMENT_START = 'DELETE_COMMENT_START'
export const DELETE_COMMENT_SUCCESS = 'DELETE_COMMENT_SUCCESS'
export const DELETE_COMMENT_FAIL = 'DELETE_COMMENT_FAIL'

export const EDIT_COMMENT = 'EDIT_COMMENT'
export const EDIT_COMMENT_START = 'EDIT_COMMENT_START'
export const EDIT_COMMENT_SUCCESS = 'EDIT_COMMENT_SUCCESS'
export const EDIT_COMMENT_FAIL = 'EDIT_COMMENT_FAIL'

// Chat ====================================================================

export const ADD_CHAT = 'ADD_CHAT'
export const ADD_CHAT_START = 'ADD_CHAT_START'
export const ADD_CHAT_SUCCESS = 'ADD_CHAT_SUCCESS'
export const ADD_CHAT_FAIL = 'ADD_CHAT_FAIL'

export const SEARCH_CHATS = 'SEARCH_CHATS'
export const SEARCH_CHATS_START = 'SEARCH_CHATS_START'
export const SEARCH_CHATS_SUCCESS = 'SEARCH_CHATS_SUCCESS'
export const SEARCH_CHATS_FAIL = 'SEARCH_CHATS_FAIL'

// USER SOCIALIZING ====================================================================

export const GET_USER_PROFILE = 'GET_USER_PROFILE'
export const GET_USER_PROFILE_START = 'GET_USER_PROFILE_START'
export const GET_USER_PROFILE_SUCCESS = 'GET_USER_PROFILE_SUCCESS'
export const GET_USER_PROFILE_FAIL = 'GET_USER_PROFILE_FAIL'

export const FOLLOW_USER = 'FOLLOW_USER'
export const FOLLOW_USER_START = 'FOLLOW_USER_START'
export const FOLLOW_USER_SUCCESS = 'FOLLOW_USER_SUCCESS'
export const FOLLOW_USER_FAIL = 'FOLLOW_USER_FAIL'

export const UNFOLLOW_USER = 'UNFOLLOW_USER'
export const UNFOLLOW_USER_START = 'UNFOLLOW_USER_START'
export const UNFOLLOW_USER_SUCCESS = 'UNFOLLOW_USER_SUCCESS'
export const UNFOLLOW_USER_FAIL = 'UNFOLLOW_USER_FAIL'

export const GET_FOLLOWERS = 'GET_FOLLOWERS'
export const GET_FOLLOWERS_START = 'GET_FOLLOWERS_START'
export const GET_FOLLOWERS_SUCCESS = 'GET_FOLLOWERS_SUCCESS'
export const GET_FOLLOWERS_FAIL = 'GET_FOLLOWERS_FAIL'
export const GET_FOLLOWERS_CANCEL = 'GET_FOLLOWERS_CANCEL'
export const GET_FOLLOWERS_CLEAR = 'GET_FOLLOWERS_CLEAR'

export const GET_FOLLOWINGS = 'GET_FOLLOWINGS'
export const GET_FOLLOWINGS_START = 'GET_FOLLOWINGS_START'
export const GET_FOLLOWINGS_SUCCESS = 'GET_FOLLOWINGS_SUCCESS'
export const GET_FOLLOWINGS_FAIL = 'GET_FOLLOWINGS_FAIL'
export const GET_FOLLOWINGS_CANCEL = 'GET_FOLLOWINGS_CANCEL'
export const GET_FOLLOWINGS_CLEAR = 'GET_FOLLOWINGS_CLEAR'

export const BLOCK_USER = 'BLOCK_USER'
export const BLOCK_USER_START = 'BLOCK_USER_START'
export const BLOCK_USER_SUCCESS = 'BLOCK_USER_SUCCESS'
export const BLOCK_USER_FAIL = 'BLOCK_USER_FAIL'

export const UNBLOCK_USER = 'UNBLOCK_USER'
export const UNBLOCK_USER_START = 'UNBLOCK_USER_START'
export const UNBLOCK_USER_SUCCESS = 'UNBLOCK_USER_SUCCESS'
export const UNBLOCK_USER_FAIL = 'UNBLOCK_USER_FAIL'

export const GET_BLOCKED_USERS = 'GET_BLOCKED_USERS'
export const GET_BLOCKED_USERS_START = 'GET_BLOCKED_USERS_START'
export const GET_BLOCKED_USERS_SUCCESS = 'GET_BLOCKED_USERS_SUCCESS'
export const GET_BLOCKED_USERS_FAIL = 'GET_BLOCKED_USERS_FAIL'

export const GET_ALL_USER = 'GET_ALL_USER'
export const GET_ALL_USER_START = 'GET_ALL_USER_START'
export const GET_ALL_USER_SUCCESS = 'GET_ALL_USER_SUCCESS'
export const GET_ALL_USER_FAIL = 'GET_ALL_USER_FAIL'

export const GET_USER_BY_ID = 'GET_USER_BY_ID'
export const GET_USER_BY_ID_START = 'GET_USER_BY_ID_START'
export const GET_USER_BY_ID_SUCCESS = 'GET_USER_BY_ID_SUCCESS'
export const GET_USER_BY_ID_FAIL = 'GET_USER_BY_ID_FAIL'

export const ADD_USER = 'ADD_USER'
export const ADD_USER_START = 'ADD_USER_START'
export const ADD_USER_SUCCESS = 'ADD_USER_SUCCESS'
export const ADD_USER_FAIL = 'ADD_USER_FAIL'

export const EDIT_USER = 'EDIT_USER'
export const EDIT_USER_START = 'EDIT_USER_START'
export const EDIT_USER_SUCCESS = 'EDIT_USER_SUCCESS'
export const EDIT_USER_FAIL = 'EDIT_USER_FAIL'

export const DELETE_USER = 'DELETE_USER'
export const DELETE_USER_START = 'DELETE_USER_START'
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS'
export const DELETE_USER_FAIL = 'DELETE_USER_FAIL'

// REPORTS ==================================

export const REPORT_EVENT = 'REPORT_EVENT'
export const REPORT_EVENT_START = 'REPORT_EVENT_START'
export const REPORT_EVENT_SUCCESS = 'REPORT_EVENT_SUCCESS'
export const REPORT_EVENT_FAIL = 'REPORT_EVENT_FAIL'

export const GET_REPORT_EVENT = 'GET_REPORT_EVENT'
export const GET_REPORT_EVENT_START = 'GET_REPORT_EVENT_START'
export const GET_REPORT_EVENT_SUCCESS = 'GET_REPORT_EVENT_SUCCESS'
export const GET_REPORT_EVENT_FAIL = 'GET_REPORT_EVENT_FAIL'

export const REPORT_COMMENT = 'REPORT_COMMENT'
export const REPORT_COMMENT_START = 'REPORT_COMMENT_START'
export const REPORT_COMMENT_SUCCESS = 'REPORT_COMMENT_SUCCESS'
export const REPORT_COMMENT_FAIL = 'REPORT_COMMENT_FAIL'

export const GET_REPORTED_COMMENTS = 'GET_REPORTED_COMMENTS'
export const GET_REPORTED_COMMENTS_START = 'GET_REPORTED_COMMENTS_START'
export const GET_REPORTED_COMMENTS_SUCCESS = 'GET_REPORTED_COMMENTS_SUCCESS'
export const GET_REPORTED_COMMENTS_FAIL = 'GET_REPORTED_COMMENTS_FAIL'

// ==================================

export const BLOCK_EVENTS = 'BLOCK_EVENTS'
export const BLOCK_EVENTS_START = 'BLOCK_EVENTS_START'
export const BLOCK_EVENTS_SUCCESS = 'BLOCK_EVENTS_SUCCESS'
export const BLOCK_EVENTS_FAIL = 'BLOCK_EVENTS_FAIL'

export const BLOCK_COMMENT = 'BLOCK_COMMENT'
export const BLOCK_COMMENT_START = 'BLOCK_COMMENT_START'
export const BLOCK_COMMENT_SUCCESS = 'BLOCK_COMMENT_SUCCESS'
export const BLOCK_COMMENT_FAIL = 'BLOCK_COMMENT_FAIL'

export const CLEAR_CACHE = 'CLEAR_CACHE'
export const CLEAR_CACHE_START = 'CLEAR_CACHE_START'
export const CLEAR_CACHE_SUCCESS = 'CLEAR_CACHE_SUCCESS'
export const CLEAR_CACHE_FAIL = 'CLEAR_CACHE_FAIL'
// ==================================

export const ADD_NOTIFICATION_TOKEN = 'ADD_NOTIFICATION_TOKEN'
export const ADD_NOTIFICATION_TOKEN_START = 'ADD_NOTIFICATION_TOKEN_START'
export const ADD_NOTIFICATION_TOKEN_SUCCESS = 'ADD_NOTIFICATION_TOKEN_SUCCESS'
export const ADD_NOTIFICATION_TOKEN_FAIL = 'ADD_NOTIFICATION_TOKEN_FAIL'
// ==================================

export const DELETE_NOTIFICATION_TOKEN = 'DELETE_NOTIFICATION_TOKEN'
export const DELETE_NOTIFICATION_TOKEN_START =
  'DELETE_NOTIFICATION_TOKEN_START'
export const DELETE_NOTIFICATION_TOKEN_SUCCESS =
  'DELETE_NOTIFICATION_TOKEN_SUCCESS'
export const DELETE_NOTIFICATION_TOKEN_FAIL = 'DELETE_NOTIFICATION_TOKEN_FAIL'

export const GET_MONITPRING = 'GET_MONITPRING'
export const GET_MONITPRING_START = 'GET_MONITPRING_START'
export const GET_MONITPRING_SUCCESS = 'GET_MONITPRING_SUCCESS'
export const GET_MONITPRING_FAIL = 'GET_MONITPRING_FAIL'

export const GET_APP_VERSION_STATUS = 'GET_APP_VERSION_STATUS'
export const GET_APP_VERSION_STATUS_START = 'GET_APP_VERSION_STATUS_START'
export const GET_APP_VERSION_STATUS_SUCCESS = 'GET_APP_VERSION_STATUS_SUCCESS'
export const GET_APP_VERSION_STATUS_FAIL = 'GET_APP_VERSION_STATUS_FAIL'

// ================================== MIXER

export const ADD_DISPLAY_MIX = 'ADD_DISPLAY_MIX'
export const ADD_DISPLAY_MIX_START = 'ADD_DISPLAY_MIX_START'
export const ADD_DISPLAY_MIX_SUCCESS = 'ADD_DISPLAY_MIX_SUCCESS'
export const ADD_DISPLAY_MIX_FAIL = 'ADD_DISPLAY_MIX_FAIL'

export const SEARCH_DISPLAY_MIX = 'SEARCH_DISPLAY_MIX'
export const SEARCH_DISPLAY_MIX_START = 'SEARCH_DISPLAY_MIX_START'
export const SEARCH_DISPLAY_MIX_SUCCESS = 'SEARCH_DISPLAY_MIX_SUCCESS'
export const SEARCH_DISPLAY_MIX_FAIL = 'SEARCH_DISPLAY_MIX_FAIL'

export const DELETE_DISPLAY_MIX = 'DELETE_DISPLAY_MIX'
export const DELETE_DISPLAY_MIX_START = 'DELETE_DISPLAY_MIX_START'
export const DELETE_DISPLAY_MIX_SUCCESS = 'DELETE_DISPLAY_MIX_SUCCESS'
export const DELETE_DISPLAY_MIX_FAIL = 'DELETE_DISPLAY_MIX_FAIL'

// ================================= CATEGORY

export const GET_ALL_CATEGORIES = 'GET_ALL_CATEGORIES'
export const GET_ALL_CATEGORIES_START = 'GET_ALL_CATEGORIES_START'
export const GET_ALL_CATEGORIES_SUCCESS = 'GET_ALL_CATEGORIES_SUCCESS'
export const GET_ALL_CATEGORIES_FAIL = 'GET_ALL_CATEGORIES_FAIL'

// ================================= INVITE

export const ADD_OWNER_INVITATION = 'ADD_OWNER_INVITATION'
export const ADD_OWNER_INVITATION_START = 'ADD_OWNER_INVITATION_START'
export const ADD_OWNER_INVITATION_SUCCESS = 'ADD_OWNER_INVITATION_SUCCESS'
export const ADD_OWNER_INVITATION_FAIL = 'ADD_OWNER_INVITATION_FAIL'

export const DELETE_OWNER_INVITATION = 'DELETE_OWNER_INVITATION'
export const DELETE_OWNER_INVITATION_START = 'DELETE_OWNER_INVITATION_START'
export const DELETE_OWNER_INVITATION_SUCCESS =
  'DELETE_OWNER_INVITATION_SUCCESS'
export const DELETE_OWNER_INVITATION_FAIL = 'DELETE_OWNER_INVITATION_FAIL'

// ================================= SEARCH USERS
export const SEARCH_USERS = 'SEARCH_USERS'
export const SEARCH_USERS_START = 'SEARCH_USERS_START'
export const SEARCH_USERS_SUCCESS = 'SEARCH_USERS_SUCCESS'
export const SEARCH_USERS_FAIL = 'SEARCH_USERS_FAIL'

// ================================= VIUBIT

export const GET_VIUBIT_BALANCE = 'GET_VIUBIT_BALANCE'
export const GET_VIUBIT_BALANCE_START = 'GET_VIUBIT_BALANCE_START'
export const GET_VIUBIT_BALANCE_SUCCESS = 'GET_VIUBIT_BALANCE_SUCCESS'
export const GET_VIUBIT_BALANCE_FAIL = 'GET_VIUBIT_BALANCE_FAIL'

export const BUY_VIUBIT_ANDROID = 'BUY_VIUBIT_ANDROID'
export const BUY_VIUBIT_IOS = 'BUY_VIUBIT_IOS'
export const BUY_VIUBIT_START = 'BUY_VIUBIT_START'
export const BUY_VIUBIT_SUCCESS = 'BUY_VIUBIT_SUCCESS'
export const BUY_VIUBIT_FAIL = 'BUY_VIUBIT_FAIL'
export const BUY_VIUBIT_CLEAR = 'BUY_VIUBIT_CLEAR'

export const SEND_GIFT = 'SEND_GIFT'
export const SEND_GIFT_START = 'SEND_GIFT_START'
export const SEND_GIFT_SUCCESS = 'SEND_GIFT_SUCCESS'
export const SEND_GIFT_FAIL = 'SEND_GIFT_FAIL'

export const GET_ALL_VIUBIT_PACKAGES = 'GET_ALL_VIUBIT_PACKAGES'
export const GET_ALL_VIUBIT_PACKAGES_START = 'GET_ALL_VIUBIT_PACKAGES_START'
export const GET_ALL_VIUBIT_PACKAGES_SUCCESS =
  'GET_ALL_VIUBIT_PACKAGES_SUCCESS'
export const GET_ALL_VIUBIT_PACKAGES_FAIL = 'GET_ALL_VIUBIT_PACKAGES_FAIL'

export const SEARCH_VIUBIT_HISTORY = 'SEARCH_VIUBIT_HISTORY'
export const SEARCH_VIUBIT_HISTORY_START = 'SEARCH_VIUBIT_HISTORY_START'
export const SEARCH_VIUBIT_HISTORY_SUCCESS = 'SEARCH_VIUBIT_HISTORY_SUCCESS'
export const SEARCH_VIUBIT_HISTORY_FAIL = 'SEARCH_VIUBIT_HISTORY_FAIL'

export const GET_VIUBIT_EVENT_SUMMARY = 'GET_VIUBIT_EVENT_SUMMARY'
export const GET_VIUBIT_EVENT_SUMMARY_START = 'GET_VIUBIT_EVENT_SUMMARY_START'
export const GET_VIUBIT_EVENT_SUMMARY_SUCCESS = 'GET_VIUBIT_EVENT_SUMMARY_SUCCESS'
export const GET_VIUBIT_EVENT_SUMMARY_FAIL = 'GET_VIUBIT_EVENT_SUMMARY_FAIL'

// ================================= INVOICE

export const CREATE_INVOICE = 'CREATE_INVOICE'
export const CREATE_INVOICE_START = 'CREATE_INVOICE_START'
export const CREATE_INVOICE_SUCCESS = 'CREATE_INVOICE_SUCCESS'
export const CREATE_INVOICE_FAIL = 'CREATE_INVOICE_FAIL'

export const CHECK_PAID = 'CHECK_PAID'
export const CHECK_PAID_START = 'CHECK_PAID_START'
export const CHECK_PAID_SUCCESS = 'CHECK_PAID_SUCCESS'
export const CHECK_PAID_FAIL = 'CHECK_PAID_FAIL'
export const CHECK_PAID_CLEAR = 'CHECK_PAID_CLEAR'

export const SEARCH_INVOICE_HISTORY = 'SEARCH_INVOICE_HISTORY'
export const SEARCH_INVOICE_HISTORY_START = 'SEARCH_INVOICE_HISTORY_START'
export const SEARCH_INVOICE_HISTORY_SUCCESS = 'SEARCH_INVOICE_HISTORY_SUCCESS'
export const SEARCH_INVOICE_HISTORY_FAIL = 'SEARCH_INVOICE_HISTORY_FAIL'

export const GET_INVOICE_BY_ID = 'GET_INVOICE_BY_ID'
export const GET_INVOICE_BY_ID_START = 'GET_INVOICE_BY_ID_START'
export const GET_INVOICE_BY_ID_SUCCESS = 'GET_INVOICE_BY_ID_SUCCESS'
export const GET_INVOICE_BY_ID_FAIL = 'GET_INVOICE_BY_ID_FAIL'
// ========NOTIFICATIONS PROFILE ==========
export const GET_MY_NOTIFICATIONS_ERROR = 'GET_MY_NOTIFICATIONS_ERROR'
export const GET_MY_NOTIFICATIONS = 'GET_MY_NOTIFICATIONS'
export const MARK_ALL_NOTIFICATIONS = 'MARK_ALL_NOTIFICATIONS'
export const GET_MY_NOTIFICATIONS_START = 'GET_MY_NOTIFICATIONS_START'
export const GET_MY_NOTIFICATIONS_SUCCESS = 'GET_MY_NOTIFICATIONS_SUCCESS'
export const GET_MY_NOTIFICATIONS_CLEAR = 'GET_MY_NOTIFICATIONS_CLEAR'
export const GET_MY_NOTIFICATIONS_NUMBER = 'GET_MY_NOTIFICATIONS_NUMBER'

// =================Director===============

export const GET_EVENT_TIMELINE = 'GET_DIRECTOR_TIMELINE'
export const GET_EVENT_TIMELINE_START = 'GET_DIRECTOR_TIMELINE_START'
export const GET_EVENT_TIMELINE_SUCCESS = 'GET_DIRECTOR_TIMELINE_SUCCESS'
export const GET_EVENT_TIMELINE_FAIL = 'GET_DIRECTOR_TIMELINE_FAIL'

export const CREATE_EVENT_TIMELINE = 'CREATE_EVENT_TIMELINE'
export const CREATE_EVENT_TIMELINE_START = 'CREATE_EVENT_TIMELINE_START'
export const CREATE_EVENT_TIMELINE_SUCCESS = 'CREATE_EVENT_TIMELINE_SUCCESS'
export const CREATE_EVENT_TIMELINE_FAIL = 'CREATE_EVENT_TIMELINE_FAIL'

export const UPDATE_EVENT_TIMELINE_CURRENT_SCENE = 'UPDATE_EVENT_TIMELINE_CURRENT_SCENE'
export const UPDATE_EVENT_TIMELINE_CURRENT_SCENE_START = 'UPDATE_EVENT_TIMELINE_CURRENT_SCENE_START'
export const UPDATE_EVENT_TIMELINE_CURRENT_SCENE_SUCCESS = 'UPDATE_EVENT_TIMELINE_CURRENT_SCENE_SUCCESS'
export const UPDATE_EVENT_TIMELINE_CURRENT_SCENE_FAIL = 'UPDATE_EVENT_TIMELINE_CURRENT_SCENE_FAIL'

export const GET_TIMELINE_TEMPLATES_LIST = "GET_TIMELINE_TEMPLATES_LIST"
export const GET_TIMELINE_TEMPLATES_LIST_START = "GET_TIMELINE_TEMPLATES_LIST_START"
export const GET_TIMELINE_TEMPLATES_LIST_SUCCESS = "GET_TIMELINE_TEMPLATES_LIST_SUCCESS"
export const GET_TIMELINE_TEMPLATES_LIST_FAIL = "GET_TIMELINE_TEMPLATES_LIST_FAIL"

export const CREATE_TIMELINE_TEMPLATE = "CREATE_TIMELINE_TEMPLATE"
export const CREATE_TIMELINE_TEMPLATE_START = "CREATE_TIMELINE_TEMPLATE_START"
export const CREATE_TIMELINE_TEMPLATE_SUCCESS = "CREATE_TIMELINE_TEMPLATE_SUCCESS"
export const CREATE_TIMELINE_TEMPLATE_FAIL = "CREATE_TIMELINE_TEMPLATE_FAIL"

export const GET_TIMELINE_TEMPLATE = "GET_TIMELINE_TEMPLATE"
export const GET_TIMELINE_TEMPLATE_START = "GET_TIMELINE_TEMPLATE_START"
export const GET_TIMELINE_TEMPLATE_SUCCESS = "GET_TIMELINE_TEMPLATE_SUCCESS"
export const GET_TIMELINE_TEMPLATE_FAIL = "GET_TIMELINE_TEMPLATE_FAIL"

export const DELETE_TIMELINE_TEMPLATE = "DELETE_TIMELINE_TEMPLATE"
export const DELETE_TIMELINE_TEMPLATE_START = "DELETE_TIMELINE_TEMPLATE_START"
export const DELETE_TIMELINE_TEMPLATE_SUCCESS = "DELETE_TIMELINE_TEMPLATE_SUCCESS"
export const DELETE_TIMELINE_TEMPLATE_FAIL = "DELETE_TIMELINE_TEMPLATE_FAIL"

export const TOGGLE_HIDE_EVENT = 'TOGGLE_HIDE_EVENT';
export const TOGGLE_HIDE_EVENT_START = 'TOGGLE_HIDE_EVENT_START';
export const TOGGLE_HIDE_EVENT_SUCCESS = 'TOGGLE_HIDE_EVENT_SUCCESS';
export const TOGGLE_HIDE_EVENT_FAIL = 'TOGGLE_HIDE_EVENT_FAIL';
