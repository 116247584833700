import * as actionTypes from "../actions/actionTypes";

const initialState = {
  response: null,
  deleteResponse: null,
  mixes: null,
  loading: false,
  error: null
};

const addDisplayMixStart = state => {
  const newState = {
    ...state,
    loading: true,
    error: null,
    response: null
  };
  return newState;
};
const addDisplayMixSuccess = (state, action) => {
  const newState = {
    ...state,
    loading: false,
    response: action.response,
    error: null
  };
  return newState;
};

const addDisplayMixFail = (state, action) => {
  const newState = {
    ...state,
    response: null,
    loading: false,
    error: action.error
  };
  return newState;
};

// ==================================== SearchDisplayMix

const searchDisplayMixStart = state => {
  const newState = {
    ...state,
    loading: true,
    error: null,
    mixes: null
  };
  return newState;
};
const searchDisplayMixSuccess = (state, action) => {
  const newState = {
    ...state,
    loading: false,
    mixes: action.mixes,
    error: null
  };
  return newState;
};

const searchDisplayMixFail = (state, action) => {
  const newState = {
    ...state,
    mixes: null,
    loading: false,
    error: action.error
  };
  return newState;
};

// ============

const deleteDisplayMixStart = state => {
  const newState = {
    ...state,
    loading: true,
    error: null,
    deleteResponse: null
  };
  return newState;
};
const deleteDisplayMixSuccess = (state, action) => {
  const newState = {
    ...state,
    loading: false,
    deleteResponse: action.deleteResponse,
    error: null
  };
  return newState;
};

const deleteDisplayMixFail = (state, action) => {
  const newState = {
    ...state,
    deleteResponse: null,
    loading: false,
    error: action.error
  };
  return newState;
};

export const mixerReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ADD_DISPLAY_MIX_START:
      return addDisplayMixStart(state);
    case actionTypes.ADD_DISPLAY_MIX_SUCCESS:
      return addDisplayMixSuccess(state, action);
    case actionTypes.ADD_DISPLAY_MIX_FAIL:
      return addDisplayMixFail(state, action);

    case actionTypes.SEARCH_DISPLAY_MIX_START:
      return searchDisplayMixStart(state);
    case actionTypes.SEARCH_DISPLAY_MIX_SUCCESS:
      return searchDisplayMixSuccess(state, action);
    case actionTypes.SEARCH_DISPLAY_MIX_FAIL:
      return searchDisplayMixFail(state, action);

    case actionTypes.DELETE_DISPLAY_MIX_START:
      return deleteDisplayMixStart(state);
    case actionTypes.DELETE_DISPLAY_MIX_SUCCESS:
      return deleteDisplayMixSuccess(state, action);
    case actionTypes.DELETE_DISPLAY_MIX_FAIL:
      return deleteDisplayMixFail(state, action);

    default:
      return state;
  }
};
