import * as actionTypes from './actionTypes'

export const addNotificationToken = (token, fcmToken, server) => {
  return {
    type: actionTypes.ADD_NOTIFICATION_TOKEN,
    token: token,
    fcmToken: fcmToken,
    server: server
  }
}

export const addNotificationTokenStart = () => {
  return {
    type: actionTypes.ADD_NOTIFICATION_TOKEN_START
  }
}

export const addNotificationTokenSuccess = response => {
  return {
    type: actionTypes.ADD_NOTIFICATION_TOKEN_SUCCESS,
    response: response
  }
}

export const addNotificationTokenFail = error => {
  return {
    type: actionTypes.ADD_NOTIFICATION_TOKEN_FAIL,
    error: error
  }
}

// ==========

export const deleteNotificationToken = (token, fcmToken, server) => {
  return {
    type: actionTypes.DELETE_NOTIFICATION_TOKEN,
    token: token,
    fcmToken: fcmToken,
    server: server
  }
}

export const deleteNotificationTokenStart = () => {
  return {
    type: actionTypes.DELETE_NOTIFICATION_TOKEN_START
  }
}

export const deleteNotificationTokenSuccess = response => {
  return {
    type: actionTypes.DELETE_NOTIFICATION_TOKEN_SUCCESS,
    response: response
  }
}

export const deleteNotificationTokenFail = error => {
  return {
    type: actionTypes.DELETE_NOTIFICATION_TOKEN_FAIL,
    error: error
  }
}
