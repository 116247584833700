import { put } from "redux-saga/effects";
import * as actions from "../actions/index";
import { POST, GET, DELETE } from "../callApiWrapper";

//==========================================GET EVENT TIMELINE
export function* getEventTimelineSaga(action) {
  yield put(actions.getEventTimelineStart());
  const headers = {
    "X-Auth-Token": action.token,
    "Content-type": "application/json"
  };

  const response = yield GET(
    `${action.server}api/vume/event/${action.eventId}/timeline`,
    headers
  );

  if (response && response === 503) {
    yield put(actions.getEventTimelineFail(response));
  } else if (response && response.status === 200) {
    yield put(actions.getEventTimelineSuccess(response.data));
  } else if (response && (response.status === 403 || response.status === 401)) {
    yield put(actions.userLogout(action.token, action.server));
  } else {
    yield put(actions.getEventTimelineFail(response));
  }
}
//========================================== CREATE EVENT TIMELINE
export function* createEventTimelineSaga(action) {
  yield put(actions.createEventTimelineStart());
  let response;
  const ddb = `--B`;
  const ending = `--B--`;
  let body;
  let file="";
  const jsonData =
    `${ddb}\n` +
    `Content-Disposition: form-data; name="data"; \n ` +
    `filename="blob" \n` +
    `Content-Type: application/json\n\n` +
    `${JSON.stringify(action.data)}\n`;

  if(action.file) {
    for(let i = 0; i < action.file.length; i++) {
      file = `${file}` +
        `${ddb}\n` +
        `Content-Disposition: form-data; name="files"; filename=${action.file[i].filename}\n` +
        `Content-Type: ${action.file[i].mime}\n\n` +
        `${action.file[i].data}\n`;
    }
  }
  body = jsonData;
  if(file) {
    body = body + file + ending;
  } else {
    body = body + ending;
  }


    response = yield fetch(
      `${action.server}api/vume/event/${action.eventId}/timeline`,
      {
        method: "POST",
        headers: {
          "Content-type": `multipart/form-data; boundary=B`,
          "X-Auth-Token": action.token
        },
        body
      }
    )
      .then(res => {
        console.log("res", res);

        return res.json();
      })
      .then(json => {
        const newResponse = {
          data: json,
          status: json.statusCode ? json.statusCode : 200
        };
        return newResponse;
      })
      .catch(err => {
        console.log("err", err);
        return err;
      });

  if (response && response === 503) {
    yield put(actions.createEventTimelineFail(response));
  } else if (response && response.status === 200) {
    yield put(actions.createEventTimelineSuccess(response.data));
  } else if (response && (response.status === 403 || response.status === 401)) {
    yield put(actions.userLogout(action.token, action.server));
  } else {
    yield put(actions.createEventTimelineFail(response));
  }
}

//==========================================UPDATE EVENT TIMELINE CURRENT SCENE

export function* updateEventTimelineCurrentSceneSaga(action) {
  yield put(actions.updateEventTimelineCurrentSceneStart());
  //let response;
  //if (action.data.image.encoding === "BASE64") {
  //  const ddb = `--${action.data.image.key}`;
  //  const ending = `${ddb}--`;
  //  let body;
  //  let file;

  //  const jsonData =
  //    `${ddb}\n` +
  //    `Content-Disposition: form-data; name="data"\n` +
  //    `Content-Type: application/json\n\n` +
  //    `${JSON.stringify(action.data)}\n`;
  //  if (action.file.image) {
  //    file =
  //      `${ddb}\n` +
  //      `Content-Disposition: form-data; name="file"; filename="userAvatar"\n` +
  //      `Content-Type: ${action.file.image.mime}\n\n` +
  //      `${action.file.image.data}\n`;
  //  }

  //  body = jsonData;
  //  if (file) {
  //    body = body + file + ending;
  //  }

  //  if (!file) {
  //    body = body + ending;
  //  }
  //  response = yield fetch(
  //    `${action.server}api/vume/event/${action.eventId}/timeline/realtime`,
  //    {
  //      method: "POST",
  //      headers: {
  //        "Content-type": `multipart/form-data; boundary=${action.data.image.key}`,
  //        "X-Auth-Token": action.token
  //      },
  //      body
  //    }
  //  )
  //    .then(res => {
  //      console.log("res", res);

  //      return res.json();
  //    })
  //    .then(json => {
  //      const newResponse = {
  //        data: json,
  //        status: json.statusCode ? json.statusCode : 200
  //      };
  //      return newResponse;
  //    })
  //    .catch(err => {
  //      console.log("err", err);
  //      return err;
  //    });
  //} else {
  //  const userData = new FormData();
  //  userData.append(
  //    "data",
  //    new Blob([JSON.stringify(action.data)], { type: "application/json" })
  //  );
  //  if (action.file) {
  //    userData.append("file", action.file, "image");
  //  }
  //  const headers = {
  //    "X-Auth-Token": action.token
  //  };
  //  response = yield POST(
  //    `${action.server}api/vume/event/${action.eventId}/timeline/realtime`,
  //    userData,
  //    headers
  //  );
  //}

  //if (response && response === 503) {
  //  yield put(actions.updateEventTimelineCurrentSceneFail(response));
  //} else if (response && response.status === 200) {
  //  yield put(actions.updateEventTimelineCurrentSceneSuccess(response.data));
  //} else if (response && (response.status === 403 || response.status === 401)) {
  //  yield put(actions.userLogout(action.token, action.server));
  //} else {
  //  yield put(actions.updateEventTimelineCurrentSceneFail(response));
  //}

  let response;
  const ddb = `--B`;
  const ending = `--B--`;
  let body;
  let file;
  const jsonData =
    `${ddb}\n` +
    `Content-Disposition: form-data; name="data"; \n ` +
    `filename="blob" \n` +
    `Content-Type: application/json\n\n` +
    `${JSON.stringify(action.data)}\n`;

  if(action.file) {
    file =
      `${ddb}\n` +
      `Content-Disposition: form-data; name="files"; filename="eventAvatar"\n` +
      `Content-Type: ${action.file.mime}\n\n` +
      `${action.file.data}\n`;
  }
  body = jsonData;
  if(file) {
    body = body + file + ending;
  } else {
    body = body + ending;
  }

  response = yield fetch(
    `${action.server}api/vume/event/${action.eventId}/timeline/realtime`,
    {
      method: "POST",
      headers: {
        "Content-type": `multipart/form-data; boundary=B`,
        "X-Auth-Token": action.token
      },
      body
    }
  )
    .then(res => {
      console.log("res", res);

      return res.json();
    })
    .then(json => {
      const newResponse = {
        data: json,
        status: json.statusCode ? json.statusCode : 200
      };
      return newResponse;
    })
    .catch(err => {
      console.log("err", err);
      return err;
    });

  if(response && response === 503) {
    yield put(actions.updateEventTimelineCurrentSceneFail(response));
  } else if(response && response.status === 200) {
    yield put(actions.updateEventTimelineCurrentSceneSuccess(response.data));
  } else if(response && (response.status === 403 || response.status === 401)) {
    yield put(actions.userLogout(action.token, action.server));
  } else {
    yield put(actions.updateEventTimelineCurrentSceneFail(response));
  }
}

//=============================================================================CREATE TIMELINE TEMPLATE LIST
export function* getTimelineTemplatesListSaga(action) {
  yield put(actions.getTimelineTemplatesListStart());
  const headers = {
    "X-Auth-Token": action.token,
    "Content-type": "application/json"
  };

  const response = yield GET(
    `${action.server}api/vume/timelineTemplates`,
    headers
  );
  if (response && response === 503) {
    yield put(actions.getTimelineTemplatesListFail(response));
  } else if (response && response.status === 200) {
    yield put(actions.getTimelineTemplatesListSuccess(response.data));
  } else if (response && (response.status === 403 || response.status === 401)) {
    yield put(actions.userLogout(action.token, action.server));
  } else {
    yield put(actions.getTimelineTemplatesListFail(response));
  }
}

// =============================================CREATE TIMELINE TEMPLATE

export function* createTimelineTemplateSaga(action) {
  yield put(actions.createTimelineTemplateStart());
  let response;
  const ddb = `--B`;
  const ending = `--B--`;
  let body;
  let file = "";
  const jsonData =
    `${ddb}\n` +
    `Content-Disposition: form-data; name="data"; \n ` +
    `filename="blob" \n` +
    `Content-Type: application/json\n\n` +
    `${JSON.stringify(action.data)}\n`;

  if(action.file) {
    for(let i = 0; i < action.file.length; i++) {
      file = `${file}` +
        `${ddb}\n` +
        `Content-Disposition: form-data; name="files"; filename=${action.file[i].filename}\n` +
        `Content-Type: ${action.file[i].mime}\n\n` +
        `${action.file[i].data}\n`;
    }
  }

  body = jsonData;
  if (file) {
    body = body + file + ending;
  } else {
    body = body + ending;
  }
  response = yield fetch(`${action.server}api/vume/timelineTemplates`, {
    method: "POST",
    headers: {
      "Content-type": `multipart/form-data; boundary=B`,
      "X-Auth-Token": action.token
    },
    body
  })
    .then(res => {
      return res.json();
    })
    .then(json => {
      console.log("resss", json);
      const newResponse = {
        data: json,
        status: json.statusCode ? json.statusCode : 200
      };
      return newResponse;
    })
    .catch(err => {
      console.log("errerrerrerr", { err });
      return err;
    });

  if (response && response === 503) {
    yield put(actions.createTimelineTemplateFail(response));
  } else if (response && response.status === 200) {
    yield put(actions.createTimelineTemplateSuccess(response.data));
  } else if (response && (response.status === 403 || response.status === 401)) {
    yield put(actions.userLogout(action.token, action.server));
  } else {
    yield put(actions.createTimelineTemplateFail(response));
  }
}

//===============================================GET TIMELINE TEMPLATE

export function* getTimelineTemplateSaga(action) {
  yield put(actions.getTimelineTemplateStart());
  const headers = {
    "X-Auth-Token": action.token,
    "Content-type": "application/json"
  };

  const response = yield GET(
    `${action.server}api/vume/timelineTemplates/${action.id}`,
    headers
  );

  if (response && response === 503) {
    yield put(actions.getTimelineTemplatesListFail(response));
  } else if (response && response.status === 200) {
    yield put(actions.getTimelineTemplateSuccess(response.data));
  } else if (response && (response.status === 403 || response.status === 401)) {
    yield put(actions.userLogout(action.token, action.server));
  } else {
    yield put(actions.getTimelineTemplateFail(response));
  }
}

//============================================DELETE TIMELINE TEMPLATE

export function* deleteTimelineTemplateSaga(action) {
  yield put(actions.deleteTimelineTemplateStart());
  const headers = {
    "X-Auth-Token": action.token,
    "Content-type": "application/json"
  };

  const response = yield DELETE(
    `${action.server}api/vume/timelineTemplates/${action.id}`,
    headers
  );

  if (response && response === 503) {
    yield put(actions.deleteTimelineTemplateFail(response));
  } else if (response && response.status === 200) {
    yield put(actions.deleteTimelineTemplateSuccess(response.data));
  } else if (response && (response.status === 403 || response.status === 401)) {
    yield put(actions.userLogout(action.token, action.server));
  } else {
    yield put(actions.deleteTimelineTemplateFail(response));
  }
}
