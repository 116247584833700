import React, { Component } from "react";
import { Form, Input, Button } from "antd";
import { Label } from "../../Elements/Label/Label";

export class ChangePassword extends Component {
  state = {
    oldPassword: null,
    newPassword: null,
    confirmPassword: null
  };
  onChangeHandler = (key, e) => {
    this.setState({
      [key]: e.target.value
    });
  };
  onSubmit = e => {
    e.preventDefault();
    this.props.onSubmit(this.state);
  };
  render() {
    return (
      <div
        className={`MSD-ChangePassword ${
          this.props.className ? this.props.className : ""
        }`}
      >
        {this.props.logo ? <img src={this.props.logo} alt="logo" /> : null}
        <Form onSubmit={e => this.onSubmit(e)}>
          <Label>
            {this.props.texts
              ? this.props.texts.oldPassword
              : "Current Password *"}
          </Label>
          <Form.Item>
            <Input
              placeholder={
                this.props.texts
                  ? this.props.texts.oldPassword
                  : "Current Password *"
              }
              type="password"
              onChange={e => this.onChangeHandler("oldPassword", e)}
            />
          </Form.Item>
          <Label>
            {this.props.texts ? this.props.texts.newPassword : "New Password *"}
          </Label>
          <Form.Item>
            <Input
              placeholder={
                this.props.texts
                  ? this.props.texts.newPassword
                  : "New Password *"
              }
              type="password"
              onChange={e => this.onChangeHandler("newPassword", e)}
            />
          </Form.Item>
          <Label>
            {this.props.texts
              ? this.props.texts.confirmPassword
              : "Confirm Password *"}{" "}
          </Label>
          <Form.Item>
            <Input
              placeholder={
                this.props.texts
                  ? this.props.texts.confirmPassword
                  : "Confirm Password *"
              }
              type="password"
              onChange={e => this.onChangeHandler("confirmPassword", e)}
            />
          </Form.Item>
          <div className="changePasswordFormBtn">
            <Form.Item>
              <Button htmlType="submit" onClick={e => this.onSubmit(e)}>
                {this.props.changePasswordIcon ? (
                  <this.props.changePasswordIcon />
                ) : null}
                {this.props.texts ? this.props.texts.update : "UPDATE"}
              </Button>
            </Form.Item>
          </div>
        </Form>
      </div>
    );
  }
}
