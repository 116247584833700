import { put } from "redux-saga/effects";
import * as actions from "../actions/index";
import { PUT, POST, DELETE } from "../callApiWrapper";

export function* addReactionSaga(action) {
  yield put(actions.addReactionStart());
  const headers = {
    "X-Auth-Token": action.token
  };
  const response = yield POST(
    `${action.server}api/vume/eventReaction`,
    action.object,
    headers
  );
  if (response && response === 503) {
    yield put(actions.serverErrorSuccess(response));
  } else if (response && response === 451) {
    yield put(actions.permissionErrorSuccess(Math.random()));
  } else if (response && response.status === 200) {
    yield put(actions.addReactionSuccess(response.data));
  } else if (response && (response.status === 403 || response.status === 401)) {
    yield put(actions.userLogout(action.token, action.server));
  } else {
    yield put(actions.addReactionFail(response));
  }
}

// ====================================================

export function* editReactionSaga(action) {
  yield put(actions.editReactionStart());
  const headers = {
    "X-Auth-Token": action.token
  };
  const response = yield PUT(
    `${action.server}api/vume/eventReaction`,
    action.object,
    headers
  );
  if (response && response === 503) {
    yield put(actions.serverErrorSuccess(response));
  } else if (response && response === 451) {
    yield put(actions.permissionErrorSuccess(Math.random()));
  } else if (response && response.status === 200) {
    yield put(actions.editReactionSuccess(response.data));
  } else if (response && (response.status === 403 || response.status === 401)) {
    yield put(actions.userLogout(action.token, action.server));
  } else {
    yield put(actions.editReactionFail(response));
  }
}
// ======================================

export function* deleteReactionSaga(action) {
  yield put(actions.deleteReactionStart());
  const headers = {
    "X-Auth-Token": action.token
  };
  const response = yield DELETE(
    `${action.server}api/vume/eventReaction`,
    headers,
    action.object
  );
  if (response && response === 503) {
    yield put(actions.serverErrorSuccess(response));
  } else if (response && response === 451) {
    yield put(actions.permissionErrorSuccess(Math.random()));
  } else if (response && response.status === 200) {
    yield put(actions.deleteReactionSuccess(response.status));
  } else if (response && (response.status === 403 || response.status === 401)) {
    yield put(actions.userLogout(action.token, action.server));
  } else {
    yield put(actions.deleteReactionFail(response));
  }
}

// ===================================== SEARCH REACTIONS

export function* searchReactionsSaga(action) {
  yield put(actions.searchReactionsStart());
  let data = {
    pageModel: {
      orders: [
        {
          dir: action.searchModel.order,
          property: action.searchModel.property
            ? action.searchModel.property
            : "id"
        }
      ],
      pageNumber: action.searchModel.pageNumber,
      pageSize: action.searchModel.pageSize
    },
    searchModel: {
      eventId: action.searchModel.eventId ? action.searchModel.eventId : null,
      type: action.searchModel.type ? action.searchModel.type : null,
      userId: action.searchModel.userId ? action.searchModel.userId : null
    }
  };
  const headers = {
    "content-type": "application/json",
    "X-Auth-Token": action.token
  };

  const response = yield POST(
    `${action.server}api/vume/eventReaction/search`,
    data,
    headers
  );
  if (response && response === 503) {
    yield put(actions.serverErrorSuccess(response));
  }
  if (response && response.status === 200) {
    yield put(actions.searchReactionsSuccess(response.data));
  } else if (response && (response.status === 403 || response.status === 401)) {
    yield put(actions.userLogout(action.token, action.server));
  } else {
    yield put(actions.searchReactionsFail(response));
  }
}
