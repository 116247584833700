import React from 'react';
import PropTypes from 'prop-types';

export const Logo = (props) => {
    return (
            <img src={props.src} alt="logo" style={props.style} className={props.className}/>
     )
}


Logo.propTypes = {
    src:PropTypes.string,
  }