import * as actionTypes from "../actions/actionTypes";

const initialState = {
  users: null,
  user: null,
  response: null,
  loading: false,
  error: null
};

const getUsersStart = state => {
  const newState = {
    ...state,
    users: null,
    loading: true
  };
  return newState;
};

const getUsersSuccess = (state, action) => {
  const newState = {
    ...state,
    loading: false,
    users: action.users,
    error: null
  };
  return newState;
};
const getUsersFail = (state, action) => {
  const newState = {
    ...state,
    loading: false,
    users: null,
    error: action.error
  };
  return newState;
};
// ================================================ GET Users
const getUserByIdStart = state => {
  const newState = {
    ...state,
    loading: true,
    error: null,
    user: null
  };
  return newState;
};
const getUserByIdSuccess = (state, action) => {
  const newState = {
    ...state,
    loading: false,
    user: action.user,
    error: null
  };
  return newState;
};

const getUserByIdFail = (state, action) => {
  const newState = {
    ...state,
    user: null,
    loading: false,
    error: action.error
  };
  return newState;
};

// ============================================== ADD USER

const addUserStart = state => {
  const newState = {
    ...state,
    loading: true,
    error: null,
    response: null
  };
  return newState;
};
const addUserSuccess = (state, action) => {
  const newState = {
    ...state,
    loading: false,
    response: action.response
  };
  return newState;
};

const addUserFail = (state, action) => {
  const newState = {
    ...state,
    loading: false,
    error: action.error
  };
  return newState;
};

// ============================================== DELETE USER

const deleteUserStart = state => {
  const newState = {
    ...state,
    loading: true,
    error: null,
    response: null
  };
  return newState;
};
const deleteUserSuccess = (state, action) => {
  const newState = {
    ...state,
    loading: false,
    response: action.response,
    error: null
  };
  return newState;
};

const deleteUserFail = (state, action) => {
  const newState = {
    ...state,
    response: null,
    loading: false,
    error: action.error
  };
  return newState;
};

// ============================================== EDIT USER

const editUserStart = state => {
  const newState = {
    ...state,
    response: null,
    loading: true
  };
  return newState;
};
const editUserSuccess = (state, action) => {
  const newState = {
    ...state,
    loading: false,
    response: action.response,
    error: null
  };
  return newState;
};

const editUserFail = (state, action) => {
  const newState = {
    ...state,
    loading: false,
    response: null,
    error: action.error
  };
  return newState;
};

// =========================== SEARCH USERS

const searchUsersStart = state => {
  const newState = {
    ...state,
    users: null,
    loading: true
  };
  return newState;
};
const searchUsersSuccess = (state, action) => {
  const newState = {
    ...state,
    loading: false,
    users: action.users,
    error: null
  };
  return newState;
};

const searchUsersFail = (state, action) => {
  const newState = {
    ...state,
    loading: false,
    users: null,
    error: action.error
  };
  return newState;
};

export const usersReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_ALL_USER_START:
      return getUsersStart(state);
    case actionTypes.GET_ALL_USER_SUCCESS:
      return getUsersSuccess(state, action);
    case actionTypes.GET_ALL_USER_FAIL:
      return getUsersFail(state, action);

    case actionTypes.SEARCH_USERS_START:
      return searchUsersStart(state);
    case actionTypes.SEARCH_USERS_SUCCESS:
      return searchUsersSuccess(state, action);
    case actionTypes.SEARCH_USERS_FAIL:
      return searchUsersFail(state, action);

    case actionTypes.GET_USER_BY_ID_START:
      return getUserByIdStart(state);
    case actionTypes.GET_USER_BY_ID_SUCCESS:
      return getUserByIdSuccess(state, action);
    case actionTypes.GET_USER_BY_ID_FAIL:
      return getUserByIdFail(state, action);

    case actionTypes.DELETE_USER_START:
      return deleteUserStart(state);
    case actionTypes.DELETE_USER_SUCCESS:
      return deleteUserSuccess(state, action);
    case actionTypes.DELETE_USER_FAIL:
      return deleteUserFail(state, action);

    case actionTypes.EDIT_USER_START:
      return editUserStart(state);
    case actionTypes.EDIT_USER_SUCCESS:
      return editUserSuccess(state, action);
    case actionTypes.EDIT_USER_FAIL:
      return editUserFail(state, action);

    case actionTypes.ADD_USER_START:
      return addUserStart(state);
    case actionTypes.ADD_USER_SUCCESS:
      return addUserSuccess(state, action);
    case actionTypes.ADD_USER_FAIL:
      return addUserFail(state, action);

    default:
      return state;
  }
};
