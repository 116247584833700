import * as actionTypes from "../actions/actionTypes";

const initialState = {
  data: null,
  loading: false,
  error: null
};

const getMetaDataStart = state => {
  const newState = {
    ...state,
    loading: true,
    error: null,
    data: null
  };
  return newState;
};
const getMetaDataSuccess = (state, action) => {
  const newState = {
    ...state,
    loading: false,
    data: action.data,
    error: null
  };
  return newState;
};

const getMetaDataFail = (state, action) => {
  const newState = {
    ...state,
    data: null,
    loading: false,
    error: action.error
  };
  return newState;
};

export const getMetaDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_META_DATA_START:
      return getMetaDataStart(state);
    case actionTypes.GET_META_DATA_SUCCESS:
      return getMetaDataSuccess(state, action);
    case actionTypes.GET_META_DATA_FAIL:
      return getMetaDataFail(state, action);
    default:
      return state;
  }
};
