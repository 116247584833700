import * as actionTypes from "./actionTypes";

export const getMyPlan = (token, server) => {
  return {
    type: actionTypes.GET_MY_PLAN,
    token: token,
    server: server
  };
};

export const getMyPlanStart = () => {
  return {
    type: actionTypes.GET_MY_PLAN_START
  };
};

export const getMyPlanSuccess = myPlan => {
  return {
    type: actionTypes.GET_MY_PLAN_SUCCESS,
    myPlan: myPlan
  };
};
export const getMyPlanFail = error => {
  return {
    type: actionTypes.GET_MY_PLAN_FAIL,
    error: error
  };
};

//   ========================= SET FREE TO PREMIUM

export const setFreePremium = (token,promotionCode,server) => {
  return {
    type: actionTypes.SET_FREE_PREMIUM,
    token: token,
    promotionCode:promotionCode,
    server: server
  };
};

export const setFreePremiumStart = () => {
  return {
    type: actionTypes.SET_FREE_PREMIUM_START
  };
};

export const setFreePremiumSuccess = response => {
  return {
    type: actionTypes.SET_FREE_PREMIUM_SUCCESS,
    response: response
  };
};
export const setFreePremiumFail = error => {
  return {
    type: actionTypes.SET_FREE_PREMIUM_FAIL,
    error: error
  };
};

// ===================================================== GET BY ID

export const getUserPlanById = (token, id, server) => {
  return {
    type: actionTypes.GET_USER_PLAN_BY_ID,
    token: token,
    id: id,
    server: server
  };
};

export const getUserPlanByIdStart = () => {
  return {
    type: actionTypes.GET_USER_PLAN_BY_ID_START
  };
};

export const getUserPlanByIdSuccess = userPlan => {
  return {
    type: actionTypes.GET_USER_PLAN_BY_ID_SUCCESS,
    userPlan: userPlan
  };
};
export const getUserPlanByIdFail = error => {
  return {
    type: actionTypes.GET_USER_PLAN_BY_ID_FAIL,
    error: error
  };
};

export const assignPlan = (token,data,server) => {
  return {
    type: actionTypes.ASSIGN_USER_PLANS,
    data: data,
    server:server,
    token:token
  };
};

export const assignPlanStart = () => {
  return {
    type: actionTypes.ASSIGN_USER_PLAN_START,
  };
};

export const assignPlanSuccess = response => {

  return {
    type: actionTypes.ASSIGN_USER_PLAN_SUCCESS,
    response: response,
  };
};

export const assignPlanFail = error => {
  return {
    type: actionTypes.ASSIGN_USER_PLAN_FAIL,
    error: error
  };
};