import * as actionTypes from "../actions/actionTypes";

const initialState = {
  loading: false,
  response: null,
  error: ""
};

//==================get event timeline==================
const getEventTimelineStart = state => {
  const newState = {
    ...state,
    loading: true,
    response: null
  };
  return newState;
};

const getEventTimelineSuccess = (state, action) => {
  const newState = {
    ...state,
    loading: false,
    response: action.response,
    error: null
  };
  return newState;
};

const getEventTimelineFail = (state, action) => {
  const newState = {
    ...state,
    loading: false,
    response: null,
    error: action.error
  };
  return newState;
};

//==================create event timeline==================
const createEventTimelineStart = state => {
  const newState = {
    ...state,
    loading: true,
    response: null
  };
  return newState;
};

const createEventTimelineSuccess = (state, action) => {
  const newState = {
    ...state,
    loading: false,
    response: action.response,
    error: null
  };
  return newState;
};

const createEventTimelineFail = (state, action) => {
  const newState = {
    ...state,
    loading: false,
    response: null,
    error: action.response
  };
  return newState;
};

//==============update event timeline current scene===============
const updateEventTimelineCurrentSceneStart = state => {
  const newState = {
    ...state,
    loading: true,
    response: null
  };
  return newState;
};

const updateEventTimelineCurrentSceneSuccess = (state, action) => {
  const newState = {
    ...state,
    loading: false,
    response: action.response,
    error: null
  };
  return newState;
};

const updateEventTimelineCurrentSceneFail = (state, action) => {
  const newState = {
    ...state,
    loading: false,
    response: null,
    error: action.response
  };
  return newState;
};

//==================get timeline templates list==================
const getTimelineTemplatesListStart = state => {
  const newState = {
    ...state,
    loading: true,
    response: null
  };
  return newState;
};

const getTimelineTemplatesListSuccess = (state, action) => {
  console.log("acccccc", state, action);

  const newState = {
    ...state,
    loading: false,
    response: action.response,
    error: null
  };
  return newState;
};

const getTimelineTemplatesListFail = (state, action) => {
  console.log("acccccc", state, action);
  const newState = {
    ...state,
    loading: false,
    response: null,
    error: action.response
  };
  return newState;
};

//==============create timeline template===============

const createTimelineTemplateStart = state => {
  const newState = {
    ...state,
    loading: true,
    response: null
  };
  return newState;
};

const createTimelineTemplateSuccess = (state, action) => {
  const newState = {
    ...state,
    loading: false,
    response: action.response,
    error: null
  };
  return newState;
};

const createTimelineTemplateFail = (state, action) => {
  const newState = {
    ...state,
    loading: false,
    response: null,
    error: action.response
  };
  return newState;
};

//==============get timeline template===============

const getTimelineTemplateStart = state => {
  const newState = {
    ...state,
    loading: true,
    response: null
  };
  return newState;
};

const getTimelineTemplateSuccess = (state, action) => {
  const newState = {
    ...state,
    loading: false,
    response: action.response,
    error: null
  };
  return newState;
};

const getTimelineTemplateFail = (state, action) => {
  const newState = {
    ...state,
    loading: false,
    response: null,
    error: action.response
  };
  return newState;
};

//==============delete timeline template===============

const deleteTimelineTemplateStart = state => {
  const newState = {
    ...state,
    loading: true,
    response: null
  };
  return newState;
};

const deleteTimelineTemplateSuccess = (state, action) => {
  const newState = {
    ...state,
    loading: false,
    response: action.response,
    error: null
  };
  return newState;
};

const deleteTimelineTemplateFail = (state, action) => {
  const newState = {
    ...state,
    loading: null,
    response: null,
    erorr: action.response
  };
  return newState;
};

//===================director reducer===================

export const directorReducer = (state = initialState, action) => {
  switch (action.type) {
    //get event timeline
    case actionTypes.GET_EVENT_TIMELINE_START:
      return getEventTimelineStart(state);
    case actionTypes.GET_EVENT_TIMELINE_SUCCESS:
      return getEventTimelineSuccess(state, action);
    case actionTypes.GET_EVENT_TIMELINE_FAIL:
      return getEventTimelineFail(state, action);

    //create event timeline
    case actionTypes.CREATE_EVENT_TIMELINE_START:
      return createEventTimelineStart(state);
    case actionTypes.CREATE_EVENT_TIMELINE_SUCCESS:
      return createEventTimelineSuccess(state, action);
    case actionTypes.CREATE_EVENT_TIMELINE_FAIL:
      return createEventTimelineFail(state, action);

    //update event timeline current scene
    case actionTypes.UPDATE_EVENT_TIMELINE_CURRENT_SCENE_START:
      return updateEventTimelineCurrentSceneStart(state);
    case actionTypes.UPDATE_EVENT_TIMELINE_CURRENT_SCENE_SUCCESS:
      return updateEventTimelineCurrentSceneSuccess(state, action);
    case actionTypes.UPDATE_EVENT_TIMELINE_CURRENT_SCENE_FAIL:
      return updateEventTimelineCurrentSceneFail(state, action);

    //get timeline templates list
    case actionTypes.GET_TIMELINE_TEMPLATES_LIST_START:
      return getTimelineTemplatesListStart(state);
    case actionTypes.GET_TIMELINE_TEMPLATES_LIST_SUCCESS:
      return getTimelineTemplatesListSuccess(state, action);
    case actionTypes.GET_TIMELINE_TEMPLATES_LIST_FAIL:
      return getTimelineTemplatesListFail(state, action);

    //create timeline template
    case actionTypes.CREATE_TIMELINE_TEMPLATE_START:
      return createTimelineTemplateStart(state);
    case actionTypes.CREATE_TIMELINE_TEMPLATE_SUCCESS:
      return createTimelineTemplateSuccess(state, action);
    case actionTypes.CREATE_TIMELINE_TEMPLATE_FAIL:
      return createTimelineTemplateFail(state, action);

    //get timeline template
    case actionTypes.GET_TIMELINE_TEMPLATE_START:
      return getTimelineTemplateStart(state);
    case actionTypes.GET_TIMELINE_TEMPLATE_SUCCESS:
      return getTimelineTemplateSuccess(state, action);
    case actionTypes.GET_TIMELINE_TEMPLATE_FAIL:
      return getTimelineTemplateFail(state, action);

    //delete timeline template
    case actionTypes.DELETE_TIMELINE_TEMPLATE_START:
      return deleteTimelineTemplateStart(state);
    case actionTypes.DELETE_TIMELINE_TEMPLATE_SUCCESS:
      return deleteTimelineTemplateSuccess(state, action);
    case actionTypes.DELETE_TIMELINE_TEMPLATE_FAIL:
      return deleteTimelineTemplateFail(state, action);

    default:
      return state;
  }
};
