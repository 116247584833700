import * as actionTypes from "../actions/actionTypes";

const initialState = {
  user: null,
  loading: false,
  error: null,
  response: null,
  logout: null
};

const userLoginStart = state => {
  const newState = {
    ...state,
    loading: {
      login: true
    },
    user: null,
    error: null
  };
  return newState;
};
const userLoginSuccess = (state, action) => {
  const newState = {
    ...state,
    loading: false,
    user: action.user,
    error: null
  };
  return newState;
};

const userLoginFail = (state, action) => {
  const newState = {
    ...state,
    user: null,
    loading: false,
    error: action.error
  };
  return newState;
};

// ================================================================

const userLogout = state => {
  const newState = {
    ...state,
    loading: true,
    logout: null
  };
  return newState;
};
const userLogoutSuccess = (state, action) => {
  const newState = {
    ...state,
    logout: action.logout,
    loading: null,
    user: null,
    error: null
  };
  return newState;
};

// =============================================================== FORGOT PASSWORD

const forgotPasswordSendCodeStart = state => {
  const newState = {
    ...state,
    loading: true,
    response: null,
    error: null
  };
  return newState;
};
const forgotPasswordSendCodeSuccess = (state, action) => {
  const newState = {
    ...state,
    loading: false,
    response: action.response,
    error: null
  };
  return newState;
};

const forgotPasswordSendCodeFail = (state, action) => {
  const newState = {
    ...state,
    response: null,
    loading: false,
    error: action.error
  };
  return newState;
};

// ================================ CHECK CODE

const forgotPasswordCheckCodeStart = state => {
  const newState = {
    ...state,
    loading: true,
    response: null,
    error: null
  };
  return newState;
};
const forgotPasswordCheckCodeSuccess = (state, action) => {
  const newState = {
    ...state,
    loading: false,
    response: action.response,
    error: null
  };
  return newState;
};

const forgotPasswordCheckCodeFail = (state, action) => {
  const newState = {
    ...state,
    response: null,
    loading: false,
    error: action.error
  };
  return newState;
};

// ================================ FORGOT PASSWORD CHANGE PASSWORD

const forgotPasswordChangePasswordStart = state => {
  const newState = {
    ...state,
    loading: true,
    response: null,
    error: null
  };
  return newState;
};
const forgotPasswordChangePasswordSuccess = (state, action) => {
  const newState = {
    ...state,
    loading: false,
    response: action.response,
    error: null
  };
  return newState;
};

const forgotPasswordChangePasswordFail = (state, action) => {
  const newState = {
    ...state,
    response: null,
    loading: false,
    error: action.error
  };
  return newState;
};

// ================================ USER REGISTER SEND CODE

const userRegisterSendCodeStart = state => {
  const newState = {
    loading: true,
    response: null,
    error: null
  };
  return newState;
};
const userRegisterSendCodeSuccess = (state, action) => {
  const newState = {
    ...state,
    loading: false,
    response: action.response,
    error: null
  };
  return newState;
};

const userRegisterSendCodeFail = (state, action) => {
  const newState = {
    ...state,
    response: null,
    loading: false,
    error: action.error
  };
  return newState;
};

// ================================ USER REGISTER REGISTER

const userRegisterRegisterStart = state => {
  const newState = {
    ...state,
    loading: true,
    response: null,
    error: null
  };
  return newState;
};
const userRegisterRegisterSuccess = (state, action) => {
  const newState = {
    ...state,
    loading: false,
    response: action.response,
    error: null
  };
  return newState;
};

const userRegisterRegisterFail = (state, action) => {
  const newState = {
    ...state,
    response: null,
    loading: false,
    error: action.error
  };
  return newState;
};

// ================================ USER SOCIAL LOGIN

const userSocialLoginStart = state => {
  const newState = {
    ...state,
    loading: {
      login: true
    },
    response: null,
    error: null
  };
  return newState;
};
const userSocialLoginSuccess = (state, action) => {
  const newState = {
    ...state,
    loading: false,
    response: action.response,
    error: null
  };
  return newState;
};

const userSocialLoginFail = (state, action) => {
  const newState = {
    ...state,
    response: null,
    loading: false,
    error: action.error
  };
  return newState;
};

export const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.USER_LOGIN_START:
      return userLoginStart(state);
    case actionTypes.USER_LOGIN_SUCCESS:
      return userLoginSuccess(state, action);
    case actionTypes.USER_LOGIN_FAIL:
      return userLoginFail(state, action);
    case actionTypes.USER_LOGOUT:
      return userLogout(state, action);
    case actionTypes.USER_LOGOUT_SUCCESS:
      return userLogoutSuccess(state, action);

    case actionTypes.FORGOT_PASSWORD_SEND_CODE_START:
      return forgotPasswordSendCodeStart(state);
    case actionTypes.FORGOT_PASSWORD_SEND_CODE_SUCCESS:
      return forgotPasswordSendCodeSuccess(state, action);
    case actionTypes.FORGOT_PASSWORD_SEND_CODE_FAIL:
      return forgotPasswordSendCodeFail(state, action);

    case actionTypes.FORGOT_PASSWORD_CHECK_CODE_START:
      return forgotPasswordCheckCodeStart(state);
    case actionTypes.FORGOT_PASSWORD_CHECK_CODE_SUCCESS:
      return forgotPasswordCheckCodeSuccess(state, action);
    case actionTypes.FORGOT_PASSWORD_CHECK_CODE_FAIL:
      return forgotPasswordCheckCodeFail(state, action);

    case actionTypes.FORGOT_PASSWORD_CHANGE_PASSWORD_START:
      return forgotPasswordChangePasswordStart(state);
    case actionTypes.FORGOT_PASSWORD_CHANGE_PASSWORD_SUCCESS:
      return forgotPasswordChangePasswordSuccess(state, action);
    case actionTypes.FORGOT_PASSWORD_CHANGE_PASSWORD_FAIL:
      return forgotPasswordChangePasswordFail(state, action);

    case actionTypes.USER_REGISTER_SEND_CODE_START:
      return userRegisterSendCodeStart(state);
    case actionTypes.USER_REGISTER_SEND_CODE_SUCCESS:
      return userRegisterSendCodeSuccess(state, action);
    case actionTypes.USER_REGISTER_SEND_CODE_FAIL:
      return userRegisterSendCodeFail(state, action);

    case actionTypes.USER_REGISTER_REGISTER_START:
      return userRegisterRegisterStart(state);
    case actionTypes.USER_REGISTER_REGISTER_SUCCESS:
      return userRegisterRegisterSuccess(state, action);
    case actionTypes.USER_REGISTER_REGISTER_FAIL:
      return userRegisterRegisterFail(state, action);

    case actionTypes.USER_SOCIAL_LOGIN_START:
      return userSocialLoginStart(state);
    case actionTypes.USER_SOCIAL_LOGIN_SUCCESS:
      return userSocialLoginSuccess(state, action);
    case actionTypes.USER_SOCIAL_LOGIN_FAIL:
      return userSocialLoginFail(state, action);

    default:
      return state;
  }
};
