import * as actionTypes from "./actionTypes";

export const addDisplayMix = (token, object, server) => {
  return {
    type: actionTypes.ADD_DISPLAY_MIX,
    object: object,
    server: server,
    token: token
  };
};
export const addDisplayMixStart = () => {
  return {
    type: actionTypes.ADD_DISPLAY_MIX_START
  };
};

export const addDisplayMixSuccess = response => {
  return {
    type: actionTypes.ADD_DISPLAY_MIX_SUCCESS,
    response: response
  };
};
export const addDisplayMixFail = error => {
  return {
    type: actionTypes.ADD_DISPLAY_MIX_FAIL,
    error: error
  };
};

// ================================================ searchDisplayMix

export const searchDisplayMix = (token, object, server) => {
  return {
    type: actionTypes.SEARCH_DISPLAY_MIX,
    object: object,
    server: server,
    token: token
  };
};
export const searchDisplayMixStart = () => {
  return {
    type: actionTypes.SEARCH_DISPLAY_MIX_START
  };
};

export const searchDisplayMixSuccess = mixes => {
  return {
    type: actionTypes.SEARCH_DISPLAY_MIX_SUCCESS,
    mixes: mixes
  };
};
export const searchDisplayMixFail = error => {
  return {
    type: actionTypes.SEARCH_DISPLAY_MIX_FAIL,
    error: error
  };
};


// ===========

export const deleteDisplayMix = (token, id, server) => {
  return {
    type: actionTypes.DELETE_DISPLAY_MIX,
    id: id,
    server: server,
    token: token
  };
};
export const deleteDisplayMixStart = () => {
  return {
    type: actionTypes.DELETE_DISPLAY_MIX_START
  };
};

export const deleteDisplayMixSuccess = deleteResponse => {
  return {
    type: actionTypes.DELETE_DISPLAY_MIX_SUCCESS,
    deleteResponse: deleteResponse
  };
};
export const deleteDisplayMixFail = error => {
  return {
    type: actionTypes.DELETE_DISPLAY_MIX_FAIL,
    error: error
  };
};