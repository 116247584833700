
import * as actionTypes from './actionTypes'

  
  export const serverErrorSuccess = response => {
    return {
      type: actionTypes.SERVER_ERROR_SUCCESS,
      response:response
    };
  };

  export const permissionErrorSuccess = permissionError => {
    return {
      type: actionTypes.PERMISSION_ERROR_SUCCESS,
      permissionError: permissionError
    };
  };