import * as actionTypes from "./actionTypes";

//==============get director timeline===============

export const getEventTimeline = (token, eventId, server) => {
  return {
    type: actionTypes.GET_EVENT_TIMELINE,
    token: token,
    server: server,
    eventId: eventId
  };
};

export const getEventTimelineStart = () => {
  return {
    type: actionTypes.GET_EVENT_TIMELINE_START
  };
};

export const getEventTimelineSuccess = response => {
  return {
    type: actionTypes.GET_EVENT_TIMELINE_SUCCESS,
    response: response
  };
};

export const getEventTimelineFail = error => {
  return {
    type: actionTypes.GET_EVENT_TIMELINE_FAIL,
    error: error
  };
};

//==============create director timeline===============

export const createEventTimeline = (token, data, file, eventId, server) => {
  return {
    type: actionTypes.CREATE_EVENT_TIMELINE,
    token: token,
    server: server,
    data: data,
    file: file,
    eventId: eventId
  };
};

export const createEventTimelineStart = () => {
  return {
    type: actionTypes.CREATE_EVENT_TIMELINE_START
  };
};

export const createEventTimelineSuccess = response => {
  return {
    type: actionTypes.CREATE_EVENT_TIMELINE_SUCCESS,
    response: response
  };
};

export const createEventTimelineFail = error => {
  return {
    type: actionTypes.CREATE_EVENT_TIMELINE_FAIL,
    error: error
  };
};

//==============update event timeline current scene===============
export const updateEventTimelineCurrentScene = (token, data, file, eventId, server) => {
  return {
    type: actionTypes.UPDATE_EVENT_TIMELINE_CURRENT_SCENE,
    token: token,
    server: server,
    data: data,
    file: file,
    eventId: eventId
  };
};

export const updateEventTimelineCurrentSceneStart = () => {
  return {
    type: actionTypes.UPDATE_EVENT_TIMELINE_CURRENT_SCENE_START
  };
};

export const updateEventTimelineCurrentSceneSuccess = response => {
  return {
    type: actionTypes.UPDATE_EVENT_TIMELINE_CURRENT_SCENE_SUCCESS,
    response: response
  };
};

export const updateEventTimelineCurrentSceneFail = error => {
  return {
    type: actionTypes.UPDATE_EVENT_TIMELINE_CURRENT_SCENE_FAIL,
    error: error
  };
};

//==============get timeline templates list===============

export const getTimelineTemplatesList = (token, server) => {
  return {
    type: actionTypes.GET_TIMELINE_TEMPLATES_LIST,
    server: server,
    token: token
  };
};

export const getTimelineTemplatesListStart = () => {
  return {
    type: actionTypes.GET_TIMELINE_TEMPLATES_LIST_START
  };
};

export const getTimelineTemplatesListSuccess = response => {
  console.log("succcesss", response);
  return {
    type: actionTypes.GET_TIMELINE_TEMPLATES_LIST_SUCCESS,
    response: response
  };
};

export const getTimelineTemplatesListFail = error => {
  console.log("errorerrorerror", error);

  return {
    type: actionTypes.GET_TIMELINE_TEMPLATES_LIST_FAIL,
    error: error
  };
};

//==============create timeline template===============

export const createTimelineTemplate = (token, data, file, server) => {
  return {
    type: actionTypes.CREATE_TIMELINE_TEMPLATE,
    token: token,
    server: server,
    data: data,
    file: file
  };
};

export const createTimelineTemplateStart = () => {
  return {
    type: actionTypes.CREATE_TIMELINE_TEMPLATE_START
  };
};

export const createTimelineTemplateSuccess = response => {
  return {
    type: actionTypes.CREATE_TIMELINE_TEMPLATE_SUCCESS,
    response: response
  };
};

export const createTimelineTemplateFail = error => {
  return {
    type: actionTypes.CREATE_TIMELINE_TEMPLATE_FAIL,
    error: error
  };
};

//==============get timeline template===============

export const getTimelineTemplate = (token, server) => {
  return {
    type: actionTypes.GET_TIMELINE_TEMPLATE,
    server: server,
    token: token
  };
};

export const getTimelineTemplateStart = () => {
  return {
    type: actionTypes.GET_TIMELINE_TEMPLATE_START
  };
};

export const getTimelineTemplateSuccess = response => {
  return {
    type: actionTypes.GET_TIMELINE_TEMPLATE_SUCCESS,
    response: response
  };
};

export const getTimelineTemplateFail = error => {
  return {
    type: actionTypes.GET_TIMELINE_TEMPLATE_FAIL,
    error: error
  };
};

//==============delete timeline template===============

export const deleteTimelineTemplate = (token, id, server) => {
  return {
    type: actionTypes.DELETE_TIMELINE_TEMPLATE,
    id: id,
    server: server,
    token: token
  };
};

export const deleteTimelineTemplateStart = () => {
  return {
    type: actionTypes.DELETE_TIMELINE_TEMPLATE_START
  };
};

export const deleteTimelineTemplateSuccess = response => {
  return {
    type: actionTypes.DELETE_TIMELINE_TEMPLATE_SUCCESS,
    response: response
  };
};

export const deleteTimelineTemplateFail = error => {
  return {
    type: actionTypes.DELETE_TIMELINE_TEMPLATE_FAIL,
    error: error
  };
};
