import * as actionTypes from "../actions/actionTypes";

const initialState = {
  streams: null,
  stream: null,
  loading: false,
  error: null
};

// =============================================================== ADD STREAM
const addStreamsStart = state => {
  const newState = {
    ...state,
    streams: null,
    loading: true
  };
  return newState;
};
const addStreamsSuccess = (state, action) => {
  const newState = {
    ...state,
    loading: false,
    streams: action.streams,
    error: null
  };
  return newState;
};

const addStreamsFail = (state, action) => {
  const newState = {
    ...state,
    loading: false,
    streams: null,
    error: action.error
  };
  return newState;
};

// =============================================================== UPDATE STREAM
const updateStreamStart = state => {
  const newState = {
    ...state,
    stream: null,
    loading: true
  };
  return newState;
};
const updateStreamSuccess = (state, action) => {
  const newState = {
    ...state,
    loading: false,
    stream: action.stream,
    error: null
  };
  return newState;
};

const updateStreamFail = (state, action) => {
  const newState = {
    ...state,
    loading: false,
    stream: null,
    error: action.error
  };
  return newState;
};

// =============================================================== DELETE STREAM
const deleteStreamStart = state => {
  const newState = {
    ...state,
    stream: null,
    loading: true
  };
  return newState;
};
const deleteStreamSuccess = (state, action) => {
  const newState = {
    ...state,
    loading: false,
    stream: action.stream,
    error: null
  };
  return newState;
};

const deleteStreamFail = (state, action) => {
  const newState = {
    ...state,
    loading: false,
    stream: null,
    error: action.error
  };
  return newState;
};

export const streamsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ADD_STREAMS_START:
      return addStreamsStart(state);
    case actionTypes.ADD_STREAMS_SUCCESS:
      return addStreamsSuccess(state, action);
    case actionTypes.ADD_STREAMS_FAIL:
      return addStreamsFail(state, action);

    case actionTypes.UPDATE_STREAM_START:
      return updateStreamStart(state);
    case actionTypes.UPDATE_STREAM_SUCCESS:
      return updateStreamSuccess(state, action);
    case actionTypes.UPDATE_STREAM_FAIL:
      return updateStreamFail(state, action);

    case actionTypes.DELETE_STREAM_START:
      return deleteStreamStart(state);
    case actionTypes.DELETE_STREAM_SUCCESS:
      return deleteStreamSuccess(state, action);
    case actionTypes.DELETE_STREAM_FAIL:
      return deleteStreamFail(state, action);

    default:
      return state;
  }
};
