import React from "react";
import volumeMuteIcon from "./volume-mute.svg";
import "./Unmute.css";

export const Unmute = props => {
  return (
    <span className="MSD-unmuted" onClick={props.onClick}>
      {!props.hideMuteIcon && <img src={volumeMuteIcon} alt="volume" />}
      {props.text}
    </span>
  );
};
