import React from 'react'
import styles from './applicationError.css'

export const applicationError = (props) =>{
    return(
        <div className={styles.applicationErrorPage}>
           <h3>
               Oops
           </h3>
           <p>
               Something went wrong in the application. Do you want to report it?
           </p>
           <button className={styles.reportBug} onClick={() =>window.location.href=`mailto:troubleshoot@viulive.com?subject=error in application&body=${props.error}+${JSON.stringify(props.info)}`}>
               Report the issue
           </button>
           <button onClick={props.homePage} className={styles.backHome}>
               Back to home
           </button>
        </div>
    )
}

