import * as actionTypes from "../actions/actionTypes";

const initialState = {
  response: null,
  userPlan: null,
  myPlan: null,
  loading: false,
  error: null
};

const getMyPlanStart = state => {
  const newState = {
    ...state,
    loading: true,
    error: null,
    myPlan: null
  };
  return newState;
};
const getMyPlanSuccess = (state, action) => {
  const newState = {
    ...state,
    loading: false,
    myPlan: action.myPlan,
    error: null
  };
  return newState;
};

const getMyPlanFail = (state, action) => {
  const newState = {
    ...state,
    myPlan: null,
    loading: false,
    error: action.error
  };
  return newState;
};

// ==============================

const getUserPlanByIdStart = state => {
  const newState = {
    ...state,
    loading: true,
    error: null,
    userPlan: null
  };
  return newState;
};
const getUserPlanByIdSuccess = (state, action) => {
  const newState = {
    ...state,
    loading: false,
    userPlan: action.userPlan,
    error: null
  };
  return newState;
};

const getUserPlanByIdFail = (state, action) => {
  const newState = {
    ...state,
    userPlan: null,
    loading: false,
    error: action.error
  };
  return newState;
};
const assignPlanStart = state => {
  const newState = {
    ...state,
    response: null,
    loading: true
  };
  return newState;
};
const assignPlanSuccess = (state, action) => {
  const newState = {
    ...state,
    loading: false,
    response: action.response,
    error: null
  };
  return newState;
};

const assignPlanFail = (state, action) => {
  const newState = {
    ...state,
    loading: false,
    response: null,
    error: action.error
  };
  return newState;
};
// ==============================

const setFreePremiumStart = state => {
  const newState = {
    ...state,
    loading: true,
    error: null,
    response: null
  };
  return newState;
};
const setFreePremiumSuccess = (state, action) => {
  const newState = {
    loading: false,
    response: action.response,
    error: null
  };
  return newState;
};

const setFreePremiumFail = (state, action) => {
  const newState = {
    ...state,
    response: null,
    loading: false,
    error: action.error
  };
  return newState;
};

export const userPlanReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_MY_PLAN_START:
      return getMyPlanStart(state);
    case actionTypes.GET_MY_PLAN_SUCCESS:
      return getMyPlanSuccess(state, action);
    case actionTypes.GET_MY_PLAN_FAIL:
      return getMyPlanFail(state, action);

    case actionTypes.GET_USER_PLAN_BY_ID_START:
      return getUserPlanByIdStart(state);
    case actionTypes.GET_USER_PLAN_BY_ID_SUCCESS:
      return getUserPlanByIdSuccess(state, action);
    case actionTypes.GET_USER_PLAN_BY_ID_FAIL:
      return getUserPlanByIdFail(state, action);

    case actionTypes.SET_FREE_PREMIUM_START:
      return setFreePremiumStart(state);
    case actionTypes.SET_FREE_PREMIUM_SUCCESS:
      return setFreePremiumSuccess(state, action);
    case actionTypes.SET_FREE_PREMIUM_FAIL:
      return setFreePremiumFail(state, action);

      
      case actionTypes.ASSIGN_USER_PLAN_START:
        return assignPlanStart(state);
      case actionTypes.ASSIGN_USER_PLAN_SUCCESS:
        return assignPlanSuccess(state, action);
      case actionTypes.ASSIGN_USER_PLAN_FAIL:
        return assignPlanFail(state, action);
    default:
      return state;
  }
};
