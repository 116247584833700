import { put } from "redux-saga/effects";
import * as actions from "../actions/index";
import { GET, POST } from "../callApiWrapper";

export function* getViubitBalanceSaga(action) {
  yield put(actions.getViubitBalanceStart());
  const headers = {
    "X-Auth-Token": action.token,
    "Content-type": "application/json"
  };
  const response = yield GET(
    `${action.server}api/monetizing/viubitTransaction/balance`,
    headers
  );
  if (response && response === 503) {
    yield put(actions.serverErrorSuccess(response));
  } else if (response && response.status === 200) {
    yield put(actions.getViubitBalanceSuccess(response.data));
  } else if ((response && response.status === 403) || response.status === 401) {
    yield put(actions.userLogout(action.token, action.server));
  } else {
    yield put(actions.getViubitBalanceFail(response));
  }
}

// =========================

export function* sendGiftSaga(action) {
  yield put(actions.sendGiftStart());
  const headers = {
    "X-Auth-Token": action.token,
    "Content-type": "application/json"
  };
  const response = yield POST(
    `${action.server}api/monetizing/viubitTransaction`,
    action.req,
    headers
  );
  if (response && response === 503) {
    yield put(actions.serverErrorSuccess(response));
  } else if (response && response.status === 200) {
    yield put(actions.sendGiftSuccess(response.data));
  } else if (response && (response.status === 403 || response.status === 401)) {
    yield put(actions.userLogout(action.token, action.server));
  } else {
    yield put(actions.sendGiftFail(response));
  }
}

// =========================

export function* getAllViubitPackagesSaga(action) {
  yield put(actions.getAllViubitPackagesStart());
  const headers = {
    "X-Auth-Token": action.token,
    "Content-type": "application/json"
  };
  const response = yield GET(
    `${action.server}api/monetizing/product/viubitpackage/all`,
    headers
  );
  if (response && response === 503) {
    yield put(actions.serverErrorSuccess(response));
  } else if (response && response.status === 200) {
    yield put(actions.getAllViubitPackagesSuccess(response.data));
  } else if (response && (response.status === 403 || response.status === 401)) {
    yield put(actions.userLogout(action.token, action.server));
  } else {
    yield put(actions.getAllViubitPackagesFail(response));
  }
}

// =========================

export function* searchViubitHistorySaga(action) {
  yield put(actions.searchViubitHistoryStart());
  const headers = {
    "X-Auth-Token": action.token,
    "Content-type": "application/json"
  };
  const response = yield POST(
    `${action.server}api/monetizing/viubitTransaction/history`,
    action.req,
    headers
  );
  if (response && response === 503) {
    yield put(actions.serverErrorSuccess(response));
  } else if (response && response.status === 200) {
    yield put(actions.searchViubitHistorySuccess(response.data));
  } else if (response && (response.status === 403 || response.status === 401)) {
    yield put(actions.userLogout(action.token, action.server));
  } else {
    yield put(actions.searchViubitHistoryFail(response));
  }
}


//====================

export function* getViuBitEventSummarySaga(action) {
  yield put(actions.getViuBitEventSummaryStart());
  const headers = {
    "X-Auth-Token": action.token,
    "Content-type": "application/json"
  };
  const response = yield GET(
    `${action.server}api/monetizing/viubitTransaction/event/${action.eventId}/summary`,
    headers
  );
  if (response && response === 503) {
    yield put(actions.serverErrorSuccess(response));
  } else if (response && response.status === 200) {
    yield put(actions.getViuBitEventSummarySuccess(response.data));
  } else if ((response && response.status === 403) || response.status === 401) {
    yield put(actions.userLogout(action.token, action.server));
  } else {
    yield put(actions.getViuBitEventSummaryFail(response));
  }
}
