/* eslint-disable no-undef */
import { put } from 'redux-saga/effects'
import * as actions from '../actions/index'
import { GET, PUT, DELETE } from '../callApiWrapper'

export function * getMyProfileSaga(action) {
  yield put(actions.getMyProfileStart())
  const headers = {
    'X-Auth-Token': action.token,
    'Content-type': 'application/json'
  }
  const response = yield GET(
    `${action.server}api/user/profile/get/${action.id}`,
    headers
  )
  if (response && response === 503) {
    yield put(actions.serverErrorSuccess(response))
  } else if (response && response.status === 200) {
    yield put(actions.getMyProfileSuccess(response.data))
  } else if (response && (response.status === 403 || response.status === 401)) {
    yield put(actions.userLogout(action.token, action.server))
  } else {
    yield put(actions.getMyProfileFail(response))
  }
}


export function * deleteMyProfileSaga(action) {
  yield put(actions.deleteMyProfileStart())
  const headers = {
    'X-Auth-Token': action.token,
    'Content-type': 'application/json'
  }
  const response = yield DELETE(
    `${action.server}api/user/deactivate`,
    headers
  )
  if (response && response === 503) {
    yield put(actions.serverErrorSuccess(response))
  } else if (response && response.status === 200) {
    yield put(actions.deleteMyProfileSuccess(response.data))
  } else if (response && (response.status === 403 || response.status === 401)) {
    yield put(actions.userLogout(action.token, action.server))
  } else {
    yield put(actions.deleteMyProfileFail(response))
  }
}


// ===================== EDIT
export function * editMyProfileSaga(action) {
  yield put(actions.editMyProfileStart())
  let response
  if (action.data.identity.avatar.encoding === 'BASE64') {
    const ddb = `--${action.data.identity.avatar.key}`
    const ending = `${ddb}--`
    let body
    let avatarFile
    let coverFile
    let gearsFile

    const jsonData =
      `${ddb}\n` +
      `Content-Disposition: form-data; name="data"\n` +
      `Content-Type: application/json\n\n` +
      `${JSON.stringify(action.data)}\n`
    if (action.file.avatar) {
      avatarFile =
        `${ddb}\n` +
        `Content-Disposition: form-data; name="file"; filename="userAvatar"\n` +
        `Content-Type: ${action.file.avatar.mime}\n\n` +
        `${action.file.avatar.data}\n`
    }
    if (action.file.cover) {
      coverFile =
        `${ddb}\n` +
        `Content-Disposition: form-data; name="cover"; filename="cover"\n` +
        `Content-Type: ${action.file.cover.mime}\n\n` +
        `${action.file.cover.data}\n`
    }
    if (action.file.gears) {
      gearsFile =
        `${ddb}\n` +
        `Content-Disposition: form-data; name="gears"; filename="gears"\n` +
        `Content-Type: ${action.file.gears.mime}\n\n` +
        `${action.file.gears.data}\n`
    }
    body = jsonData
    if (avatarFile) {
      body = body + avatarFile + ending
    }
    if (coverFile) {
      body = body + coverFile + ending
    }
    if (gearsFile) {
      body = body + gearsFile + ending
    }
    if (!avatarFile && !coverFile && !gearsFile) {
      body = body + ending
    }
    response = yield fetch(`${action.server}api/user/profile`, {
      method: 'PUT',
      headers: {
        'Content-type': `multipart/form-data; boundary=${action.data.identity.avatar.key}`,
        'X-Auth-Token': action.token
      },
      body
    })
      .then(res => {
        console.log('res', res)

        return res.json()
      })
      .then(json => {
        const newResponse = {
          data: json,
          status: json.statusCode ? json.statusCode : 200
        }
        return newResponse
      })
      .catch(err => {
        console.log('err', err)
        return err
      })
  } else {
    const userData = new FormData()
    userData.append(
      'data',
      new Blob([JSON.stringify(action.data)], { type: 'application/json' })
    )
    if (action.file.avatar) {
      userData.append('file', action.file.avatar, 'userAvatar')
    }
    if (action.file.cover) {
      userData.append('cover', action.file.cover, 'cover')
    }
    if (action.file.gears) {
      userData.append('gears', action.file.gears, 'gears')
    }
    const headers = {
      'X-Auth-Token': action.token
    }
    response = yield PUT(`${action.server}api/user/profile`, userData, headers)
  }
  if (response && response === 503) {
    yield put(actions.serverErrorSuccess(response))
  } else if (response && response.status === 200) {
    yield put(actions.editMyProfileSuccess(response))
  } else if (response && (response.status === 403 || response.status === 401)) {
    yield put(actions.userLogout(action.token, action.server))
    yield put(actions.editMyProfileFail(response))
  } else {
    yield put(actions.editMyProfileFail(response))
  }
}
