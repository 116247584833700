import React, { Component } from "react";
import { Button, Icon, Modal, Slider } from "antd";
import AvatarEditor from "react-avatar-editor";
import addCameraIcon from "../../../svg/add-profile-image.svg";
import "./ImageUpload.css";

export class ImageUpload extends Component {
  state = {
    defaultValue: false,
    file: null,
    visible: false,
    cropConfig: this.props.cropConfig,
    pic: null
  };
  componentDidMount() {
    if (this.props.defaultValue) {
      this.setState({
        file: this.props.defaultValue.url,
        cropConfig: { ...this.state.cropConfig, scale: 1.2 }
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.defaultValue &&
      this.props.defaultValue &&
      prevProps.defaultValue.url !== this.props.defaultValue.url
    ) {
      this.setState({
        file: this.props.defaultValue.url
      });
    }
  }
  handleChange = event => {
    const file = event.target.files[0];
    var pattern = /image-*/;

    if (event.target.files[0]) {
      if (!file.type.match(pattern)) {
        Modal.error({
          title: "only .png , .jpeg and .gif is supported."
        });
        return false;
      } else {
        this.setState({
          file: URL.createObjectURL(event.target.files[0]),
          visible: true,
          pic: event.target.files[0]
        });
      }
    }
  };
  handleRotate = () => {
    const newState = {
      ...this.state.cropConfig,
      rotate:
        this.state.cropConfig.rotate === 270
          ? 0
          : this.state.cropConfig.rotate + 90
    };
    this.setState({
      cropConfig: newState
    });
  };
  handleZoom = e => {
    const newState = {
      ...this.state.cropConfig,
      scale: e
    };
    this.setState({
      cropConfig: newState
    });
  };
  handleRemove = () => {
    this.setState({
      file: this.props.editMode ? null : this.props.defaultValue.url,
      visible: false,
      pic: null,
      cropConfig: { ...this.state.cropConfig, rotate: 0, scale: 1.2 }
    });
    this.props.value(null);

    if (this.props.removeIcon) {
      this.props.removeIcon(true);
    }
  };
  handleOk = () => {
    if (this.editor) {
      const img = this.editor.getImageScaledToCanvas();
      img.toBlob(blob => {
        if (blob) {
          this.setState({
            file: URL.createObjectURL(blob),
            visible: false,
            cropConfig: { ...this.state.cropConfig, rotate: 0 }
          });
          this.props.value(blob, this.state.file);
        }
      });
    }
    if (this.props.getPic) {
      this.props.getPic(true);
    }
  };

  setEditorRef = editor => (this.editor = editor);
  render() {
    return (
      <React.Fragment>
        {(this.props.showCross ||
          (this.props.editMode &&
            this.props.defaultValue &&
            this.props.defaultValue.url &&
            !this.props.removeCross)) && (
          <span className="crossIcon" onClick={() => this.handleRemove()}>
            <i
              className="icon-camera-delete"
              style={{
                background: "#eee",
                borderRadius: "50%",
                padding: "5px",
                color: "red"
              }}
            />
          </span>
        )}
        <div
          className={`ImageUpload ${
            this.props.className ? this.props.className : ""
          }`}
        >
          <label
            htmlFor={this.props.id}
            className={`${
              this.state.file ? "hasFile" : ""
            } customFileUpload textCenter`}
          >
            {this.state.file ? (
              <img src={this.state.file} />
            ) : (
              this.props.uploadBtn
            )}

            <span className="addCameraIcon">
              <img
                src={addCameraIcon}
                alt="addprofileimage"
                className="addIconImage"
              />
            </span>
          </label>
          <input
            type="file"
            accept="image/x-png,image/gif,image/jpeg,image/png"
            id={this.props.id}
            onChange={event => {
              this.handleChange(event);
              event.target.value = null;
            }}
          />
        </div>

        <Modal
          title="Crop Image"
          visible={this.state.visible}
          footer={false}
          onCancel={() => this.handleRemove()}
          className={`textCenter imageCropModal ${this.props.modalClassName}`}
        >
          <div className={`MSD-Crop ${this.props.cropClassName}`}>
            <div className="cropConfig">
              <Button onClick={this.handleRotate}>
                <Icon type="reload" />
              </Button>
              <span>
                <Slider
                  vertical
                  value={this.state.cropConfig.scale}
                  min={1}
                  max={2}
                  step={0.01}
                  style={{ display: "inline-block" }}
                  onChange={e => this.handleZoom(e)}
                  tooltipVisible={false}
                />
              </span>
            </div>
            {this.state.cropConfig ? (
              <AvatarEditor
                ref={this.setEditorRef}
                image={this.state.pic}
                width={this.state.cropConfig.width}
                height={this.state.cropConfig.height}
                border={this.state.cropConfig.border}
                color={[0, 0, 0, 0.6]}
                scale={this.state.cropConfig.scale}
                rotate={this.state.cropConfig.rotate}
                style={{ objectFit: "contain" }}
              />
            ) : null}

            <div className="textCenter cropButtons">
              <Button onClick={() => this.handleRemove()}>Cancel</Button>
              <Button onClick={() => this.handleOk()} type="primary">
                Ok
              </Button>
            </div>
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}
