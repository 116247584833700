import * as actionTypes from './actionTypes'


export const addStreams = (token,data,server) => {
    return {
      type: actionTypes.ADD_STREAMS,
      data: data,
      server:server,
      token:token
    };
  };
  export const addStreamsStart = () => {
    return {
      type: actionTypes.ADD_STREAMS_START,
    };
  };
  export const addStreamsSuccess = streams => {
    return {
      type: actionTypes.ADD_STREAMS_SUCCESS,
      streams: streams,
    };
  };
  
  export const addStreamsFail = error => {
    return {
      type: actionTypes.ADD_STREAMS_FAIL,
      error: error
    };
  };

//   ============================================================= EDIT STREAM

export const updateStream = (token,object,server) => {
    return {
      type: actionTypes.UPDATE_STREAM,
      object: object,
      server:server,
      token:token
    };
  };
  export const updateStreamStart = () => {
    return {
      type: actionTypes.UPDATE_STREAM_START,
    };
  };
  export const updateStreamSuccess = stream => {
    return {
      type: actionTypes.UPDATE_STREAM_SUCCESS,
      stream: stream,
    };
  };
  
  export const updateStreamFail = error => {
    return {
      type: actionTypes.UPDATE_STREAM_FAIL,
      error: error
    };
  };

  //   ============================================================= DELETE STREAM

  export const deleteStream = (token,eventId,streamId,server) => {
    return {
      type: actionTypes.DELETE_STREAM,
      eventId: eventId,
      streamId:streamId,
      server:server,
      token:token
    };
  };
  export const deleteStreamStart = () => {
    return {
      type: actionTypes.DELETE_STREAM_START,
    };
  };
  export const deleteStreamSuccess = stream => {
    return {
      type: actionTypes.DELETE_STREAM_SUCCESS,
      stream: stream,
    };
  };
  
  export const deleteStreamFail = error => {
    return {
      type: actionTypes.DELETE_STREAM_FAIL,
      error: error
    };
  };