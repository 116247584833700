import { put } from "redux-saga/effects";
import * as actions from "../actions/index";
import { POST, DELETE } from "../callApiWrapper";

export function* addDisplayMixSaga(action) {
  yield put(actions.addDisplayMixStart());
  const headers = {
    "X-Auth-Token": action.token,
    "Content-type": "application/json"
  };
  const response = yield POST(
    `${action.server}api/vume/eventDisplayMixer`,
    action.object,
    headers
  );

  if (response && response === 503) {
    yield put(actions.serverErrorSuccess(response));
  } else if (response && response === 451) {
    yield put(actions.permissionErrorSuccess(Math.random()));
  } else if (response && response.status === 200) {
    yield put(actions.addDisplayMixSuccess(response.data));
  } else if (response && (response.status === 403 || response.status === 401)) {
    yield put(actions.userLogout(action.token, action.server));
  } else {
    yield put(actions.addDisplayMixFail(response));
  }
}

// ======================================= SearchDisplaymix

export function* searchDisplayMixSaga(action) {
  yield put(actions.searchDisplayMixStart());
  const headers = {
    "X-Auth-Token": action.token,
    "Content-type": "application/json"
  };
  const data = {
    pageModel: {
      orders: [
        {
          dir: action.object.order,
          property: action.object.property ? action.object.property : "id"
        }
      ],
      pageNumber: action.object.pageNumber,
      pageSize: action.object.pageSize
    },
    searchModel: {
      eventId: action.object.eventId,
      userId: action.object.userId ? action.object.userId : null,
      timeStart: action.object.timeStart ? action.object.timeStart : null,
      timeEnd: action.object.timeEnd ? action.object.timeEnd : null
    }
  };

  const response = yield POST(
    `${action.server}api/vume/eventDisplayMixer/search`,
    data,
    headers
  );
  if (response && response === 503) {
    yield put(actions.serverErrorSuccess(response));
  } else if (response && response.status === 200) {
    yield put(actions.searchDisplayMixSuccess(response.data));
  } else if (response && (response.status === 403 || response.status === 401)) {
    yield put(actions.userLogout(action.token, action.server));
  } else {
    yield put(actions.searchDisplayMixFail(response));
  }
}

// =================================

export function* deleteDisplayMixSaga(action) {
  yield put(actions.deleteDisplayMixStart());
  const headers = {
    "X-Auth-Token": action.token,
    "Content-type": "application/json"
  };
  const response = yield DELETE(
    `${action.server}api/vume/eventDisplayMixer/${action.id}`,
    headers
  );

  if (response && response === 503) {
    yield put(actions.serverErrorSuccess(response));
  } else if (response === 451) {
    yield put(actions.permissionErrorSuccess(Math.random()));
  } else if (response && response.status === 200) {
    const res = {
      status: 200,
      id: action.id
    };
    yield put(actions.deleteDisplayMixSuccess(res));
  } else if (response && (response.status === 403 || response.status === 401)) {
    yield put(actions.userLogout(action.token, action.server));
  } else {
    yield put(actions.deleteDisplayMixFail(response));
  }
}
