export const isEmpty = value => {
  if (value === "" || value === null) {
    return true;
  } else {
    return false;
  }
};

export const isEmail = value => {
  const emailRegex = /^\s*(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))\s*$/;
  const regexCheck = emailRegex.test(String(value).toLowerCase());
  if (regexCheck) {
    return true;
  } else {
    return false;
  }
};

export const isMinimumLength = (value, length) => {
  if (value.length > length) {
    return true;
  } else {
    return false;
  }
};

export const isMaximumLength = (value, length) => {
  if (value.length <= length) {
    return false;
  } else {
    return true;
  }
};

export const isBetweenLength = (value, min, max) => {
  if (value.length >= min && value.length <= max) {
    return true;
  } else {
    return false;
  }
};

export const isPasswordMatched = (password, confirmation) => {
  if (password === confirmation) {
    return true;
  } else {
    return false;
  }
};

export const isChecked = check => {
  if (check) {
    return true;
  } else {
    return false;
  }
};
export const isSamePassword = (oldPassword, newPassword) => {
  if (oldPassword === newPassword) {
    return true;
  } else {
    return false;
  }
};
