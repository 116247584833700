import React from 'react'

export const AvatarPic = (props) =>{
    return(
        <React.Fragment>
            <div className={props.className}>
                <figure>
                    <img src={props.src} alt={(props.alt) ? props.alt : "avatar"}    onError={props.onError}/>
                </figure>
                {
                    (props.username) ? 
                    <p className={props.usernameClassName}>
                        {props.username}
                    </p> : null
                }
            </div>
        </React.Fragment>
    )
}