import React from 'react'
import ReactResponsiveSelect from 'react-responsive-select';
import "react-responsive-select/dist/ReactResponsiveSelect.css";

import './Select.css'
 
export const MobileSelect = (props) =>{
    let options = [];

    if(props.options){
     Object.keys(props.options).map( key => {
                return options.push({
                        text: props.options[key][props.titleField],
                        value: props.options[key][props.idField]
                    })
                 
            } )
        
    }
    const onChange = (newValue) => {
         props.onChange(newValue.value)
    }
    return(

            <ReactResponsiveSelect
            className="mobileSelector"
                options={options}
                selectedValue={props.defaultValue}
                onChange={onChange}
            
            />
)
}

