import * as actionTypes from './actionTypes'

// ================================================================================= GET EVENTS
export const getEvents = (token, searchModel, server) => {
  return {
    type: actionTypes.GET_EVENTS,
    searchModel: searchModel,
    server: server,
    token: token
  }
}

export const getEventsStart = () => {
  return {
    type: actionTypes.GET_EVENTS_START
  }
}

export const getEventsCancel = () => {
  return {
    type: actionTypes.GET_EVENTS_CANCEL
  }
}
export const getEventsClear = () => {
  return {
    type: actionTypes.GET_EVENTS_CLEAR
  }
}
export const getEventsSuccess = events => {
  return {
    type: actionTypes.GET_EVENTS_SUCCESS,
    events: events
  }
}

export const getEventsFail = error => {
  return {
    type: actionTypes.GET_EVENTS_FAIL,
    error: error
  }
}

// ================================================================================= GET EVENTS
export const userLastEvents = (token, searchModel, server) => {
  return {
    type: actionTypes.USER_LAST_EVENTS,
    searchModel: searchModel,
    server: server,
    token: token
  }
}

export const userLastEventsStart = () => {
  return {
    type: actionTypes.USER_LAST_EVENTS_START
  }
}

export const userLastEventsSuccess = events => {
  return {
    type: actionTypes.USER_LAST_EVENTS_SUCCESS,
    events: events
  }
}

export const userLastEventsFail = error => {
  return {
    type: actionTypes.USER_LAST_EVENTS_FAIL,
    error: error
  }
}
// ================================================================================= SEARCH EVENTS
export const searchEvents = (token, searchModel, server) => {
  return {
    type: actionTypes.SEARCH_EVENTS,
    searchModel: searchModel,
    server: server,
    token: token
  }
}

export const searchEventsStart = () => {
  return {
    type: actionTypes.SEARCH_EVENTS_START
  }
}

export const searchEventsSuccess = searchedEvents => {
  return {
    type: actionTypes.SEARCH_EVENTS_SUCCESS,
    searchedEvents: searchedEvents
  }
}

export const searchEventsFail = error => {
  return {
    type: actionTypes.SEARCH_EVENTS_FAIL,
    error: error
  }
}

// =========================================SEARCH TOP EVENTS
export const searchTopEvents = (token, searchModel, server) => {
  return {
    type: actionTypes.SEARCH_TOP_EVENTS,
    searchModel: searchModel,
    server: server,
    token: token
  }
}

export const searchTopEventsStart = () => {
  return {
    type: actionTypes.SEARCH_TOP_EVENTS_START
  }
}

export const searchTopEventsSuccess = topEvents => {
  return {
    type: actionTypes.SEARCH_TOP_EVENTS_SUCCESS,
    topEvents: topEvents
  }
}

export const searchTopEventsFail = error => {
  return {
    type: actionTypes.SEARCH_TOP_EVENTS_FAIL,
    error: error
  }
}

// ================================================================================= SEARCH EVENTS
export const userProfileEvents = (token, searchModel, server) => {
  return {
    type: actionTypes.USER_PROFILE_EVENTS,
    searchModel: searchModel,
    server: server,
    token: token
  }
}

export const userProfileEventsStart = () => {
  return {
    type: actionTypes.USER_PROFILE_EVENTS_START
  }
}
export const userProfileEventsCancel = () => {
  return {
    type: actionTypes.GET_USER_EVENTS_CANCEL
  }
}

export const userProfileEventsSuccess = userProfileEvents => {
  return {
    type: actionTypes.USER_PROFILE_EVENTS_SUCCESS,
    userProfileEvents: userProfileEvents
  }
}

export const userProfileEventsFail = error => {
  return {
    type: actionTypes.USER_PROFILE_EVENTS_FAIL,
    error: error
  }
}
//   ================================================================================= GET EVENTS BY ID

export const getEventById = (token, id, server) => {
  return {
    type: actionTypes.GET_EVENT_BY_ID,
    id: id,
    server: server,
    token: token
  }
}

export const getEventByIdStart = () => {
  return {
    type: actionTypes.GET_EVENT_BY_ID_START
  }
}

export const getEventByIdSuccess = event => {
  return {
    type: actionTypes.GET_EVENT_BY_ID_SUCCESS,
    event: event
  }
}

export const getEventByIdFail = error => {
  return {
    type: actionTypes.GET_EVENT_BY_ID_FAIL,
    error: error
  }
}

//   ================================================================================= CHECK EVENTS

export const checkEventById = (token, id, server) => {
  return {
    type: actionTypes.CHECK_EVENT_BY_ID,
    id: id,
    server: server,
    token: token
  }
}

export const checkEventByIdStart = () => {
  return {
    type: actionTypes.CHECK_EVENT_BY_ID_START
  }
}

export const checkEventByIdSuccess = event => {
  return {
    type: actionTypes.CHECK_EVENT_BY_ID_SUCCESS,
    event: event
  }
}

export const checkEventByIdFail = error => {
  return {
    type: actionTypes.CHECK_EVENT_BY_ID_FAIL,
    error: error
  }
}

//   ================================================================================= GET MY BROADCAST

export const getMyBroadcast = (token, searchModel, server) => {
  return {
    type: actionTypes.GET_MY_BROADCAST,
    searchModel: searchModel,
    server: server,
    token: token
  }
}

export const getMyBroadcastStart = () => {
  return {
    type: actionTypes.GET_MY_BROADCAST_START
  }
}

export const getMyBroadcastSuccess = myBroadcast => {
  return {
    type: actionTypes.GET_MY_BROADCAST_SUCCESS,
    myBroadcast: myBroadcast
  }
}

export const getMyBroadcastFail = error => {
  return {
    type: actionTypes.GET_MY_BROADCAST_FAIL,
    error: error
  }
}

// ====================================================================================== CREATE EVENT

export const createEvent = (token, data, file, server) => {
  return {
    type: actionTypes.CREATE_EVENT,
    data: data,
    file: file,
    server: server,
    token: token
  }
}

export const createEventStart = () => {
  return {
    type: actionTypes.CREATE_EVENT_START
  }
}

export const createEventSuccess = event => {
  return {
    type: actionTypes.CREATE_EVENT_SUCCESS,
    event: event
  }
}

export const createEventFail = error => {
  return {
    type: actionTypes.CREATE_EVENT_FAIL,
    error: error
  }
}

// ====================================================================================== CREATE AND START EVENT

export const createAndStartEvent = (token, data, file, server) => {
  return {
    type: actionTypes.CREATE_AND_START_EVENT,
    data: data,
    file: file,
    server: server,
    token: token
  }
}

export const createAndStartEventStart = () => {
  return {
    type: actionTypes.CREATE_AND_START_EVENT_START
  }
}

export const createAndStartEventSuccess = event => {
  return {
    type: actionTypes.CREATE_AND_START_EVENT_SUCCESS,
    event: event
  }
}

export const createAndStartEventFail = error => {
  return {
    type: actionTypes.CREATE_AND_START_EVENT_FAIL,
    error: error
  }
}

// ====================================================================================== CREATE AND PREVIEW EVENT


export const createAndPreviewEvent = (token, data, file, server) => {
  return {
    type: actionTypes.CREATE_AND_PREVIEW_EVENT,
    data: data,
    file: file,
    server: server,
    token: token
  };
}

export const createAndPreviewEventStart = () => {
  return {
    type: actionTypes.CREATE_AND_PREVIEW_EVENT_START
  }
}

export const createAndPreviewEventSuccess = event => {
  return {
    type: actionTypes.CREATE_AND_PREVIEW_EVENT_SUCCESS,
    event: event
  }
}

export const createAndPreviewEventFail = error => {
  return {
    type: actionTypes.CREATE_AND_PREVIEW_EVENT_FAIL,
    error: error
  }
}

// ====================================================================================== DELETE EVENT
export const deleteEvent = (token, id, server) => {
  return {
    type: actionTypes.DELETE_EVENTS,
    id: id,
    server: server,
    token: token
  }
}

export const deleteEventStart = () => {
  return {
    type: actionTypes.DELETE_EVENTS_START
  }
}

export const deleteEventSuccess = response => {
  return {
    type: actionTypes.DELETE_EVENTS_SUCCESS,
    response: response
  }
}

export const deleteEventFail = error => {
  return {
    type: actionTypes.DELETE_EVENTS_FAIL,
    error: error
  }
}

// ====================================================================================== EDIT EVENT

export const editEvent = (token, data, file, server) => {
  return {
    type: actionTypes.EDIT_EVENT,
    data: data,
    file: file,
    server: server,
    token: token
  }
}

export const editEventStart = () => {
  return {
    type: actionTypes.EDIT_EVENT_START
  }
}

export const editEventSuccess = event => {
  return {
    type: actionTypes.EDIT_EVENT_SUCCESS,
    event: event
  }
}

export const editEventFail = error => {
  return {
    type: actionTypes.EDIT_EVENT_FAIL,
    error: error
  }
}

// ====================================================================================== EDIT EVENT INFO

export const editEventInfo = (token, data, server) => {
  return {
    type: actionTypes.EDIT_EVENT_INFO,
    data: data,
    server: server,
    token: token
  }
}

export const editEventInfoStart = () => {
  return {
    type: actionTypes.EDIT_EVENT_INFO_START
  }
}

export const editEventInfoSuccess = eventInfo => {
  return {
    type: actionTypes.EDIT_EVENT_INFO_SUCCESS,
    eventInfo: eventInfo
  }
}

export const editEventInfoFail = error => {
  return {
    type: actionTypes.EDIT_EVENT_INFO_FAIL,
    error: error
  }
}

// ====================================================================================== CHANGE STATE

export const eventChangeState = (token, id, state, server) => {
  return {
    type: actionTypes.EVENT_CHANGE_STATE,
    id: id,
    state: state,
    server: server,
    token: token
  }
}
export const eventChangeStateStart = () => {
  return {
    type: actionTypes.EVENT_CHANGE_STATE_START
  }
}
export const eventChangeStateSuccess = eventStatus => {
  return {
    type: actionTypes.EVENT_CHANGE_STATE_SUCCESS,
    eventStatus: eventStatus
  }
}

export const eventChangeStateFail = error => {
  return {
    type: actionTypes.EVENT_CHANGE_STATE_FAIL,
    error: error
  }
}

// ====================================================================================== LEAVE EVENT
export const leaveEvent = (token, id, server) => {
  return {
    type: actionTypes.LEAVE_EVENT,
    id: id,
    server: server,
    token: token
  }
}

export const leaveEventStart = () => {
  return {
    type: actionTypes.LEAVE_EVENT_START
  }
}

export const leaveEventSuccess = response => {
  return {
    type: actionTypes.LEAVE_EVENT_SUCCESS,
    response: response
  }
}

export const leaveEventFail = error => {
  return {
    type: actionTypes.LEAVE_EVENT_FAIL,
    error: error
  }
}

// ====================================================================================== JOIN ON EVENT

export const joinOnEvent = (token, joinCode, server) => {
  return {
    type: actionTypes.JOIN_ON_EVENT,
    joinCode: joinCode,
    server: server,
    token: token
  }
}
export const joinOnEventStart = () => {
  return {
    type: actionTypes.JOIN_ON_EVENT_START
  }
}
export const joinOnEventSuccess = event => {
  return {
    type: actionTypes.JOIN_ON_EVENT_SUCCESS,
    event: event
  }
}

export const joinOnEventFail = error => {
  return {
    type: actionTypes.JOIN_ON_EVENT_FAIL,
    error: error
  }
}

// ======================================================================================BIND EVENT STREAM

export const bindEventStream = (token, object, server) => {
  return {
    type: actionTypes.BIND_EVENT_STREAM,
    object: object,
    server: server,
    token: token
  }
}
export const bindEventStreamStart = () => {
  return {
    type: actionTypes.BIND_EVENT_STREAM_START
  }
}
export const bindEventStreamSuccess = bindedResponse => {
  return {
    type: actionTypes.BIND_EVENT_STREAM_SUCCESS,
    bindedResponse: bindedResponse
  }
}

export const bindEventStreamFail = error => {
  return {
    type: actionTypes.BIND_EVENT_STREAM_FAIL,
    error: error
  }
}

// ======================================= CATEGORIES

export const getAllCategories = server => {
  return {
    type: actionTypes.GET_ALL_CATEGORIES,
    server: server
  }
}

export const getAllCategoriesStart = () => {
  return {
    type: actionTypes.GET_ALL_CATEGORIES_START
  }
}

export const getAllCategoriesSuccess = categories => {
  return {
    type: actionTypes.GET_ALL_CATEGORIES_SUCCESS,
    categories: categories
  }
}

export const getAllCategoriesFail = error => {
  return {
    type: actionTypes.GET_ALL_CATEGORIES_FAIL,
    error: error
  }
}

// ======================================= CATEGORIES

export const latestEventsCategory = (token, server) => {
  return {
    type: actionTypes.LATEST_EVENTS_CATEGORY,
    token: token,
    server: server
  }
}

export const latestEventsCategoryStart = () => {
  return {
    type: actionTypes.LATEST_EVENTS_CATEGORY_START
  }
}

export const latestEventsCategorySuccess = latestEvents => {
  return {
    type: actionTypes.LATEST_EVENTS_CATEGORY_SUCCESS,
    latestEvents: latestEvents
  }
}

export const latestEventsCategoryFail = error => {
  return {
    type: actionTypes.LATEST_EVENTS_CATEGORY_FAIL,
    error: error
  }
}

// ======================================= INVITATION

export const addOwnerInvitation = (token, data, server) => {
  return {
    type: actionTypes.ADD_OWNER_INVITATION,
    token: token,
    data: data,
    server: server
  }
}

export const addOwnerInvitationStart = () => {
  return {
    type: actionTypes.ADD_OWNER_INVITATION_START
  }
}

export const addOwnerInvitationSuccess = ownerInvites => {
  return {
    type: actionTypes.ADD_OWNER_INVITATION_SUCCESS,
    ownerInvites: ownerInvites
  }
}

export const addOwnerInvitationFail = error => {
  return {
    type: actionTypes.ADD_OWNER_INVITATION_FAIL,
    error: error
  }
}

// =====
export const deleteOwnerInvitation = (token, data, server) => {
  return {
    type: actionTypes.DELETE_OWNER_INVITATION,
    token: token,
    data: data,
    server: server
  }
}

export const deleteOwnerInvitationStart = () => {
  return {
    type: actionTypes.DELETE_OWNER_INVITATION_START
  }
}

export const deleteOwnerInvitationSuccess = ownerInvites => {
  return {
    type: actionTypes.DELETE_OWNER_INVITATION_SUCCESS,
    ownerInvites: ownerInvites
  }
}

export const deleteOwnerInvitationFail = error => {
  return {
    type: actionTypes.DELETE_OWNER_INVITATION_FAIL,
    error: error
  }
}


// ====================================================================================== update hide EVENT state

export const  toggleHideEvent = function toggleHideEvent(token, id, hidden, server) {
  return {
    type: actionTypes.TOGGLE_HIDE_EVENT,
    id: id,
    hidden: hidden,
    server: server,
    token: token,
  };
};

export const  toggleHideEventStart = function toggleHideEventStart() {
  return {
    type: actionTypes.TOGGLE_HIDE_EVENT_START,
  };
};

export const  toggleHideEventSuccess = function toggleHideEventSuccess(response) {
  return {
    type: actionTypes.TOGGLE_HIDE_EVENT_SUCCESS,
    response: response,
  };
};

export const  toggleHideEventFail = function toggleHideEventFail(error) {
  return {
    type: actionTypes.TOGGLE_HIDE_EVENT_FAIL,
    error: error,
  };
};
