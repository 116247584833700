import * as actionTypes from "./actionTypes";

export const getUsers = (token, searchModel, server) => {
  return {
    type: actionTypes.GET_ALL_USER,
    token: token,
    searchModel: searchModel,
    server: server
  };
};

export const getUsersStart = loadMore => {
  return {
    type: actionTypes.GET_ALL_USER_START
  };
};

export const getUsersSuccess = users => {
  return {
    type: actionTypes.GET_ALL_USER_SUCCESS,
    users: users
  };
};

export const getUsersFail = error => {
  return {
    type: actionTypes.GET_ALL_USER_FAIL,
    error: error
  };
};

// ============================================== USER BY ID

export const getUserById = (token, id, server) => {
  return {
    type: actionTypes.GET_USER_BY_ID,
    token: token,
    id: id,
    server: server
  };
};

export const getUserByIdStart = () => {
  return {
    type: actionTypes.GET_USER_BY_ID_START
  };
};

export const getUserByIdSuccess = user => {
  return {
    type: actionTypes.GET_USER_BY_ID_SUCCESS,
    user: user
  };
};
export const getUserByIdFail = error => {
  return {
    type: actionTypes.GET_USER_BY_ID_FAIL,
    error: error
  };
};

// ============================================== ADD USER

export const addUser = (token, data, file, server) => {
  return {
    type: actionTypes.ADD_USER,
    data: data,
    file: file,
    server: server,
    token: token
  };
};

export const addUserStart = () => {
  return {
    type: actionTypes.ADD_USER_START
  };
};

export const addUserSuccess = response => {
  return {
    type: actionTypes.ADD_USER_SUCCESS,
    response: response
  };
};
export const addUserFail = error => {
  return {
    type: actionTypes.ADD_USER_FAIL,
    error: error
  };
};

// ============================================== DELETE USER

export const deleteUser = (token, id, server) => {
  return {
    type: actionTypes.DELETE_USER,
    id: id,
    server: server,
    token: token
  };
};

export const deleteUserStart = () => {
  return {
    type: actionTypes.DELETE_USER_START
  };
};

export const deleteUserSuccess = response => {
  return {
    type: actionTypes.DELETE_USER_SUCCESS,
    response: response
  };
};
export const deleteUserFail = error => {
  return {
    type: actionTypes.DELETE_USER_FAIL,
    error: error
  };
};

// ============================================== EDIT USER

export const editUser = (token, data, file, server) => {
  return {
    type: actionTypes.EDIT_USER,
    data: data,
    file: file,
    server: server,
    token: token
  };
};
export const editUserStart = () => {
  return {
    type: actionTypes.EDIT_USER_START
  };
};

export const editUserSuccess = response => {
  return {
    type: actionTypes.EDIT_USER_SUCCESS,
    response: response
  };
};

export const editUserFail = error => {
  return {
    type: actionTypes.EDIT_USER_FAIL,
    error: error
  };
};

// ============================= SEARCH USERS

export const searchUsers = (token, searchModel, server) => {
  return {
    type: actionTypes.SEARCH_USERS,
    searchModel: searchModel,
    server: server,
    token: token
  };
};

export const searchUsersStart = () => {
  return {
    type: actionTypes.SEARCH_USERS_START
  };
};

export const searchUsersSuccess = users => {
  return {
    type: actionTypes.SEARCH_USERS_SUCCESS,
    users: users
  };
};

export const searchUsersFail = error => {
  return {
    type: actionTypes.SEARCH_USERS_FAIL,
    error: error
  };
};
