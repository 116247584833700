import { put } from "redux-saga/effects";
import * as actions from "../actions/index";
import { POST, PUT } from "../callApiWrapper";

export function* reportEventSaga(action) {
  yield put(actions.reportEventStart());
  const headers = {
    "X-Auth-Token": action.token,
    "content-type": "application/json"
  };
  const response = yield POST(
    `${action.server}api/vume/eventReport`,
    action.object,
    headers
  );
  if (response && response === 503) {
    yield put(actions.serverErrorSuccess(response));
  } else if (response && response === 451) {
    yield put(actions.permissionErrorSuccess(Math.random()));
  } else if (response && response.status === 200) {
    yield put(actions.reportEventSuccess(response.data));
  } else if (response && (response.status === 403 || response.status === 401)) {
    yield put(actions.userLogout(action.token, action.server));
  } else {
    yield put(actions.reportEventFail(response.data));
  }
}

// =========
export function* getReportEventsSaga(action) {
  yield put(actions.getReportEventsStart(action.loadMore));
  let data = {
    pageModel: {
      orders: [
        {
          dir: action.searchModel.order,
          property: action.searchModel.property
            ? action.searchModel.property
            : "id"
        }
      ],
      pageNumber: action.searchModel.pageNumber,
      pageSize: action.searchModel.pageSize
    },
    searchModel: {
      eventsIds: []
    }
  };
  if (action.searchModel.tags) {
    const newObject = {
      ...data.searchModel,
      tags: action.searchModel.tags
    };
    data = {
      ...data,
      searchModel: newObject
    };
  }

  const headers = {
    "content-type": "application/json",
    "X-Auth-Token": action.token
  };

  const response = yield POST(
    `${action.server}api/vume/eventReport/search`,
    data,
    headers
  );
  if (response && response === 503) {
    yield put(actions.serverErrorSuccess(response));
  }
  if (response && response.status === 200) {
    let responseData = response.data;

    if (action.loadMore) {
      yield put(actions.getReportEventsSuccess(responseData));
    } else {
      yield put(actions.getReportEventsSuccess(response.data));
    }
  } else if (response && (response.status === 403 || response.status === 401)) {
    yield put(actions.userLogout(action.token, action.server));
  } else {
    yield put(actions.getReportEventsFail(response));
  }
}

// ========= COMMENTS
export function* reportCommentSaga(action) {
  yield put(actions.reportCommentStart());
  const headers = {
    "X-Auth-Token": action.token,
    "content-type": "application/json"
  };
  const response = yield POST(
    `${action.server}api/vume/eventCommentReport`,
    action.object,
    headers
  );
  if (response === 503) {
    yield put(actions.serverErrorSuccess(response));
  } else if (response && response === 451) {
    yield put(actions.permissionErrorSuccess(Math.random()));
  } else if (response && response.status === 200) {
    yield put(actions.reportCommentSuccess(response.data));
  } else if (response && (response.status === 403 || response.status === 401)) {
    yield put(actions.userLogout(action.token, action.server));
  } else {
    yield put(actions.reportCommentFail(response.data));
  }
}

// =========

export function* getReportedCommentsSaga(action) {
  yield put(actions.getReportedCommentsStart());
  const headers = {
    "X-Auth-Token": action.token,
    "Content-type": "application/json"
  };
  const data = {
    pageModel: {
      orders: [
        {
          dir: action.object.order,
          property: "id"
        }
      ],
      pageNumber: action.object.pageNumber,
      pageSize: action.object.pageSize
    },
    searchModel: {
      eventIds: action.object.eventId ? action.object.eventId : null
    }
  };
  const response = yield POST(
    `${action.server}api/vume/eventCommentReport/search`,
    data,
    headers
  );
  if (response && response === 503) {
    yield put(actions.serverErrorSuccess(response));
  } else if (response && response.status === 200) {
    yield put(actions.getReportedCommentsSuccess(response.data));
  } else if (response && (response.status === 403 || response.status === 401)) {
    yield put(actions.userLogout(action.token, action.server));
  } else {
    yield put(actions.getReportedCommentsFail(response));
  }
}

// ========= SHOULD GO TO SEPERATE FILE

export function* blockEventSaga(action) {
  yield put(actions.blockEventStart());

  const response = yield PUT(
    `${action.server}api/vume/event/block/${action.id}`,
    null,
    { "Content-Type": "application/json", "X-Auth-Token": action.token }
  );
  if (response && response === 503) {
    yield put(actions.serverErrorSuccess(response));
  } else if (response && response.status === 200) {
    yield put(actions.blockEventSuccess(response.status));
  } else if (response && (response.status === 403 || response.status === 401)) {
    yield put(actions.userLogout(action.token, action.server));
  } else {
    yield put(actions.blockEventFail(response));
  }
}
