/* eslint-disable no-undef */
import * as actionTypes from './actionTypes'
import { POST } from "../callApiWrapper";

export const getMyNotification = (token, action, server) => {
  console.log('action', token, action, server)
  return {
    type: actionTypes.GET_MY_NOTIFICATIONS,
    action,
    token: token,
    server: server
  }
}

export const getMyNotificationStart = () => {
  return {
    type: actionTypes.GET_MY_NOTIFICATIONS_START
  }
}

export const getMyNotificationCancel = () => {
  return {
    type: actionTypes.GET_MY_NOTIFICATIONS_CLEAR
  }
}

export const getMyNotificationSuccess = user => {
  return {
    type: actionTypes.GET_MY_NOTIFICATIONS_SUCCESS,
    payload: user
  }
}
export const getMyNotificationFail = error => {
  return {
    type: actionTypes.GET_MY_NOTIFICATIONS_ERROR,
    payload: error
  }
}

export const markAllNotificationAsRead = (action) => {
  const headers = {
    'X-Auth-Token': action.token,
    'Content-type': 'application/json'
  }
  const response = POST(
    `${action.server}api/notifications/resetUnread`,
    {},
    headers
  )
}

export const markNotificationAsReadById = (action) => {
  const headers = {
    "X-Auth-Token": action.token,
    "Content-type": "application/json",
  };
  return POST(
    `${action.server}api/notifications/markRead/${action.notificationId}`,
    {},
    headers,
  );
}

export const markAllRead = (action) => {
  const headers = {
    'X-Auth-Token': action.token,
    'Content-type': 'application/json'
  }
  const response = POST(
    `${action.server}api/notifications/markAllRead`,
    {}, 
    headers
  )
  console.log(response)
};
