export const success = {
  changePassword: 'Password changed successfully!',
  editEvent: 'Event edited successfully!',
  joined: 'You have joined the event!',
  eventFinished: 'This event has ended.',
  updateProfile: 'User profile updated successfully',
  cacheCleared: 'Cache cleared successfully',
  userBlocked: 'User has been blocked!',
  mixerBroadcastSuccess: 'Your mix successfully broadcast to viewers'
}
