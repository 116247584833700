import * as actionTypes from './actionTypes'

// ================================================================================= GET LOCATIONS
export const getLocations = (server) => {
    return {
      type: actionTypes.GET_LOCATIONS,
      server:server
    };
  };

  export const getLocationsStart = () => {
    return {
      type: actionTypes.GET_LOCATIONS_START
    };
  };
  
  export const getLocationsSuccess = locations => {
    return {
      type: actionTypes.GET_LOCATIONS_SUCCESS,
      locations: locations
    };
  };
  
  export const getLocationsFail = error => {
    return {
      type: actionTypes.GET_LOCATIONS_FAIL,
      error: error
    };
  };
//   ================================================================================= GET LOCATIONS