import * as actionTypes from "../actions/actionTypes";

const initialState = {
  response: null,
  liveResponse: null,
  loading: false,
  error: null
};

const addTotalViewStart = state => {
  const newState = {
    ...state,
    loading: true,
    error: null,
    response: null
  };
  return newState;
};
const addTotalViewSuccess = (state, action) => {
  const newState = {
    ...state,
    loading: false,
    response: action.response,
    error: null
  };
  return newState;
};

const addTotalViewFail = (state, action) => {
  const newState = {
    ...state,
    response: null,
    loading: false,
    error: action.error
  };
  return newState;
};

// ===================== LIVE VIEW

const addLiveViewStart = state => {
  const newState = {
    ...state,
    loading: true,
    error: null,
    liveResponse: null
  };
  return newState;
};
const addLiveViewSuccess = (state, action) => {
  const newState = {
    ...state,
    loading: false,
    liveResponse: action.liveResponse,
    error: null
  };
  return newState;
};

const addLiveViewFail = (state, action) => {
  const newState = {
    ...state,
    liveResponse: null,
    loading: false,
    error: action.error
  };
  return newState;
};

// ============

const deleteLiveViewStart = state => {
  const newState = {
    ...state,
    loading: true,
    error: null,
    liveResponse: null
  };
  return newState;
};
const deleteLiveViewSuccess = (state, action) => {
  const newState = {
    ...state,
    loading: false,
    liveResponse: action.liveResponse,
    error: null
  };
  return newState;
};

const deleteLiveViewFail = (state, action) => {
  const newState = {
    ...state,
    liveResponse: null,
    loading: false,
    error: action.error
  };
  return newState;
};

export const eventViewReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ADD_TOTAL_VIEW_START:
      return addTotalViewStart(state);
    case actionTypes.ADD_TOTAL_VIEW_SUCCESS:
      return addTotalViewSuccess(state, action);
    case actionTypes.ADD_TOTAL_VIEW_FAIL:
      return addTotalViewFail(state, action);

    case actionTypes.ADD_LIVE_VIEW_START:
      return addLiveViewStart(state);
    case actionTypes.ADD_LIVE_VIEW_SUCCESS:
      return addLiveViewSuccess(state, action);
    case actionTypes.ADD_LIVE_VIEW_FAIL:
      return addLiveViewFail(state, action);

    case actionTypes.DELETE_LIVE_VIEW_START:
      return deleteLiveViewStart(state);
    case actionTypes.DELETE_LIVE_VIEW_SUCCESS:
      return deleteLiveViewSuccess(state, action);
    case actionTypes.DELETE_LIVE_VIEW_FAIL:
      return deleteLiveViewFail(state, action);

    default:
      return state;
  }
};
