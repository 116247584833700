export const getServerBasedOnPoints = (lat, lon, locations, servers,sockets) => {
  const distances = [];
  locations.map(location => {
    const serverLat = location.split("/")[0];
    const serverLon = location.split("/")[1];
    const result = getDistanceFromLatLonInKm(lat, lon, serverLat, serverLon);
    distances.push(result);
  });

  const index = distances.findIndex(val => {
    return val === Math.min.apply(null, distances);
  });
  const convertToSocket = servers[index].replace("https", "wss");
  const socketUrl = sockets.find(f => {
    if (f.includes(convertToSocket)) {
      return f;
    }
  });
  return [servers[index], socketUrl];
};

const getDistanceFromLatLonInKm = (lat1, lon1, lat2, lon2) => {
  var R = 6371; // Radius of the earth in km
  var dLat = deg2rad(lat2 - lat1);
  var dLon = deg2rad(lon2 - lon1);
  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c;
  return d; //Result in km
};
const deg2rad = deg => {
  return deg * (Math.PI / 180);
};

// ======

export const getClosestServer = (servers, sockets) => {
  const res = Promise.all(
    servers.map(server => {
      const start_time = new Date().getTime();
      return fetch(server)
        .then(res => {
          if (res.status === 403) return new Date().getTime() - start_time;
          return 10000;
        })
        .catch(() => 10000);
    })
  ).then(data => {
    const index = data.findIndex(val => {
      return val === Math.min.apply(null, data);
    });
    const convertToSocket = servers[index].replace("https", "wss");
    const socketUrl = sockets.find(f => {
      if (f.includes(convertToSocket)) {
        return f;
      }
    });
    return [servers[index], socketUrl];
  });
  return res
};
