import * as actionTypes from "./actionTypes";

export const addChat = (token, object, server) => {
  return {
    type: actionTypes.ADD_CHAT,
    object: object,
    server: server,
    token: token
  };
};
export const addChatStart = () => {
  return {
    type: actionTypes.ADD_CHAT_START
  };
};

export const addChatSuccess = response => {
  return {
    type: actionTypes.ADD_CHAT_SUCCESS,
    response: response
  };
};
export const addChatFail = error => {
  return {
    type: actionTypes.ADD_CHAT_FAIL,
    error: error
  };
};

// ================================================ searchChats

export const searchChats = (token, object, server) => {
  return {
    type: actionTypes.SEARCH_CHATS,
    object: object,
    server: server,
    token: token
  };
};
export const searchChatsStart = () => {
  return {
    type: actionTypes.SEARCH_CHATS_START
  };
};

export const searchChatsSuccess = chats => {
  return {
    type: actionTypes.SEARCH_CHATS_SUCCESS,
    chats: chats
  };
};
export const searchChatsFail = error => {
  return {
    type: actionTypes.SEARCH_CHATS_FAIL,
    error: error
  };
};

