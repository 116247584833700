import React from "react";
import { Select as SelectList, Divider, Icon } from "antd";
import PropTypes from "prop-types";
import "./Select.css";

export const Select = props => {
  let options;
  if (props.options) {
    options = Object.keys(props.options).map(key => {
      return (
        <SelectList.Option
          key={props.options[key][props.idField]}
          value={props.options[key][props.idField]}
        >
          {
            props.mergeTitle ? 
            `${props.options[key][props.titleField] !== undefined ? props.options[key][props.titleField] : ''} (${props.options[key][props.mergeTitle]})`
            :
          props.options[key][props.titleField]
          }
        </SelectList.Option>
      );
    });
  } else {
    options = (
      <SelectList.Option value="Please Wait">Please Wait!</SelectList.Option>
    );
  }
  return (
    <SelectList
      showSearch
      defaultValue={props.defaultValue ? props.defaultValue : "Please Select"}
      value={
        props.value
          ? props.value
          : props.defaultValue
          ? props.defaultValue
          : null
      }
      placeholder={props.placeholder ? props.placeholder : "Choose one"}
      onChange={val => props.onChange(val)}
      filterOption={(input, option) =>
        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      dropdownClassName={
        props.dropdownClassName ? props.dropdownClassName : null
      }
      dropdownRender={menu =>
        props.dropdownRender ? (
          <div>
            {menu}
            <Divider style={{ margin: "4px 0" }} />
            <div
              style={{ padding: "5px", cursor: "pointer" }}
              onMouseDown={props.dropdownRender.onClick}
            >
              <Icon type="plus" />{" "}
              {props.dropdownRender.text ? props.dropdownRender.text : "Add"}
            </div>
          </div>
        ) : (
          <div>{menu}</div>
        )
      }
      className={`MSD-Select ${props.className ? props.className : ""}`}
    >
      {options}
    </SelectList>
  );
};

Select.propTypes = {
  onChange: PropTypes.func,
  options: PropTypes.array.isRequired
};
