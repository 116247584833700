import * as actionTypes from "../actions/actionTypes";

const initialState = {
  response: null,
  loading: false,
  error: null,
  reactions: null
};

const addReactionStart = state => {
  const newState = {
    ...state,
    loading: true,
    error: null,
    response: null
  };
  return newState;
};
const addReactionSuccess = (state, action) => {
  const newState = {
    ...state,
    loading: false,
    response: action.response,
    error: null
  };
  return newState;
};

const addReactionFail = (state, action) => {
  const newState = {
    ...state,
    response: null,
    loading: false,
    error: action.error
  };
  return newState;
};

// ==============================

const editReactionStart = state => {
  const newState = {
    ...state,
    loading: true,
    error: null,
    response: null
  };
  return newState;
};
const editReactionSuccess = (state, action) => {
  const newState = {
    ...state,
    loading: false,
    response: action.response,
    error: null
  };
  return newState;
};

const editReactionFail = (state, action) => {
  const newState = {
    ...state,
    response: null,
    loading: false,
    error: action.error
  };
  return newState;
};

// ==============================

const deleteReactionStart = state => {
  const newState = {
    ...state,
    loading: true,
    error: null,
    response: null
  };
  return newState;
};
const deleteReactionSuccess = (state, action) => {
  const newState = {
    ...state,
    loading: false,
    response: action.response,
    error: null
  };
  return newState;
};

const deleteReactionFail = (state, action) => {
  const newState = {
    ...state,
    response: null,
    loading: false,
    error: action.error
  };
  return newState;
};

// ========================== SEARCH REACTIONS

const searchReactionsStart = state => {
  const newState = {
    ...state,
    loading: true,
    reactions: null,
    error:null
  };
  return newState;
};
const searchReactionsSuccess = (state, action) => {
  const newState = {
    ...state,
    loading: false,
    reactions: action.reactions,
    error: null
  };
  return newState;
};

const searchReactionsFail = (state, action) => {
  const newState = {
    ...state,
    loading: false,
    reactions: null,
    error: action.error
  };
  return newState;
};

export const reactionReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ADD_REACTION_START:
      return addReactionStart(state);
    case actionTypes.ADD_REACTION_SUCCESS:
      return addReactionSuccess(state, action);
    case actionTypes.ADD_REACTION_FAIL:
      return addReactionFail(state, action);

    case actionTypes.EDIT_REACTION_START:
      return editReactionStart(state);
    case actionTypes.EDIT_REACTION_SUCCESS:
      return editReactionSuccess(state, action);
    case actionTypes.EDIT_REACTION_FAIL:
      return editReactionFail(state, action);

    case actionTypes.DELETE_REACTION_START:
      return deleteReactionStart(state);
    case actionTypes.DELETE_REACTION_SUCCESS:
      return deleteReactionSuccess(state, action);
    case actionTypes.DELETE_REACTION_FAIL:
      return deleteReactionFail(state, action);

    case actionTypes.SEARCH_REACTIONS_START:
      return searchReactionsStart(state);
    case actionTypes.SEARCH_REACTIONS_SUCCESS:
      return searchReactionsSuccess(state, action);
    case actionTypes.SEARCH_REACTIONS_FAIL:
      return searchReactionsFail(state, action);

    default:
      return state;
  }
};
