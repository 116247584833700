import React, { useState } from "react";
import { DatePicker } from "antd";
import moment from "moment";
import PropTypes from "prop-types";

export const DateAndTimePicker = props => {
  const dateFormat = props.format ? props.format : "YYYY/MM/DD HH:mm:ss";
  const disabledDateFunc = current => {
    return (
      current < moment().startOf("day") ||
      current > moment().add(+parseInt(props.maximumDay), "days")
    );
  };
  const getDisabledHours = isLastDay => {
    var hours = [];
    if (!isLastDay) {
      for (var i = 0; i < moment().hour(); i++) {
        hours.push(i);
      }
      return hours;
    } else {
      for (var i = moment().hour(); i < 24; i++) {
        hours.push(i);
      }
      return hours;
    }
  };
  const getDisabledMinutes = (date, isLastDay) => {
    var minutes = [];
    if (
      moment().date() === moment(date).date() &&
      moment().hour() === moment(date).hour()
    ) {
      if (!isLastDay) {
        for (var i = 0; i < moment().minute(); i++) {
          minutes.push(i);
        }
        return minutes;
      } else {
        for (var i = moment().minute(); i < 60; i++) {
          minutes.push(i);
        }
        return minutes;
      }
    }
    return []
  };
  const disabledDateTime = data => {
    if (data.format("YYYY-MM-DD") !== moment().format("YYYY-MM-DD")) return {};
    return {
      disabledHours: () => getDisabledHours(props.isLastDay),
      disabledMinutes: () => getDisabledMinutes(data, props.isLastDay)
    };
  };
  return (
    <div>
      <DatePicker
        className={`MSD-DatePicker ${props.className}`}
        showTime={{
          format: props.timeFormat ? props.timeFormat : "HH:mm"
        }}
        disabledTime={disabledDateTime}
        format={dateFormat.replace(/\//g, "-")}
        disabledDate={props.disableDate ? disabledDateFunc : null}
        placeholder={props.placeholder ? props.placeholder : "Select Time"}
        defaultValue={
          props.defaultValue
            ? moment(props.defaultValue, dateFormat)
            : moment(new Date(), dateFormat)
        }
        onChange={(date, dateString) =>
          props.onChange(dateString.replace(/-/g, "/"))
        }
        style={props.style}
        disabled={props.disabled ? props.disabled : false}
      />
    </div>
  );
};

DateAndTimePicker.propTypes = {
  onChange: PropTypes.func.isRequired,
  format: PropTypes.string,
  timeFormat: PropTypes.string,
  defaultValue: PropTypes.string,
  style: PropTypes.object,
  placeholder: PropTypes.string,
  className: PropTypes.string
};
