import { put } from "redux-saga/effects";
import * as actions from "../actions/index";
import { PUT } from "../callApiWrapper";

export function* changePasswordSaga(action) {
  yield put(actions.changePasswordStart());
  const headers = {
    "X-Auth-Token": action.token,
    "content-type": "application/json"
  };
  const response = yield PUT(
    `${action.server}api/user/changePassword`,
    action.formInfo,
    headers
  );
  if (response && response === 503) {
    yield put(actions.serverErrorSuccess(response));
  } else if (response && response.status === 200) {
    yield put(actions.changePasswordSuccess(response));
  } else if (response && response.status === 403) {
    yield put(actions.userLogout(action.token, action.server));
    yield put(actions.changePasswordFail(response));
  } else {
    yield put(actions.changePasswordFail(response));
  }
}
