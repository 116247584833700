import { put, cancelled } from "redux-saga/effects";
import * as actions from "../actions/index";
import { POST, GET, PUT, DELETE } from "../callApiWrapper";

export function* getEventsSaga(action) {
  try {
    yield put(actions.getEventsStart());
    let data = {
      pageModel: {
        orders: [
          {
            dir: action.searchModel.order,
            property: action.searchModel.property
              ? action.searchModel.property
              : "id"
          }
        ],
        pageNumber: action.searchModel.pageNumber,
        pageSize: action.searchModel.pageSize
      },
      searchModel: {
        states: action.searchModel.states
          ? action.searchModel.states
          : ["PUBLISHED", "RUNNING", "FINISHED"],
        title: action.searchModel.title ? action.searchModel.title : "",
        ownerUserId: action.searchModel.ownerUserId
          ? action.searchModel.ownerUserId
          : "",
        streamReporterUserId: action.searchModel.streamReporterUserId
          ? action.searchModel.streamReporterUserId
          : "",
        eventCategoryIds: action.searchModel.eventCategoryIds
          ? action.searchModel.eventCategoryIds
          : null,
        excludeHidden: action.searchModel.hidden ? action.searchModel.hidden : false
      }
    };
    console.log('data',data)
    if (action.searchModel.tags) {
      const newObject = {
        ...data.searchModel,
        tags: action.searchModel.tags
      };
      data = {
        ...data,
        searchModel: newObject
      };
    }
    const headers = {
      "content-type": "application/json",
      "X-Auth-Token": action.token
    };

    const response = yield POST(
      `${action.server}api/vume/event/search`,
      data,
      headers
    );
    if (response && response === 503) {
      yield put(actions.serverErrorSuccess(response));
    }
    if (response && response.status === 200) {
      yield put(actions.getEventsSuccess(response.data));
      ƒ;
    } else if (
      response &&
      (response.status === 403 || response.status === 401)
    ) {
      yield put(actions.userLogout(action.token, action.server));
    } else {
      yield put(actions.getEventsFail(response));
    }
  } finally {
    if (yield cancelled()) yield put(actions.getEventsCancel());
  }
}

// =============================================================== USER LAST EVENTS

export function* userLastEventsSaga(action) {
  try {
    yield put(actions.userLastEventsStart());
    let data = {
      orders: [
        {
          dir: action.searchModel.order,
          property: action.searchModel.property
            ? action.searchModel.property
            : "id"
        }
      ],
      pageNumber: action.searchModel.pageNumber,
      pageSize: action.searchModel.pageSize
    };
    const headers = {
      "content-type": "application/json",
      "X-Auth-Token": action.token
    };

    const response = yield POST(
      `${action.server}api/vume/event/usersLastEvents`,
      data,
      headers
    );
    if (response && response === 503) {
      yield put(actions.serverErrorSuccess(response));
    }
    if (response && response.status === 200) {
      yield put(actions.userLastEventsSuccess(response.data));
      ƒ;
    } else if (
      response &&
      (response.status === 403 || response.status === 401)
    ) {
      yield put(actions.userLogout(action.token, action.server));
    } else {
      yield put(actions.userLastEventsFail(response));
    }
  } finally {
    if (yield cancelled()) yield put(actions.getEventsCancel());
  }
}

// =============================================================== SEARCHED EVENTS
export function* searchEventsSaga(action) {
  yield put(actions.searchEventsStart());
  let data = {
    pageModel: {
      orders: [
        {
          dir: action.searchModel.order,
          property: action.searchModel.property
            ? action.searchModel.property
            : "id"
        }
      ],
      pageNumber: action.searchModel.pageNumber,
      pageSize: action.searchModel.pageSize
    },
    searchModel: {
      states: action.searchModel.states
        ? action.searchModel.states
        : ["PUBLISHED", "RUNNING", "FINISHED"],
      title: action.searchModel.title ? action.searchModel.title : "",
      ownerUserId: action.searchModel.ownerUserId
        ? action.searchModel.ownerUserId
        : "",
      streamReporterUserId: action.searchModel.streamReporterUserId
        ? action.searchModel.streamReporterUserId
        : "",
      eventCategoryIds: action.searchModel.eventCategoryIds
        ? action.searchModel.eventCategoryIds
        : null
    }
  };
      console.log('data1',data)

  if (action.searchModel.tags) {
    const newObject = {
      ...data.searchModel,
      tags: action.searchModel.tags
    };
    data = {
      ...data,
      searchModel: newObject
    };
  }
  const headers = {
    "content-type": "application/json",
    "X-Auth-Token": action.token
  };

  const response = yield POST(
    `${action.server}api/vume/event/search`,
    data,
    headers
  );
  if (response && response === 503) {
    yield put(actions.serverErrorSuccess(response));
  }
  if (response && response.status === 200) {
    yield put(actions.searchEventsSuccess(response.data));
  } else if (response && (response.status === 403 || response.status === 401)) {
    yield put(actions.userLogout(action.token, action.server));
  } else {
    yield put(actions.searchEventsFail(response));
  }
}

// =============================================================== SEARCHED TOP EVENTS
export function* searchTopEventsSaga(action) {
  try {
    yield put(actions.searchTopEventsStart());
    let data = {
      pageModel: {
        orders: [
          {
            dir: action.searchModel.order,
            property: action.searchModel.property
              ? action.searchModel.property
              : "id"
          }
        ],
        pageNumber: action.searchModel.pageNumber,
        pageSize: action.searchModel.pageSize
      },
      searchModel: {
        states: action.searchModel.states
          ? action.searchModel.states
          : ["PUBLISHED", "RUNNING", "FINISHED"],
        title: action.searchModel.title ? action.searchModel.title : "",
        ownerUserId: action.searchModel.ownerUserId
          ? action.searchModel.ownerUserId
          : "",
        streamReporterUserId: action.searchModel.streamReporterUserId
          ? action.searchModel.streamReporterUserId
          : "",
        onlyTop: action.searchModel.onlyTop ? action.searchModel.onlyTop : true,
        eventCategoryIds: action.searchModel.eventCategoryIds
          ? action.searchModel.eventCategoryIds
          : null
      }
    };
    if (action.searchModel.tags) {
      const newObject = {
        ...data.searchModel,
        tags: action.searchModel.tags
      };
      data = {
        ...data,
        searchModel: newObject
      };
    }
    const headers = {
      "content-type": "application/json",
      "X-Auth-Token": action.token
    };

    const response = yield POST(
      `${action.server}api/vume/event/search`,
      data,
      headers
    );
    if (response === 503) {
      yield put(actions.serverErrorSuccess(response));
    }
    if (response && response.status === 200) {
      yield put(actions.searchTopEventsSuccess(response.data));
    } else if (
      response &&
      (response.status === 403 || response.status === 401)
    ) {
      yield put(actions.userLogout(action.token, action.server));
    } else {
      yield put(actions.searchTopEventsFail(response));
    }
  } finally {
    if (yield cancelled()) yield put(actions.getEventsCancel());
  }
}

// =============================================================== USER PROFILE EVENTS
export function* userProfileEventsSaga(action) {
  try {
    yield put(actions.userProfileEventsStart());
    let data = {
      pageModel: {
        orders: [
          {
            dir: action.searchModel.order,
            property: "id"
          }
        ],
        pageNumber: action.searchModel.pageNumber,
        pageSize: action.searchModel.pageSize
      },
      searchModel: {
        states: action.searchModel.states
          ? action.searchModel.states
          : ["PUBLISHED", "RUNNING", "FINISHED"],
        title: action.searchModel.title ? action.searchModel.title : "",
        ownerUserId: action.searchModel.ownerUserId
          ? action.searchModel.ownerUserId
          : "",
        includeAllStreamReporterBroadcasts: true,
        streamReporterUserId: action.searchModel.streamReporterUserId
          ? action.searchModel.streamReporterUserId
          : ""
      }
    };
        console.log('data2',data)

    if (action.searchModel.tags) {
      const newObject = {
        ...data.searchModel,
        tags: action.searchModel.tags
      };
      data = {
        ...data,
        searchModel: newObject
      };
    }
    const headers = {
      "content-type": "application/json",
      "X-Auth-Token": action.token
    };

    const response = yield POST(
      `${action.server}api/vume/event/search`,
      data,
      headers
    );
    if (response === 503) {
      yield put(actions.serverErrorSuccess(response));
    }
    if (response && response.status === 200) {
      yield put(actions.userProfileEventsSuccess(response.data));
    } else if (
      response &&
      (response.status === 403 || response.status === 401)
    ) {
      yield put(actions.userLogout(action.token, action.server));
    } else {
      yield put(actions.userProfileEventsFail(response));
    }
  } finally {
    if (yield cancelled()) yield put(actions.userProfileEventsCancel());
  }
}

// =============================================================== GET JOINED EVENTS

export function* getMyBroadcastSaga(action) {
  yield put(actions.getMyBroadcastStart());
  const data = {
    pageModel: {
      orders: [
        {
          dir: action.searchModel.order,
          property: "id"
        }
      ],
      pageNumber: action.searchModel.pageNumber,
      pageSize: action.searchModel.pageSize
    },
    searchModel: {
      states: action.searchModel.states
        ? action.searchModel.states
        : ["PUBLISHED", "RUNNING", "FINISHED"]
    }
  };
  const headers = {
    "content-type": "application/json",
    "X-Auth-Token": action.token
  };
  const response = yield POST(
    `${action.server}api/vume/event/broadcasts`,
    data,
    headers
  );
  if (response && response === 503) {
    yield put(actions.serverErrorSuccess(response));
  } else if (response && response.status === 200) {
    yield put(actions.getMyBroadcastSuccess(response.data));
  } else if (response && (response.status === 403 || response.status === 401)) {
    yield put(actions.userLogout(action.token, action.server));
  } else {
    yield put(actions.getMyBroadcastFail(response));
  }
}

// ============================================================= EVENT BY ID

export function* getEventByIdSaga(action) {
  yield put(actions.getEventByIdStart());
  const headers = {
    "content-type": "application/json",
    "X-Auth-Token": action.token
  };
  const response = yield GET(
    `${action.server}api/vume/event/get/${action.id}`,
    headers
  );
  if (response && response === 503) {
    yield put(actions.serverErrorSuccess(response));
  } else if (response && response === 451) {
    yield put(actions.permissionErrorSuccess(Math.random()));
  } else if (response && response.status === 200) {
    yield put(actions.getEventByIdSuccess(response.data));
  } else if (response && (response.status === 403 || response.status === 401)) {
    yield put(actions.userLogout(action.token, action.server));
  } else {
    yield put(actions.getEventByIdFail(response));
  }
}

// ============================================================= CHECK BY ID

export function* checkEventByIdSaga(action) {
  yield put(actions.checkEventByIdStart());
  const headers = {
    "content-type": "application/json",
    "X-Auth-Token": action.token
  };
  const response = yield GET(
    `${action.server}api/vume/event/lightGet/${action.id}`,
    headers
  );
  if (response && response === 503) {
    yield put(actions.serverErrorSuccess(response));
  } else if (response && response.status === 200) {
    yield put(actions.checkEventByIdSuccess(response.data));
  } else if (response && (response.status === 403 || response.status === 401)) {
    yield put(actions.userLogout(action.token, action.server));
  } else {
    yield put(actions.checkEventByIdFail(response));
  }
}

// ============================================================= Create EVENT

export function* createEventSaga(action) {
  yield put(actions.createEventStart());
  let response;
  if (action.data.avatar.encoding === "BASE64") {
    const ddb = `--${action.data.avatar.key}`;
    const ending = `${ddb}--`;
    let body;
    let file;
    const jsonData =
      `${ddb}\n` +
      `Content-Disposition: form-data; name="data"\n` +
      `Content-Type: application/json\n\n` +
      `${JSON.stringify(action.data)}\n`;

    if (action.file) {
      file =
        `${ddb}\n` +
        `Content-Disposition: form-data; name="files"; filename="eventAvatar"\n` +
        `Content-Type: ${action.file.mime}\n\n` +
        `${action.file.data}\n`;
    }
    body = jsonData;
    if (file) {
      body = body + file + ending;
    } else {
      body = body + ending;
    }
    response = yield fetch(`${action.server}api/vume/event/createAndPublish`, {
      method: "POST",
      headers: {
        "Content-type": `multipart/form-data; boundary=${action.data.avatar.key}`,
        "X-Auth-Token": action.token
      },
      body
    })
      .then(res => {
        return res.json();
      })
      .then(json => {
        const newResponse = {
          data: json,
          status: json.statusCode ? json.statusCode : 200
        };
        return newResponse;
      })
      .catch(err => {
        return err;
      });
  } else {
    const eventData = new FormData();
    eventData.append(
      "data",
      new Blob([JSON.stringify(action.data)], { type: "application/json" })
    );
    if (action.file) {
      eventData.append("files", action.file, "eventAvatar");
    }
    const headers = {
      "X-Auth-Token": action.token
    };
    response = yield POST(
      `${action.server}api/vume/event/createAndPublish`,
      eventData,
      headers
    );
  }
  if (response && response === 503) {
    yield put(actions.serverErrorSuccess(response));
  } else if (response && response.status === 200) {
    yield put(actions.createEventSuccess(response.data));
  } else if (response && (response.status === 403 || response.status === 401)) {
    yield put(actions.userLogout(action.token, action.server));
  } else {
    yield put(actions.createEventFail(response));
  }
}

// ============================================================= Create And Start EVENT

export function* createAndStartEventSaga(action) {
  yield put(actions.createAndStartEventStart());
  let response;
  if (action.data.avatar.encoding === "BASE64") {
    const ddb = `--${action.data.avatar.key}`;
    const ending = `${ddb}--`;
    let body;
    let file;
    const jsonData =
      `${ddb}\n` +
      `Content-Disposition: form-data; name="data"\n` +
      `Content-Type: application/json\n\n` +
      `${JSON.stringify(action.data)}\n`;

    if (action.file) {
      file =
        `${ddb}\n` +
        `Content-Disposition: form-data; name="files"; filename="eventAvatar"\n` +
        `Content-Type: ${action.file.mime}\n\n` +
        `${action.file.data}\n`;
    }
    body = jsonData;
    if (file) {
      body = body + file + ending;
    } else {
      body = body + ending;
    }
    response = yield fetch(`${action.server}api/vume/event/createAndStart`, {
      method: "POST",
      headers: {
        "Content-type": `multipart/form-data; boundary=${action.data.avatar.key}`,
        "X-Auth-Token": action.token
      },
      body
    })
      .then(res => {
        return res.json();
      })
      .then(json => {
        const newResponse = {
          data: json,
          status: json.statusCode ? json.statusCode : 200
        };
        return newResponse;
      })
      .catch(err => {
        return err;
      });
  } else {
    const eventData = new FormData();
    eventData.append(
      "data",
      new Blob([JSON.stringify(action.data)], { type: "application/json" })
    );
    if (action.file) {
      eventData.append("files", action.file, "eventAvatar");
    }
    const headers = {
      "X-Auth-Token": action.token
    };
    response = yield POST(
      `${action.server}api/vume/event/createAndStart`,
      eventData,
      headers
    );
  }
  if (response && response === 503) {
    yield put(actions.serverErrorSuccess(response));
  } else if (response && response.status === 200) {
    yield put(actions.createAndStartEventSuccess(response.data));
  } else if (response && (response.status === 403 || response.status === 401)) {
    yield put(actions.userLogout(action.token, action.server));
  } else {
    yield put(actions.createAndStartEventFail(response));
  }
}

// ============================================================= Create And Preview EVENT

export function* createAndPreviewEventSaga(action) {
  yield put(actions.createAndPreviewEventStart());
  let response;
  if (action.data.avatar.encoding === "BASE64") {
    const ddb = `--${action.data.avatar.key}`;
    const ending = `${ddb}--`;
    let body;
    let file;
    const jsonData =
      `${ddb}\n` +
      `Content-Disposition: form-data; name="data"\n` +
      `Content-Type: application/json\n\n` +
      `${JSON.stringify(action.data)}\n`;

    if (action.file) {
      file =
        `${ddb}\n` +
        `Content-Disposition: form-data; name="files"; filename="eventAvatar"\n` +
        `Content-Type: ${action.file.mime}\n\n` +
        `${action.file.data}\n`;
    }
    body = jsonData;
    if (file) {
      body = body + file + ending;
    } else {
      body = body + ending;
    }

    response = yield fetch(`${action.server}api/vume/event/createAndPreview`, {
      method: "POST",
      headers: {
        "Content-type": `multipart/form-data; boundary=${action.data.avatar.key}`,
        "X-Auth-Token": action.token
      },
      body
    })
      .then(res => {
        return res.json();
      })
      .then(json => {
        const newResponse = {
          data: json,
          status: json.statusCode ? json.statusCode : 200
        };
        return newResponse;
      })
      .catch(err => {
        return err;
      });
  } else {
    const eventData = new FormData();
    eventData.append(
      "data",
      new Blob([JSON.stringify(action.data)], { type: "application/json" })
    );
    if (action.file) {
      eventData.append("files", action.file, "eventAvatar");
    }
    const headers = {
      "X-Auth-Token": action.token
    };
    response = yield POST(
      `${action.server}api/vume/event/createAndPreview`,
      eventData,
      headers
    );
  }
  if (response && response === 503) {
    yield put(actions.serverErrorSuccess(response));
  } else if (response && response.status === 200) {
    yield put(actions.createAndPreviewEventSuccess(response.data));
  } else if (response && (response.status === 403 || response.status === 401)) {
    yield put(actions.userLogout(action.token, action.server));
  } else {
    yield put(actions.createAndPreviewEventFail(response));
  }
}

// ============================================================= EDIT EVENT

export function* editEventSaga(action) {
  yield put(actions.editEventStart());
  let response;
  if (action.data.avatar.encoding === "BASE64") {
    const ddb = `--${action.data.avatar.key}`;
    const ending = `${ddb}--`;
    let body;
    let file;
    const jsonData =
      `${ddb}\n` +
      `Content-Disposition: form-data; name="data"\n` +
      `Content-Type: application/json\n\n` +
      `${JSON.stringify(action.data)}\n`;

    if (action.file) {
      file =
        `${ddb}\n` +
        `Content-Disposition: form-data; name="files"; filename="eventAvatar"\n` +
        `Content-Type: ${action.file.mime}\n\n` +
        `${action.file.data}\n`;
    }
    body = jsonData;
    if (file) {
      body = body + file + ending;
    } else {
      body = body + ending;
    }
    response = yield fetch(`${action.server}api/vume/event`, {
      method: "PUT",
      headers: {
        "Content-type": `multipart/form-data; boundary=${action.data.avatar.key}`,
        "X-Auth-Token": action.token
      },
      body
    })
      .then(res => {
        return res.json();
      })
      .then(json => {
        const newResponse = {
          data: json,
          status: json.statusCode ? json.statusCode : 200
        };
        return newResponse;
      })
      .catch(err => {
        return err;
      });
  } else {
    const eventData = new FormData();
    eventData.append(
      "data",
      new Blob([JSON.stringify(action.data)], { type: "application/json" })
    );
    if (action.file) {
      eventData.append("files", action.file, "eventAvatar");
    }
    const headers = {
      "X-Auth-Token": action.token
    };
    response = yield PUT(`${action.server}api/vume/event`, eventData, headers);
  }
  if (response && response === 503) {
    yield put(actions.serverErrorSuccess(response));
  } else if (response && response.status === 200) {
    yield put(actions.editEventSuccess(response.data));
  } else if (response && (response.status === 403 || response.status === 401)) {
    yield put(actions.userLogout(action.token, action.server));
  } else {
    yield put(actions.editEventFail(response));
  }
}

// ============================================================= DELETE EVENT

export function* deleteEventSaga(action) {
  yield put(actions.deleteEventStart());
  const response = yield DELETE(`${action.server}api/vume/event/${action.id}`, {
    "Content-Type": "application/json",
    "X-Auth-Token": action.token
  });
  if (response && response === 503) {
    yield put(actions.serverErrorSuccess(response));
  } else if (response && response.status === 200) {
    yield put(actions.deleteEventSuccess(response.status));
  } else if (response && (response.status === 403 || response.status === 401)) {
    yield put(actions.userLogout(action.token, action.server));
  } else {
    yield put(actions.deleteEventFail(response));
  }
}

// ============================================================= CHANGE STATE

export function* eventChangeStateSaga(action) {
  yield put(actions.eventChangeStateStart());

  const headers = {
    "X-Auth-Token": action.token,
    "content-type": "application/json"
  };
  const response = yield PUT(
    `${action.server}api/vume/event/${action.state}/${action.id}`,
    null,
    headers
  );
  if (response && response === 503) {
    yield put(actions.serverErrorSuccess(response));
  } else if (response && response.status === 200) {
    yield put(actions.eventChangeStateSuccess(response.status));
  } else if (response && (response.status === 403 || response.status === 401)) {
    yield put(actions.userLogout(action.token, action.server));
  } else {
    yield put(actions.eventChangeStateFail(response));
  }
}

// ============================================================= JOIN EVENT

export function* joinOnEventSaga(action) {
  yield put(actions.joinOnEventStart());
  const headers = {
    "X-Auth-Token": action.token,
    "content-type": "application/json"
  };
  const response = yield POST(
    `${action.server}api/vume/event/join`,
    { secret: action.joinCode },
    headers
  );
  if (response && response === 503) {
    yield put(actions.serverErrorSuccess(response));
  } else if (response && response === 451) {
    yield put(actions.permissionErrorSuccess(Math.random()));
    yield put(actions.joinOnEventFail());
  } else if (response && response.status === 200) {
    yield put(actions.joinOnEventSuccess(response.data));
  } else if (response && (response.status === 403 || response.status === 401)) {
    yield put(actions.userLogout(action.token, action.server));
  } else {
    yield put(actions.joinOnEventFail(response.data));
  }
}

// ============================================================= BIND EVENT STREAM

export function* bindEventStreamSaga(action) {
  yield put(actions.bindEventStreamStart());
  const headers = {
    "X-Auth-Token": action.token,
    "content-type": "application/json"
  };
  const response = yield POST(
    `${action.server}api/vume/event/stream/bind`,
    action.object,
    headers
  );
  if (response && response === 503) {
    yield put(actions.serverErrorSuccess(response));
  } else if (response && response.status === 200) {
    yield put(actions.bindEventStreamSuccess(response));
  } else if (response && (response.status === 403 || response.status === 401)) {
    yield put(actions.userLogout(action.token, action.server));
  } else {
    yield put(actions.bindEventStreamFail(response));
  }
}

// ============================================================= EDIT EVENT INFO

export function* editEventInfoSaga(action) {
  yield put(actions.editEventInfoStart());
  const headers = {
    "X-Auth-Token": action.token,
    "content-type": "application/json"
  };
  const response = yield PUT(
    `${action.server}api/vume/event/editInfo`,
    action.data,
    headers
  );
  if (response === 503) {
    yield put(actions.serverErrorSuccess(response));
  } else if (response && response === 451) {
    yield put(actions.permissionErrorSuccess(Math.random()));
  } else if (response && response.status === 200) {
    yield put(actions.editEventInfoSuccess(response.data));
  } else if ((response && response.status === 403) || response.status === 401) {
    yield put(actions.userLogout(action.token, action.server));
  } else {
    yield put(actions.editEventInfoFail(response.data));
  }
}

// ============================================================= CATEGORIES

export function* getAllCategoriesSaga(action) {
  yield put(actions.getAllCategoriesStart());

  const response = yield GET(`${action.server}api/vume/eventCategory/all`, {
    "Content-Type": "application/json"
  });
  if (response === 503) {
    yield put(actions.serverErrorSuccess(response));
  } else if (response && response.status === 200) {
    yield put(actions.getAllCategoriesSuccess(response.data));
  } else if (response && (response.status === 403 || response.status === 401)) {
    yield put(actions.userLogout(action.token, action.server));
  } else {
    yield put(actions.getAllCategoriesFail(response));
  }
}

export function* latestEventsCategorySaga(action) {
  yield put(actions.latestEventsCategoryStart());
  const headers = {
    "X-Auth-Token": action.token,
    "content-type": "application/json"
  };
  const response = yield GET(
    `${action.server}api/vume/event/categorisedLatestEvents`,
    headers
  );
  if (response === 503) {
    yield put(actions.serverErrorSuccess(response));
  } else if (response && response.status === 200) {
    yield put(actions.latestEventsCategorySuccess(response.data));
  } else if (response && (response.status === 403 || response.status === 401)) {
    yield put(actions.userLogout(action.token, action.server));
  } else {
    yield put(actions.latestEventsCategoryFail(response));
  }
}

// ======================= INVITATION
export function* addOwnerInvitationSaga(action) {
  yield put(actions.addOwnerInvitationStart());
  const headers = {
    "content-type": "application/json",
    "X-Auth-Token": action.token
  };

  const response = yield POST(
    `${action.server}api/vume/event/ownerInvite`,
    action.data,
    headers
  );
  if (response === 503) {
    yield put(actions.serverErrorSuccess(response));
  }
  if (response && response.status === 200) {
    yield put(
      actions.addOwnerInvitationSuccess({
        method: "ADD",
        data: response.data
      })
    );
  } else if (response && (response.status === 403 || response.status === 401)) {
    yield put(actions.userLogout(action.token, action.server));
  } else {
    yield put(actions.addOwnerInvitationFail(response));
  }
}

export function* deleteOwnerInvitationSaga(action) {
  yield put(actions.deleteOwnerInvitationStart());
  const headers = {
    "content-type": "application/json",
    "X-Auth-Token": action.token
  };

  const response = yield POST(
    `${action.server}api/vume/event/ownerDisinvite`,
    action.data,
    headers
  );
  if (response === 503) {
    yield put(actions.serverErrorSuccess(response));
  }
  if (response && response.status === 200) {
    yield put(
      actions.deleteOwnerInvitationSuccess({
        method: "DELETE",
        data: response.data
      })
    );
  } else if (response && (response.status === 403 || response.status === 401)) {
    yield put(actions.userLogout(action.token, action.server));
  } else {
    yield put(actions.deleteOwnerInvitationFail(response));
  }
}

export function* toggleHideEventSaga(action) {
  yield put(actions.toggleHideEventStart());
  const headers = {
    "content-type": "application/json",
    "X-Auth-Token": action.token
  };
  const response = yield PUT(
    `${action.server}api/vume/event/${action.id}/customize`,
    { hidden: action.hidden },
    headers
  );

  if (response && response === 503) {
    yield put(actions.serverErrorSuccess(response));
  } else if (response && response.status === 200) {
    yield put(actions.toggleHideEventSuccess(response.status));
  } else if (response && (response.status === 403 || response.status === 401)) {
    yield put(actions.userLogout(action.token, action.server));
  } else {
    yield put(actions.toggleHideEventFail(response));
  }
}
