import { put } from "redux-saga/effects";
import * as actions from "../actions/index";
import { POST, GET, PUT, DELETE } from "../callApiWrapper";

//ALL THESE REQUEST ARE FOR ADMIN ROLE

export function* getUsersSaga(action) {
  yield put(actions.getUsersStart());
  let data = {
    pageModel: {
      orders: [
        {
          dir: action.searchModel.orderDir
            ? action.searchModel.orderDir
            : action.searchModel.order,
          property: action.searchModel.orderProperty
            ? action.searchModel.orderProperty
            : "id"
        }
      ],
      pageNumber: action.searchModel.pageNumber,
      pageSize: action.searchModel.pageSize
    },
    searchModel: {
      email: action.searchModel.email ? action.searchModel.email : ""
    }
  };
  const headers = {
    "content-type": "application/json",
    "X-Auth-Token": action.token
  };

  const response = yield POST(`${action.server}api/user/search`, data, headers);
  if (response && response === 503) {
    yield put(actions.serverErrorSuccess(response));
  }
  if (response && response.status === 200) {
    yield put(actions.getUsersSuccess(response.data));
  } else if (response && (response.status === 403 || response.status === 401)) {
    yield put(actions.userLogout(action.token, action.server));
  } else {
    yield put(actions.getUsersFail(response));
  }
}
//   ============================== CALL GET USERS

export function* getUserByIdSaga(action) {
  yield put(actions.getUserByIdStart());
  const headers = {
    "X-Auth-Token": action.token,
    "Content-type": "application/json"
  };
  const response = yield GET(`${action.server}api/user/${action.id}`, headers);
  if (response && response === 503) {
    yield put(actions.serverErrorSuccess(response));
  } else if (response && response.status === 200) {
    yield put(actions.getUserByIdSuccess(response.data));
  } else if (response && (response.status === 403 || response.status === 401)) {
    yield put(actions.userLogout(action.token, action.server));
  } else {
    yield put(actions.getUserByIdFail(response));
  }
}

//   ============================== ADD USER

export function* addUserSaga(action) {
  yield put(actions.addUserStart());
  let response;
  if (action.data.identity.avatar.encoding === "BASE64") {
    const ddb = `--${action.data.identity.avatar.key}`;
    const ending = `${ddb}--`;
    let body;
    let file;
    const jsonData =
      `${ddb}\n` +
      `Content-Disposition: form-data; name="data"\n` +
      `Content-Type: application/json\n\n` +
      `${JSON.stringify(action.data)}\n`;
    if (action.file) {
      file =
        `${ddb}\n` +
        `Content-Disposition: form-data; name="file"; filename="userAvatar"\n` +
        `Content-Type: ${action.file.mime}\n\n` +
        `${action.file.data}\n`;
    }
    body = jsonData;
    if (file) {
      body = body + file + ending;
    } else {
      body = body + ending;
    }
    response = yield fetch(`${action.server}api/user`, {
      method: "POST",
      headers: {
        "Content-type": `multipart/form-data; boundary=${action.data.identity.avatar.key}`,
        "X-Auth-Token": action.token
      },
      body
    })
      .then(res => {
        return res.json();
      })
      .then(json => {
        const newResponse = {
          data: json,
          status: json.statusCode ? json.statusCode : 200
        };
        return newResponse;
      })
      .catch(err => {
        return err;
      });
  } else {
    const userData = new FormData();
    userData.append(
      "data",
      new Blob([JSON.stringify(action.data)], { type: "application/json" })
    );
    if (action.file) {
      userData.append("file", action.file, "userAvatar");
    }
    const headers = {
      "X-Auth-Token": action.token
    };
    response = yield POST(`${action.server}api/user`, userData, headers);
  }
  if (response && response === 503) {
    yield put(actions.serverErrorSuccess(response));
  } else if (response && response.status === 200) {
    yield put(actions.addUserSuccess(response.data));
  } else if (response && (response.status === 403 || response.status === 401)) {
    yield put(actions.userLogout(action.token, action.server));
  } else {
    yield put(actions.addUserFail(response));
  }
}

//   ============================== DELETE USER

export function* deleteUserSaga(action) {
  yield put(actions.deleteUserStart());
  const headers = {
    "content-type": "application/json",
    "X-Auth-Token": action.token
  };
  const response = yield DELETE(
    `${action.server}api/user/${action.id}`,
    headers
  );

  if (response && response === 503) {
    yield put(actions.serverErrorSuccess(response));
  }
  if (response && response.status === 200) {
    yield put(actions.deleteUserSuccess(response.status));
  } else if (response && (response.status === 403 || response.status === 401)) {
    yield put(actions.userLogout(action.token, action.server));
  } else {
    yield put(actions.deleteUserFail(response));
  }
}

//   ============================== EDIT USER

export function* editUserSaga(action) {
  yield put(actions.editUserStart());
  let response;
  if (action.data.identity.avatar.encoding === "BASE64") {
    const ddb = `--${action.data.identity.avatar.key}`;
    const ending = `${ddb}--`;
    let body;
    let file;
    const jsonData =
      `${ddb}\n` +
      `Content-Disposition: form-data; name="data"\n` +
      `Content-Type: application/json\n\n` +
      `${JSON.stringify(action.data)}\n`;
    if (action.file) {
      file =
        `${ddb}\n` +
        `Content-Disposition: form-data; name="file"; filename="userAvatar"\n` +
        `Content-Type: ${action.file.mime}\n\n` +
        `${action.file.data}\n`;
    }
    body = jsonData;
    if (file) {
      body = body + file + ending;
    } else {
      body = body + ending;
    }
    response = yield fetch(`${action.server}api/user`, {
      method: "PUT",
      headers: {
        "Content-type": `multipart/form-data; boundary=${action.data.identity.avatar.key}`,
        "X-Auth-Token": action.token
      },
      body
    })
      .then(res => {
        return res.json();
      })
      .then(json => {
        const newResponse = {
          data: json,
          status: json.statusCode ? json.statusCode : 200
        };
        return newResponse;
      })
      .catch(err => {
        return err;
      });
  } else {
    const userData = new FormData();
    userData.append(
      "data",
      new Blob([JSON.stringify(action.data)], { type: "application/json" })
    );

    if (action.file) {
      userData.append("file", action.file, "userAvatar");
    }
    const headers = {
      "X-Auth-Token": action.token
    };
    response = yield PUT(`${action.server}api/user`, userData, headers);
  }

  if (response && response === 503) {
    yield put(actions.serverErrorSuccess(response));
  } else if (response && response.status === 200) {
    yield put(actions.editUserSuccess(response.data));
  } else if (response && (response.status === 403 || response.status === 401)) {
    yield put(actions.userLogout(action.token, action.server));
  } else {
    yield put(actions.editUserFail(response));
  }
}

// ============= SEARCH USERS

export function* searchUsersSaga(action) {
  yield put(actions.searchUsersStart());
  let data = {
    pageModel: {
      orders: [
        {
          dir: action.searchModel.orderDir
            ? action.searchModel.orderDir
            : action.searchModel.order,
          property: action.searchModel.orderProperty
            ? action.searchModel.orderProperty
            : "id"
        }
      ],
      pageNumber: action.searchModel.pageNumber,
      pageSize: action.searchModel.pageSize
    },
    searchModel: {
      name: action.searchModel.name ? action.searchModel.name : ""
    }
  };
  const headers = {
    "content-type": "application/json",
    "X-Auth-Token": action.token
  };

  const response = yield POST(
    `${action.server}api/user/searchAccount`,
    data,
    headers
  );
  if (response && response === 503) {
    yield put(actions.serverErrorSuccess(response));
  }
  if (response && response.status === 200) {
    yield put(actions.searchUsersSuccess(response.data));
  } else if (response && (response.status === 403 || response.status === 401)) {
    yield put(actions.userLogout(action.token, action.server));
  } else {
    yield put(actions.searchUsersFail(response));
  }
}
