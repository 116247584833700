import React from 'react';
import styles from './notFound.css'

export const notFound = ()=>{
    return(
        <div className={styles.NotFound}>
            <h2>
                404 Error
            </h2>
            <p>
                The page you are looking for does not exist!
            </p>
        </div>
    )
}

