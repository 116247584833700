export const tooltips = {
  cameraName: 'This is the name of the RTMP camera',
  cameraPosition: 'Please choose a camera angle from the list below.',
  defaultCamera:
    'The system-detected camera is any camera that is already in your system or can be connected via a cable.',
  RTMPCamera:
    'If adding devices for RTMP streaming, make sure they support live streaming.',
  joinCode: 'Enter the join code provided by the group admin here.',
  joinCode1:
    'This feature should be used when multiple users want to broadcast the same event using their own user IDs. Please obtain the join code from the team leader and enter it in the box below to proceed.',
  joinCode2:
    'For multiple streams under a single user account, the user can sign up on multiple devices with the same ID and password and begin streaming without a join code.',
  shareJoinCode:
    'Share this code with the members of your broadcast group so they can join the event.',
  teamAdmin:
    'Multiuser broadcast mode enables multiple users to broadcast an event from any location using a single or multiple cameras. This mode requires one member of the group to set up the broadcast details as the administrator (live or scheduled). Once the admin has completed the setup, a Join code will be generated, which must be shared with the other group members in order for them to join.',
  teamMember:
    "Group broadcast enables multiple users to broadcast an event from any location using a single or multiple cameras. Team members must identify and set up the cameras that will be used for the broadcast, enter the Join code provided by the Team Admin, and broadcast in accordance with the event's configuration.",
  startTime:
    'Dates and times for events will display according to your current time zone.',
  reStreaming:
    'You can enable this feature to broadcast live to other social media platforms.',
  streamBox: 'Drag and drop files here',
  copied: 'Copied',
  multiScreens: 'Multi screens',
  multiScreensOn: 'Multi screens: On',
  multiScreensOff: 'Multi screens: Off',
  fullScreenExit: 'Exit fullscreen',
  fullScreen: 'Fullscreen',
  minimize: 'Minimize',
  maximize: 'Maximize',
  chatOn: 'Live Chat: On',
  chatOff: 'Live Chat: Off',
  vodChatOn: 'Chat: On',
  vodChatOff: 'Chat: Off',
  firefoxBroadcast:
    'There have been reports of intermittent issues when using Firefox to stream on ViuLive. We recommend using a different browser if you encounter any issues.',
  obsSetting:
    'You must use our recommended OBS configuration for optimal streaming results; click here for more information.',
  facebookReStream:
    'Please note that Facebook requires 720p resolution for the best streaming results.'
}
