import * as actionTypes from "./actionTypes";

// ADD ====================
export const addReaction = (token, object, server) => {
  return {
    type: actionTypes.ADD_REACTION,
    token: token,
    object: object,
    server: server
  };
};

export const addReactionStart = () => {
  return {
    type: actionTypes.ADD_REACTION_START
  };
};

export const addReactionSuccess = response => {
  return {
    type: actionTypes.ADD_REACTION_SUCCESS,
    response: response
  };
};
export const addReactionFail = error => {
  return {
    type: actionTypes.ADD_REACTION_FAIL,
    error: error
  };
};

// EDIT ====================

export const editReaction = (token, object, server) => {
  return {
    type: actionTypes.EDIT_REACTION,
    token: token,
    object: object,
    server: server
  };
};

export const editReactionStart = () => {
  return {
    type: actionTypes.EDIT_REACTION_START
  };
};

export const editReactionSuccess = response => {
  return {
    type: actionTypes.EDIT_REACTION_SUCCESS,
    response: response
  };
};
export const editReactionFail = error => {
  return {
    type: actionTypes.EDIT_REACTION_FAIL,
    error: error
  };
};

// DELETE ====================

export const deleteReaction = (token, object, server) => {
  return {
    type: actionTypes.DELETE_REACTION,
    token: token,
    object: object,
    server: server
  };
};

export const deleteReactionStart = () => {
  return {
    type: actionTypes.DELETE_REACTION_START
  };
};

export const deleteReactionSuccess = response => {
  return {
    type: actionTypes.DELETE_REACTION_SUCCESS,
    response: response
  };
};
export const deleteReactionFail = error => {
  return {
    type: actionTypes.DELETE_REACTION_FAIL,
    error: error
  };
};

// SEARCH ====================

export const searchReactions = (token, searchModel, server) => {
  return {
    type: actionTypes.SEARCH_REACTIONS,
    token: token,
    searchModel: searchModel,
    server: server
  };
};

export const searchReactionsStart = () => {
  return {
    type: actionTypes.SEARCH_REACTIONS_START
  };
};

export const searchReactionsSuccess = reactions => {
  return {
    type: actionTypes.SEARCH_REACTIONS_SUCCESS,
    reactions: reactions
  };
};

export const searchReactionsFail = error => {
  return {
    type: actionTypes.SEARCH_REACTIONS_FAIL,
    error: error
  };
};
