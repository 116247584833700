import * as actionTypes from './actionTypes'


export const getMetaData = (eventId,server) => {
    return {
      type: actionTypes.GET_META_DATA,
        eventId:eventId,
        server:server,
    };
  };
  
  export const getMetaDataStart = () => {
    return {
      type: actionTypes.GET_META_DATA_START,
    };
  };
  
  export const getMetaDataSuccess = data => {
    return {
      type: actionTypes.GET_META_DATA_SUCCESS,
      data: data,
    };
  };
  
  export const getMetaDataFail = error => {
    return {
      type: actionTypes.GET_META_DATA_FAIL,
      error: error
    };
  };
  