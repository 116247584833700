import { put } from "redux-saga/effects";
import * as actions from "../actions/index";
import { GET } from "../callApiWrapper";

export function* getLocationsSaga(action) {
  yield put(actions.getLocationsStart());

  const response = yield GET(`${action.server}api/location/country/all`, {
    "Content-Type": "application/json"
  });
  if (response && response === 503) {
    yield put(actions.serverErrorSuccess(response));
  } else if (response && response.status === 200) {
    const filteredItems = response.data.filter(item => {
      return item.id !== "5c2ecd7e5adf253980c0cb5e";
    });
    const sortedData = filteredItems.sort((a, b) => (a.name > b.name ? 1 : -1));
    let locations = [...[{ id: null, name: "Not Specified" }], ...sortedData];
    yield put(actions.getLocationsSuccess(locations));
  } else if (response && (response.status === 403 || response.status === 401)) {
    yield put(actions.userLogout(action.token, action.server));
  } else {
    yield put(actions.getLocationsFail(response));
  }
}
