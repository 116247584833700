import * as actionTypes from "../actions/actionTypes";

const initialState = {
  loading: false,
  response: null,
  invoice: null,
  history: null,
  paymentResponse: null
};

const createInvoiceStart = state => {
  const newState = {
    ...state,
    loading: true,
    response: null
  };
  return newState;
};
const createInvoiceSuccess = (state, action) => {
  const newState = {
    ...state,
    response: action.response,
    loading: false,
    error: null
  };
  return newState;
};

const createInvoiceFail = (state, action) => {
  const newState = {
    ...state,
    error: action.error,
    loading: false
  };
  return newState;
};

// ==================================================================

const checkPaidStart = state => {
  const newState = {
    ...state,
    loading: true,
    paymentResponse: null
  };
  return newState;
};
const checkPaidClear = state => {
  const newState = {
    loading: false,
    response: null,
    invoice: null,
    history: null,
    paymentResponse: null
  };
  return newState;
};
const checkPaidSuccess = (state, action) => {
  const newState = {
    ...state,
    paymentResponse: action.paymentResponse,
    loading: false,
    error: null
  };
  return newState;
};

const checkPaidFail = (state, action) => {
  const newState = {
    ...state,
    error: action.error,
    paymentResponse: null,
    loading: false
  };
  return newState;
};

// ==================================================================

const searchInvoiceHistoryStart = state => {
  const newState = {
    ...state,
    loading: true,
    history: null,
    error: null
  };
  return newState;
};
const searchInvoiceHistorySuccess = (state, action) => {
  const newState = {
    ...state,
    history: action.history,
    loading: false,
    error: null
  };
  return newState;
};

const searchInvoiceHistoryFail = (state, action) => {
  const newState = {
    ...state,
    error: action.error,
    history: null,
    loading: false
  };
  return newState;
};

// ==================================================================

const getInvoiceByIdStart = state => {
  const newState = {
    ...state,
    loading: true,
    invoice: null,
    error: null
  };
  return newState;
};
const getInvoiceByIdSuccess = (state, action) => {
  const newState = {
    ...state,
    invoice: action.invoice,
    loading: false,
    error: null
  };
  return newState;
};

const getInvoiceByIdFail = (state, action) => {
  const newState = {
    ...state,
    error: action.error,
    invoice: null,
    loading: false
  };
  return newState;
};

export const invoiceReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CREATE_INVOICE_START:
      return createInvoiceStart(state);
    case actionTypes.CREATE_INVOICE_SUCCESS:
      return createInvoiceSuccess(state, action);
    case actionTypes.CREATE_INVOICE_FAIL:
      return createInvoiceFail(state, action);

    case actionTypes.CHECK_PAID_START:
      return checkPaidStart(state);
    case actionTypes.CHECK_PAID_CLEAR:
      return checkPaidClear(state);
    case actionTypes.CHECK_PAID_SUCCESS:
      return checkPaidSuccess(state, action);
    case actionTypes.CHECK_PAID_FAIL:
      return checkPaidFail(state, action);

    case actionTypes.SEARCH_INVOICE_HISTORY_START:
      return searchInvoiceHistoryStart(state);
    case actionTypes.SEARCH_INVOICE_HISTORY_SUCCESS:
      return searchInvoiceHistorySuccess(state, action);
    case actionTypes.SEARCH_INVOICE_HISTORY_FAIL:
      return searchInvoiceHistoryFail(state, action);

    case actionTypes.GET_INVOICE_BY_ID_START:
      return getInvoiceByIdStart(state);
    case actionTypes.GET_INVOICE_BY_ID_SUCCESS:
      return getInvoiceByIdSuccess(state, action);
    case actionTypes.GET_INVOICE_BY_ID_FAIL:
      return getInvoiceByIdFail(state, action);

    default:
      return state;
  }
};
