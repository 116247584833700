import { put } from "redux-saga/effects";
import * as actions from "../actions/index";
import { POST, GET } from "../callApiWrapper";

export function* createInvoiceSaga(action) {
  yield put(actions.createInvoiceStart());
  const headers = {
    "X-Auth-Token": action.token,
    "Content-type": "application/json"
  };
  const response = yield POST(
    `${action.server}api/monetizing/invoice`,
    action.req,
    headers
  );
  if (response && response === 503) {
    yield put(actions.serverErrorSuccess(response));
  } else if (response && response.status === 200) {
    yield put(actions.createInvoiceSuccess(response.data));
  } else if (response && (response.status === 403 || response.status === 401)) {
    yield put(actions.userLogout(action.token, action.server));
  } else {
    yield put(actions.createInvoiceFail(response));
  }
}

// ========================================================

export function* checkPaidSaga(action) {
  yield put(actions.checkPaidStart());
  const headers = {
    "X-Auth-Token": action.token,
    "Content-type": "application/json"
  };
  const response = yield POST(
    `${action.server}api/monetizing/invoice/checkPaid`,
    action.req,
    headers
  );
  if (response && response === 503) {
    yield put(actions.serverErrorSuccess(response));
  } else if (response && response.status === 200) {
    yield put(actions.checkPaidSuccess(response.data));
  } else if (response && (response.status === 403 || response.status === 401)) {
    yield put(actions.userLogout(action.token, action.server));
  } else {
    yield put(actions.checkPaidFail(response));
  }
}

// ===========================================================

export function* searchInvoiceHistorySaga(action) {
  yield put(actions.searchInvoiceHistoryStart());
  const headers = {
    "X-Auth-Token": action.token,
    "Content-type": "application/json"
  };
  const response = yield POST(
    `${action.server}api/monetizing/invoice/history`,
    action.req,
    headers
  );
  if (response && response === 503) {
    yield put(actions.serverErrorSuccess(response));
  } else if (response && response.status === 200) {
    yield put(actions.searchInvoiceHistorySuccess(response.data));
  } else if (response && (response.status === 403 || response.status === 401)) {
    yield put(actions.userLogout(action.token, action.server));
  } else {
    yield put(actions.searchInvoiceHistoryFail(response));
  }
}

// ===========================================================

export function* getInvoiceByIdSaga(action) {
  yield put(actions.getInvoiceByIdStart());
  const headers = {
    "X-Auth-Token": action.token,
    "Content-type": "application/json"
  };
  const response = yield GET(
    `${action.server}api/monetizing/invoice/${action.id}`,
    headers
  );
  if (response && response === 503) {
    yield put(actions.serverErrorSuccess(response));
  } else if (response && response.status === 200) {
    yield put(actions.getInvoiceByIdSuccess(response.data));
  } else if (response && (response.status === 403 || response.status === 401)) {
    yield put(actions.userLogout(action.token, action.server));
  } else {
    yield put(actions.getInvoiceByIdFail(response));
  }
}
