import { put, cancelled } from "redux-saga/effects";
import * as actions from '../actions/index'
import { POST } from '../callApiWrapper'

export function* getMyNotificationSaga({ token, action, server }) {

  try {
    console.log(server, action, token)
    yield put(actions.getMyNotificationStart())
    const headers = {
      'X-Auth-Token': token,
      'Content-type': 'application/json'
    }
    let date = new Date()
    date.setDate(date.getDate() - 1)
    console.log('date', date.getDate() - 1)

    const data = {
      pageModel: {
        orders: [
          {
            dir: 'ASC',
            property: 'string'
          }
        ],
        pageNumber: action.page ? action.page : 0,
        pageSize: 10
      },
      searchModel: {
        unreadOnly: action.unreadOnly ? true : false,
        fromDate: action.fromDate ? date : null
      }
    }

    const response = yield POST(
      `${server}api/notifications/search`,
      data,
      headers
    )
    console.log('response', response)
    if (response && response === 503) {
      yield put(actions.serverErrorSuccess(response))
    } else if (response && response.status === 200) {
      yield put(actions.getMyNotificationSuccess(response.data))
    } else if (response && (response.status === 403 || response.status === 401)) {
      yield put(actions.userLogout(token, server))
    } else {
      yield put(actions.getMyNotificationFail(response))
    }
  } finally {
    if (yield cancelled()) yield put(actions.getMyNotificationCancel());
  }
}

// export function * markAllNotificationAsReadSaga(action) {
//   yield put(actions.getMyProfileStart())
//   const headers = {
//     'X-Auth-Token': action.token,
//     'Content-type': 'application/json'
//   }
//   const response = yield POST(
//     `${action.server}api/notifications/markAllRead`,
//     {},
//     headers
//   )
//   if (response && response === 503) {
//     yield put(actions.serverErrorSuccess(response))
//   } else if (response && response.status === 200) {
//     yield put(actions.getMyProfileSuccess(response.data))
//   } else if (response && (response.status === 403 || response.status === 401)) {
//     yield put(actions.userLogout(action.token, action.server))
//   } else {
//     yield put(actions.getMyProfileFail(response))
//   }
// }
