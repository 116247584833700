import { put } from "redux-saga/effects";
import * as actions from "../actions/index";
import { POST } from "../callApiWrapper";

export function* addChatSaga(action) {
  yield put(actions.addChatStart());
  const headers = {
    "X-Auth-Token": action.token,
    "Content-type": "application/json"
  };
  const response = yield POST(
    `${action.server}api/vume/eventChat`,
    action.object,
    headers
  );

  if (response && response === 503) {
    yield put(actions.serverErrorSuccess(response));
  } else if (response && response === 451) {
    yield put(actions.permissionErrorSuccess(Math.random()));
  } else if (response && response.status === 200) {
    yield put(actions.addChatSuccess(response.data));
  } else if ((response && response.status === 403) || response.status === 401) {
    yield put(actions.userLogout(action.token, action.server));
  } else {
    yield put(actions.addChatFail(response));
  }
}

// ======================================= SEARCH CHATS

export function* searchChatsSaga(action) {
  yield put(actions.searchChatsStart());
  const headers = {
    "X-Auth-Token": action.token,
    "Content-type": "application/json"
  };
  const data = {
    pageModel: {
      orders: [
        {
          dir: action.object.order,
          property: "id"
        }
      ],
      pageNumber: action.object.pageNumber,
      pageSize: action.object.pageSize
    },
    searchModel: {
      eventId: action.object.eventId,
      timeStart: action.object.timeStart,
      timeEnd: action.object.timeEnd
    }
  };

  const response = yield POST(
    `${action.server}api/vume/eventChat/search`,
    data,
    headers
  );
  if (response && response === 503) {
    yield put(actions.serverErrorSuccess(response));
  } else if (response && response.status === 200) {
    yield put(actions.searchChatsSuccess(response.data));
  } else if (response && (response.status === 403 || response.status === 401)) {
    yield put(actions.userLogout(action.token, action.server));
  } else {
    yield put(actions.searchChatsFail(response));
  }
}
