import * as actionTypes from "../actions/actionTypes";

const initialState = {
  events: null,
  homeEvents: null,
  searchedEvents: null,
  event: null,
  eventStatus: null,
  loading: false,
  topEventsLoading: false,
  error: null,
  myBroadcastError: null,
  searchError: null,
  response: null,
  myBroadcast: null,
  userProfileEvents: null,
  userProfileEventsError: null,
  bindedResponse: null,
  topEvents: null,
  eventInfo: null,
  categories: null,
  latestEvents: null,
  ownerInvites: null,
  inviteLoading: false
};

const getEventsStart = (state, action) => {
  const newState = {
    ...state,
    loading: true,
    homeEvents: null
  };

  return newState;
};
const getEventsCancel = state => {
  const newState = {
    ...state,
    events: null,
    homeEvents: null,
    searchedEvents: null,
    latestEvents: null,
    topEvents: null,
    loading: false
  };

  return newState;
};
const getEventsClear = state => {
  const newState = {
    ...state,
    events: null,
    homeEvents: null,
    searchedEvents: null,
    latestEvents: null,
    topEvents: null,
    loading: false
  };

  return newState;
};

const getEventsSuccess = (state, action) => {
  const newState = {
    ...state,
    loading: false,
    events: action.events,
    homeEvents: action.events,
    error: null
  };
  return newState;
};

const getEventsFail = (state, action) => {
  const newState = {
    ...state,
    loading: false,
    events: null,
    homeEvents: null,
    error: action.error
  };
  return newState;
};

// ==============

const userLastEventsStart = (state, action) => {
  const newState = {
    ...state,
    loading: true,
    events: null,
    homeEvents: null
  };

  return newState;
};
const userLastEventsSuccess = (state, action) => {
  const newState = {
    ...state,
    loading: false,
    events: action.events,
    homeEvents: action.events,
    error: null
  };
  return newState;
};

const userLastEventsFail = (state, action) => {
  const newState = {
    ...state,
    loading: false,
    events: null,
    homeEvents: null,
    error: action.error
  };
  return newState;
};
// ==============

const searchEventsStart = (state, action) => {
  const newState = {
    ...state,
    loading: true,
    searchedEvents: null
  };
  return newState;
};
const searchEventsSuccess = (state, action) => {
  const newState = {
    ...state,
    loading: false,
    searchedEvents: action.searchedEvents,
    searchError: null
  };
  return newState;
};

const searchEventsFail = (state, action) => {
  const newState = {
    ...state,
    loading: false,
    searchedEvents: null,
    searchError: action.error
  };
  return newState;
};

// ============== TOP EVENTS

const searchTopEventsStart = (state, action) => {
  const newState = {
    ...state,
    topEventsLoading: true,
    topEvents: null
  };
  return newState;
};
const searchTopEventsSuccess = (state, action) => {
  const newState = {
    ...state,
    topEventsLoading: false,
    topEvents: action.topEvents,
    searchError: null
  };
  return newState;
};

const searchTopEventsFail = (state, action) => {
  const newState = {
    ...state,
    topEventsLoading: false,
    topEvents: null,
    searchError: action.error
  };
  return newState;
};

// ==============

const userProfileEventsStart = (state, action) => {
  const newState = {
    ...state,
    loading: true
  };
  return newState;
};
const userProfileEventsSuccess = (state, action) => {
  const newState = {
    ...state,
    loading: false,
    userProfileEvents: action.userProfileEvents,
    userProfileEventsError: null
  };
  return newState;
};

const userProfileEventsFail = (state, action) => {
  const newState = {
    ...state,
    loading: false,
    userProfileEvents: null,
    userProfileEventsError: action.error
  };
  return newState;
};
const userProfileEventsCancel = (state, action) => {
  const newState = {
    ...state,
    loading: false,
    userProfileEvents: null
  };
  return newState;
};

// =========================================================================== MY BROADCAST

const getMyBroadcastStart = (state, action) => {
  const newState = {
    ...state,
    loading: true,
    myBroadcast: null
  };

  return newState;
};
const getMyBroadcastSuccess = (state, action) => {
  const newState = {
    ...state,
    loading: false,
    myBroadcast: action.myBroadcast,
    myBroadcastError: null
  };
  return newState;
};

const getMyBroadcastFail = (state, action) => {
  const newState = {
    ...state,
    loading: false,
    myBroadcast: null,
    myBroadcastError: action.error
  };
  return newState;
};

// ======================================================================== BY ID

const getEventByIdStart = (state, action) => {
  const newState = {
    ...state,
    event: null,
    loading: true,
    eventStatus: null
  };
  return newState;
};
const getEventByIdSuccess = (state, action) => {
  const newState = {
    ...state,
    loading: false,
    event: action.event,
    eventStatus: null,
    error: null
  };
  return newState;
};

const getEventByIdFail = (state, action) => {
  const newState = {
    ...state,
    loading: false,
    event: null,
    eventStatus: null,
    error: action.error
  };
  return newState;
};

// ======================================================================== CHECK ID

const checkEventByIdStart = (state, action) => {
  const newState = {
    ...state
  };
  return newState;
};
const checkEventByIdSuccess = (state, action) => {
  const newState = {
    ...state,
    event: action.event
  };
  return newState;
};

const checkEventByIdFail = (state, action) => {
  const newState = {
    ...state,
    error: action.error
  };
  return newState;
};

// ======================================================================== Create

const createEventStart = (state, action) => {
  const newState = {
    ...state,
    event: null,
    loading: true
  };
  return newState;
};
const createEventSuccess = (state, action) => {
  const newState = {
    ...state,
    loading: false,
    event: action.event,
    error: null
  };
  return newState;
};

const createEventFail = (state, action) => {
  const newState = {
    ...state,
    loading: false,
    event: null,
    error: action.error
  };
  return newState;
};

// ======================================================================== Create AND START

const createAndStartEventStart = (state, action) => {
  const newState = {
    ...state,
    event: null,
    loading: true
  };
  return newState;
};
const createAndStartEventSuccess = (state, action) => {
  const newState = {
    ...state,
    loading: false,
    event: action.event,
    error: null
  };
  return newState;
};

const createAndStartEventFail = (state, action) => {
  const newState = {
    ...state,
    loading: false,
    event: null,
    error: action.error
  };
  return newState;
};


// ======================================================================== Create AND Preview

const createAndPreviewEventStart = (state, action) => {
  const newState = {
    ...state,
    event: null,
    loading: true
  };
  return newState;
};
const createAndPreviewEventSuccess = (state, action) => {
  const newState = {
    ...state,
    loading: false,
    event: action.event,
    error: null
  };
  return newState;
};

const createAndPreviewEventFail = (state, action) => {
  const newState = {
    ...state,
    loading: false,
    event: null,
    error: action.error
  };
  return newState;
};

// ======================================================================== DELETE

const deleteEventStart = (state, action) => {
  const newState = {
    ...state,
    response: null,
    loading: false
  };
  return newState;
};
const deleteEventSuccess = (state, action) => {
  const newState = {
    ...state,
    loading: false,
    response: action.response,
    error: null
  };
  return newState;
};

const deleteEventFail = (state, action) => {
  const newState = {
    ...state,
    loading: false,
    response: null,
    error: action.error
  };
  return newState;
};

// ======================================================================== EDIT

const editEventStart = (state, action) => {
  const newState = {
    ...state,
    loading: true
  };
  return newState;
};
const editEventSuccess = (state, action) => {
  const newState = {
    ...state,
    loading: false,
    event: action.event,
    error: null
  };
  return newState;
};

const editEventFail = (state, action) => {
  const newState = {
    ...state,
    loading: false,
    error: action.error
  };
  return newState;
};

// ======================================================================== EDIT INFO

const editEventInfoStart = (state, action) => {
  const newState = {
    ...state,
    loading: true
  };
  return newState;
};
const editEventInfoSuccess = (state, action) => {
  const newState = {
    ...state,
    loading: false,
    eventInfo: action.eventInfo,
    error: null
  };
  return newState;
};

const editEventInfoFail = (state, action) => {
  const newState = {
    ...state,
    loading: false,
    error: action.error
  };
  return newState;
};

// ======================================================================== CHANGE STATE

const eventChangeStateStart = (state, action) => {
  const newState = {
    ...state,
    loading: false,
    eventStatus: null
  };
  return newState;
};
const eventChangeStateSuccess = (state, action) => {
  const newState = {
    ...state,
    eventStatus: action.eventStatus,
    loading: false,
    error: null
  };
  return newState;
};

const eventChangeStateFail = (state, action) => {
  const newState = {
    ...state,
    eventStatus: null,
    loading: false,
    error: action.error
  };
  return newState;
};

// ======================================================================== JOIN ON EVENT

const joinOnEventStart = (state, action) => {
  const newState = {
    ...state,
    event: null,
    loading: true
  };
  return newState;
};
const joinOnEventSuccess = (state, action) => {
  const newState = {
    ...state,
    loading: false,
    event: action.event,
    error: null
  };
  return newState;
};

const joinOnEventFail = (state, action) => {
  const newState = {
    ...state,
    loading: false,
    event: null,
    error: action.error
  };
  return newState;
};
// ======================================================================== LEAVE EVENT

const leaveEventStart = (state, action) => {
  const newState = {
    ...state,
    loading: false,
    response: null
  };
  return newState;
};
const leaveEventSuccess = (state, action) => {
  const newState = {
    ...state,
    response: action.response,
    loading: false,
    error: null
  };
  return newState;
};

const leaveEventFail = (state, action) => {
  const newState = {
    ...state,
    response: null,
    loading: false,
    error: action.error
  };
  return newState;
};

// ======================================================================== BIND EVENT STREAM

const bindEventStreamStart = (state, action) => {
  const newState = {
    ...state,
    bindedResponse: null,
    error: null
  };
  return newState;
};
const bindEventStreamSuccess = (state, action) => {
  const newState = {
    ...state,
    bindedResponse: action.response,
    error: null
  };
  return newState;
};

const bindEventStreamFail = (state, action) => {
  const newState = {
    ...state,
    bindedResponse: null,
    error: action.error
  };
  return newState;
};

// ======================================================================== CATEGORIES
const getAllCategoriesStart = state => {
  const newState = {
    ...state
  };
  return newState;
};
const getAllCategoriesSuccess = (state, action) => {
  const newState = {
    ...state,
    categories: action.categories
  };
  return newState;
};

const getAllCategoriesFail = (state, action) => {
  const newState = {
    ...state
  };
  return newState;
};

// ==============
const latestEventsCategoryStart = state => {
  const newState = {
    ...state
  };
  return newState;
};
const latestEventsCategorySuccess = (state, action) => {
  const newState = {
    ...state,
    latestEvents: action.latestEvents
  };
  return newState;
};

const latestEventsCategoryFail = (state, action) => {
  const newState = {
    ...state
  };
  return newState;
};

// ==============
const addOwnerInvitationStart = state => {
  const newState = {
    ...state,
    inviteLoading: true
  };
  return newState;
};
const addOwnerInvitationSuccess = (state, action) => {
  const newState = {
    ...state,
    ownerInvites: action.ownerInvites,
    inviteLoading: false,
    error: null
  };
  return newState;
};

const addOwnerInvitationFail = (state, action) => {
  const newState = {
    ...state,
    ownerInvites: null,
    inviteLoading: false,
    error: action.error
  };
  return newState;
};

// ==============
const deleteOwnerInvitationStart = state => {
  const newState = {
    ...state,
    inviteLoading: true
  };
  return newState;
};
const deleteOwnerInvitationSuccess = (state, action) => {
  const newState = {
    ...state,
    ownerInvites: action.ownerInvites,
    inviteLoading: false,
    error: null
  };
  return newState;
};

const deleteOwnerInvitationFail = (state, action) => {
  const newState = {
    ...state,
    ownerInvites: null,
    inviteLoading: false,
    error: action.error
  };
  return newState;
};

// ======================================================================== HIDE/UNHIDE EVENTS

const toggleHideEventStart = function toggleHideEventStart(state, action) {

  const newState = {
    ...state,
    response: null,
    loading: true // Note that this should be true, indicating that loading has started.
  };
  return newState;

};

const toggleHideEventSuccess = function toggleHideEventSuccess(state, action) {
  const newState = {
    ...state,
    loading: false,
    response: action.response,
    error: null
  };
  return newState;
  // Optionally, you can update the event's hidden state here as well.
  // if (newState.events && action.eventId) {
  //   newState.events = newState.events.map(event => {
  //     if (event.id === action.eventId) {
  //       return _extends({}, event, { hidden: !event.hidden });
  //     }
  //     return event;
  //   });
  // }
};

const toggleHideEventFail = function toggleHideEventFail(state, action) {
  const newState = {
    ...state,
    loading: false,
    response: null,
    error: action.error
  };
  return newState;
};

// ====

export const eventsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_EVENTS_START:
      return getEventsStart(state, action);
    case actionTypes.GET_EVENTS_SUCCESS:
      return getEventsSuccess(state, action);
    case actionTypes.GET_EVENTS_FAIL:
      return getEventsFail(state, action);
    case actionTypes.GET_EVENTS_CANCEL:
      return getEventsCancel(state);
    case actionTypes.GET_EVENTS_CLEAR:
      return getEventsClear(state);
    case actionTypes.USER_LAST_EVENTS_START:
      return userLastEventsStart(state, action);
    case actionTypes.USER_LAST_EVENTS_SUCCESS:
      return userLastEventsSuccess(state, action);
    case actionTypes.USER_LAST_EVENTS_FAIL:
      return userLastEventsFail(state, action);

    case actionTypes.SEARCH_EVENTS_START:
      return searchEventsStart(state, action);
    case actionTypes.SEARCH_EVENTS_SUCCESS:
      return searchEventsSuccess(state, action);
    case actionTypes.SEARCH_EVENTS_FAIL:
      return searchEventsFail(state, action);

    case actionTypes.SEARCH_TOP_EVENTS_START:
      return searchTopEventsStart(state, action);
    case actionTypes.SEARCH_TOP_EVENTS_SUCCESS:
      return searchTopEventsSuccess(state, action);
    case actionTypes.SEARCH_TOP_EVENTS_FAIL:
      return searchTopEventsFail(state, action);

    case actionTypes.USER_PROFILE_EVENTS_START:
      return userProfileEventsStart(state, action);
    case actionTypes.USER_PROFILE_EVENTS_SUCCESS:
      return userProfileEventsSuccess(state, action);
    case actionTypes.USER_PROFILE_EVENTS_FAIL:
      return userProfileEventsFail(state, action);
    case actionTypes.GET_USER_EVENTS_CANCEL:
      return userProfileEventsCancel(state, action);
    case actionTypes.GET_EVENT_BY_ID_START:
      return getEventByIdStart(state, action);
    case actionTypes.GET_EVENT_BY_ID_SUCCESS:
      return getEventByIdSuccess(state, action);
    case actionTypes.GET_EVENT_BY_ID_FAIL:
      return getEventByIdFail(state, action);

    case actionTypes.CHECK_EVENT_BY_ID_START:
      return checkEventByIdStart(state, action);
    case actionTypes.CHECK_EVENT_BY_ID_SUCCESS:
      return checkEventByIdSuccess(state, action);
    case actionTypes.CHECK_EVENT_BY_ID_FAIL:
      return checkEventByIdFail(state, action);

    case actionTypes.GET_MY_BROADCAST_START:
      return getMyBroadcastStart(state, action);
    case actionTypes.GET_MY_BROADCAST_SUCCESS:
      return getMyBroadcastSuccess(state, action);
    case actionTypes.GET_MY_BROADCAST_FAIL:
      return getMyBroadcastFail(state, action);

    case actionTypes.CREATE_EVENT_START:
      return createEventStart(state, action);
    case actionTypes.CREATE_EVENT_SUCCESS:
      return createEventSuccess(state, action);
    case actionTypes.CREATE_EVENT_FAIL:
      return createEventFail(state, action);

    case actionTypes.CREATE_AND_START_EVENT_START:
      return createAndStartEventStart(state, action);
    case actionTypes.CREATE_AND_START_EVENT_SUCCESS:
      return createAndStartEventSuccess(state, action);
    case actionTypes.CREATE_AND_START_EVENT_FAIL:
      return createAndStartEventFail(state, action);

    case actionTypes.CREATE_AND_PREVIEW_EVENT_START:
      return createAndPreviewEventStart(state, action);
    case actionTypes.CREATE_AND_PREVIEW_EVENT_SUCCESS:
      return createAndPreviewEventSuccess(state, action);
    case actionTypes.CREATE_AND_PREVIEW_EVENT_FAIL:
      return createAndPreviewEventFail(state, action);

    case actionTypes.EDIT_EVENT_START:
      return editEventStart(state, action);
    case actionTypes.EDIT_EVENT_SUCCESS:
      return editEventSuccess(state, action);
    case actionTypes.EDIT_EVENT_FAIL:
      return editEventFail(state, action);

    case actionTypes.EDIT_EVENT_INFO_START:
      return editEventInfoStart(state, action);
    case actionTypes.EDIT_EVENT_INFO_SUCCESS:
      return editEventInfoSuccess(state, action);
    case actionTypes.EDIT_EVENT_INFO_FAIL:
      return editEventInfoFail(state, action);

    case actionTypes.DELETE_EVENTS_START:
      return deleteEventStart(state, action);
    case actionTypes.DELETE_EVENTS_SUCCESS:
      return deleteEventSuccess(state, action);
    case actionTypes.DELETE_EVENTS_FAIL:
      return deleteEventFail(state, action);

    case actionTypes.EVENT_CHANGE_STATE_START:
      return eventChangeStateStart(state, action);
    case actionTypes.EVENT_CHANGE_STATE_SUCCESS:
      return eventChangeStateSuccess(state, action);
    case actionTypes.EVENT_CHANGE_STATE_FAIL:
      return eventChangeStateFail(state, action);

    case actionTypes.JOIN_ON_EVENT_START:
      return joinOnEventStart(state, action);
    case actionTypes.JOIN_ON_EVENT_SUCCESS:
      return joinOnEventSuccess(state, action);
    case actionTypes.JOIN_ON_EVENT_FAIL:
      return joinOnEventFail(state, action);

    case actionTypes.LEAVE_EVENT_START:
      return leaveEventStart(state, action);
    case actionTypes.LEAVE_EVENT_SUCCESS:
      return leaveEventSuccess(state, action);
    case actionTypes.LEAVE_EVENT_FAIL:
      return leaveEventFail(state, action);

    case actionTypes.BIND_EVENT_STREAM_START:
      return bindEventStreamStart(state, action);
    case actionTypes.BIND_EVENT_STREAM_SUCCESS:
      return bindEventStreamSuccess(state, action);
    case actionTypes.BIND_EVENT_STREAM_FAIL:
      return bindEventStreamFail(state, action);

    case actionTypes.GET_ALL_CATEGORIES_START:
      return getAllCategoriesStart(state);
    case actionTypes.GET_ALL_CATEGORIES_SUCCESS:
      return getAllCategoriesSuccess(state, action);
    case actionTypes.GET_ALL_CATEGORIES_FAIL:
      return getAllCategoriesFail(state, action);

    case actionTypes.LATEST_EVENTS_CATEGORY_START:
      return latestEventsCategoryStart(state);
    case actionTypes.LATEST_EVENTS_CATEGORY_SUCCESS:
      return latestEventsCategorySuccess(state, action);
    case actionTypes.LATEST_EVENTS_CATEGORY_FAIL:
      return latestEventsCategoryFail(state, action);

    case actionTypes.ADD_OWNER_INVITATION_START:
      return addOwnerInvitationStart(state);
    case actionTypes.ADD_OWNER_INVITATION_SUCCESS:
      return addOwnerInvitationSuccess(state, action);
    case actionTypes.ADD_OWNER_INVITATION_FAIL:
      return addOwnerInvitationFail(state, action);

    case actionTypes.DELETE_OWNER_INVITATION_START:
      return deleteOwnerInvitationStart(state);
    case actionTypes.DELETE_OWNER_INVITATION_SUCCESS:
      return deleteOwnerInvitationSuccess(state, action);
    case actionTypes.DELETE_OWNER_INVITATION_FAIL:
      return deleteOwnerInvitationFail(state, action);
    case actionTypes.TOGGLE_HIDE_EVENT_START:
      return toggleHideEventStart(state, action);
    case actionTypes.TOGGLE_HIDE_EVENT_SUCCESS:
      return toggleHideEventSuccess(state, action);
    case actionTypes.TOGGLE_HIDE_EVENT_FAIL:
      return toggleHideEventFail(state, action);
    default:
      return state;
  }
};
