import { put } from "redux-saga/effects";
import * as actions from "../actions/index";
import { PUT, GET } from "../callApiWrapper";

export function* getMyPlanSaga(action) {
  yield put(actions.getMyPlanStart());
  const headers = {
    "X-Auth-Token": action.token,
    "Content-type": "application/json"
  };
  const response = yield GET(
    `${action.server}api/vume/planing/userPlan`,
    headers
  );
  if (response && response === 503) {
    yield put(actions.serverErrorSuccess(response));
  } else if (response && response.status === 200) {
    yield put(actions.getMyPlanSuccess(response.data));
  } else if (response && (response.status === 403 || response.status === 401)) {
    yield put(actions.userLogout(action.token, action.server));
  } else {
    yield put(actions.getMyPlanFail(response));
  }
}

// ====================================================

export function* getUserPlanByIdSaga(action) {
  yield put(actions.getUserPlanByIdStart());
  const headers = {
    "X-Auth-Token": action.token,
    "Content-type": "application/json"
  };
  const response = yield GET(
    `${action.server}api/vume/planing/userPlan/${action.id}`,
    headers
  );
  if (response && response === 503) {
    yield put(actions.serverErrorSuccess(response));
  } else if (response && response.status === 200) {
    yield put(actions.getUserPlanByIdSuccess(response.data));
  } else if (response && (response.status === 403 || response.status === 401)) {
    yield put(actions.userLogout(action.token, action.server));
  } else {
    yield put(actions.getUserPlanByIdFail(response));
  }
}

// ======================================

export function* setFreePremiumSaga(action) {
  yield put(actions.setFreePremiumStart());
  const headers = {
    "X-Auth-Token": action.token,
    "content-type": "application/json"
  };
  const response = yield PUT(
    `${action.server}api/vume/planing/userPlan/setFreePremium/${action.promotionCode}`,
    null,
    headers
  );
  if (response && response === 503) {
    yield put(actions.serverErrorSuccess(response));
  } else if (response && response.status === 200) {
    yield put(actions.setFreePremiumSuccess(response));
  } else if (response && (response.status === 403 || response.status === 401)) {
    yield put(actions.userLogout(action.token, action.server));
  } else {
    yield put(actions.setFreePremiumFail(response));
  }
}

export function* assignPlanSaga(action) {
  yield put(actions.assignPlanStart());
  const headers = {
    "content-type": "application/json",
    "X-Auth-Token": action.token
  };

  const response = yield PUT(
    `${action.server}api/vume/planing/userPlan`,
    action.data,
    headers
  );
  if (response && response === 503) {
    yield put(actions.serverErrorSuccess(response));
  }
  if (response && response.status === 200) {
    yield put(actions.assignPlanSuccess(response.data));
  } else if (response && (response.status === 403 || response.status === 401)) {
    yield put(actions.userLogout(action.token, action.server));
  } else {
    yield put(actions.assignPlanFail(response));
  }
}
