import * as actionTypes from "../actions/actionTypes";

const initialState = {
  balance: null,
  summary:null,
  loading: false,
  response: null,
  packages: null,
  history: null
};

const getViubitBalanceStart = state => {
  const newState = {
    ...state,
    loading: true
  };
  return newState;
};
const getViubitBalanceSuccess = (state, action) => {
  const newState = {
    ...state,
    balance: action.balance,
    loading: false,
    error: null
  };
  return newState;
};

const getViubitBalanceFail = (state, action) => {
  const newState = {
    ...state,
    error: action.error,
    loading: false
  };
  return newState;
};

// ================

const sendGiftStart = state => {
  const newState = {
    ...state,
    loading: true,
    response: null
  };
  return newState;
};
const sendGiftSuccess = (state, action) => {
  const newState = {
    ...state,
    response: action.response,
    loading: false,
    error: null
  };
  return newState;
};

const sendGiftFail = (state, action) => {
  const newState = {
    ...state,
    error: action.error,
    loading: false,
    response: null
  };
  return newState;
};

// ================

const getAllViubitPackagesStart = state => {
  const newState = {
    ...state,
    loading: true,
    packages: null
  };
  return newState;
};
const getAllViubitPackagesSuccess = (state, action) => {
  const newState = {
    ...state,
    packages: action.packages,
    loading: false,
    error: null
  };
  return newState;
};

const getAllViubitPackagesFail = (state, action) => {
  const newState = {
    ...state,
    error: action.error,
    loading: false,
    packages: null
  };
  return newState;
};

// ================

const searchViubitHistoryStart = state => {
  const newState = {
    ...state,
    loading: true,
    history: null
  };
  return newState;
};
const searchViubitHistorySuccess = (state, action) => {
  const newState = {
    ...state,
    history: action.history,
    loading: false,
    error: null
  };
  return newState;
};

const searchViubitHistoryFail = (state, action) => {
  const newState = {
    ...state,
    error: action.error,
    loading: false,
    history: null
  };
  return newState;
};

//===============

const getViuBitEventSummaryStart = (state) => {
  const newState = {
    ...state,
    loading: true,
    summary: null
  };
  return newState;
};

const getViuBitEventSummarySuccess = (state, action) => {
  const newState = {
    ...state,
    summary: action.summary,
    loading: false,
    error: null
  };
  return newState;
};

const getViuBitEventSummaryFail = (state, action) => {
  const newState = {
    ...state,
    error: action.error,
    loading: false,
    summary: null
  };
  return newState;
};


export const viubitReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_VIUBIT_BALANCE_START:
      return getViubitBalanceStart(state);
    case actionTypes.GET_VIUBIT_BALANCE_SUCCESS:
      return getViubitBalanceSuccess(state, action);
    case actionTypes.GET_APP_VERSION_STATUS_FAIL:
      return getViubitBalanceFail(state, action);

    case actionTypes.SEND_GIFT_START:
      return sendGiftStart(state);
    case actionTypes.SEND_GIFT_SUCCESS:
      return sendGiftSuccess(state, action);
    case actionTypes.SEND_GIFT_FAIL:
      return sendGiftFail(state, action);

    case actionTypes.GET_ALL_VIUBIT_PACKAGES_START:
      return getAllViubitPackagesStart(state);
    case actionTypes.GET_ALL_VIUBIT_PACKAGES_SUCCESS:
      return getAllViubitPackagesSuccess(state, action);
    case actionTypes.GET_ALL_VIUBIT_PACKAGES_FAIL:
      return getAllViubitPackagesFail(state, action);

    case actionTypes.SEARCH_VIUBIT_HISTORY_START:
      return searchViubitHistoryStart(state);
    case actionTypes.SEARCH_VIUBIT_HISTORY_SUCCESS:
      return searchViubitHistorySuccess(state, action);
    case actionTypes.SEARCH_VIUBIT_HISTORY_FAIL:
      return searchViubitHistoryFail(state, action);

    case actionTypes.GET_VIUBIT_EVENT_SUMMARY_START:
      return getViuBitEventSummaryStart(state);
    case actionTypes.GET_VIUBIT_EVENT_SUMMARY_SUCCESS:
      return getViuBitEventSummarySuccess(state, action);
    case actionTypes.GET_VIUBIT_EVENT_SUMMARY_FAIL:
      return getViuBitEventSummaryFail(state, action);

    default:
      return state;
  }
};
