import * as actionTypes from "../actions/actionTypes";

const initialState = {
  response: null,
  chats: null,
  loading: false,
  error: null,
};

const addChatStart = state => {
  const newState = {
    ...state,
    loading: true,
    error: null,
    response: null
  };
  return newState;
};
const addChatSuccess = (state, action) => {
  const newState = {
    ...state,
    loading: false,
    response: action.response,
    error: null
  };
  return newState;
};

const addChatFail = (state, action) => {
  const newState = {
    ...state,
    response: null,
    loading: false,
    error: action.error
  };
  return newState;
};

// ==================================== Search chats

const searchChatsStart = state => {
  const newState = {
    ...state,
    loading: true,
    error: null,
    chats: null
  };
  return newState;
};
const searchChatsSuccess = (state, action) => {
  const newState = {
    ...state,
    loading: false,
    chats: action.chats,
    error: null
  };
  return newState;
};

const searchChatsFail = (state, action) => {
  const newState = {
    ...state,
    chats: null,
    loading: false,
    error: action.error
  };
  return newState;
};



export const chatsReducer = (state = initialState, action) => {
    switch (action.type) {
      case actionTypes.ADD_CHAT_START:
        return addChatStart(state);
      case actionTypes.ADD_CHAT_SUCCESS:
        return addChatSuccess(state, action);
      case actionTypes.ADD_CHAT_FAIL:
        return addChatFail(state, action);
  
      case actionTypes.SEARCH_CHATS_START:
        return searchChatsStart(state);
      case actionTypes.SEARCH_CHATS_SUCCESS:
        return searchChatsSuccess(state, action);
      case actionTypes.SEARCH_CHATS_FAIL:
        return searchChatsFail(state, action);

      default:
        return state;
    }
  };
  