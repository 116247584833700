import { texts } from "./texts";
import { success } from "./success";
import { errors } from "./errors";
import { info } from "./info";
import { validations } from "./validations";
import { tooltips } from "./tooltips";

export const en = {
  text: texts,
  success: success,
  errors: errors,
  info: info,
  validations: validations,
  tooltips: tooltips
};
