import * as actionTypes from "../actions/actionTypes";
const initialState = {
  response: null,
  permissionError: null
};

const serverErrorSuccess = (state, action) => {
  const newState = {
    ...state,
    response: action.response
  };
  return newState;
};

const permissionErrorSuccess = (state, action) => {
  const newState = {
    ...state,
    permissionError: action.permissionError
  };
  return newState;
};

export const errorHandlerReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SERVER_ERROR_SUCCESS:
      return serverErrorSuccess(state, action);
    case actionTypes.PERMISSION_ERROR_SUCCESS:
      return permissionErrorSuccess(state, action);
    default:
      return state;
  }
};
