export const info = {
  enableYoutubeAccount: 'Enable Livestreaming on your Youtube Account',
  ImageUploaderNote:
    "Upload an image that represents the content of your video. A good thumbnail attracts people's attention and encourages them to view your video.",
  accessKey:
    'This event is Private, if you have the password given by the owner enter it here to view it.',
  UnauthorizedBroadcasting:
    'Unauthorized broadcastings are illegal and subject to prosecution',
  shareMessage: 'Hello, check out this new video on ViuLive.',
  processing: 'Processing, please wait…',
  verificationHelp: 'The verification code expires in 5 minutes.',
  verificationExpire: 'Verification code will expires in',
  otpExpired: 'This verification code has expired.',
  cameraLimitation: 'To add more than {{number}} streams',
  liveLimitation:
    'You have reached the maximum number of live videos allowed by your plan.',
  liveLimitationDetail:
    'You already have a Or you can finish your current event.',
  scheduleLimitation: 'Scheduled video limitation.',
  scheduleLimitationDetail:
    'You have reached the maximum number of live videos allowed by your plan.',
  broadcastLimitation:
    'You have reached the broadcast time limit for this session.',
  broadcastLimitationDetail: 'Your event will end automatically.',
  cameraCountLimitation:
    'You have reached the maximum number of streams for this event. ',
  deleteCamera: 'Are you sure you want to delete this stream?',
  deleteCameraDetail:
    'Once deleted, the videos associated with this stream will be removed as well. ',
  disableCamera: 'Are you sure you want to disable the default camera?',
  configRTMP: 'You can get RTMP URLs in the Video section of your profile.',
  finishEvent: 'End live event now?',
  finishEventDetail: 'Are you sure you want to end this live event?',
  startEvent: 'Are you sure you want to start the event now?',
  noCamera:
    'Can’t find your camera, Please check your browser settings to enable camera access.',
  broadcastCrash: 'We apologize for the inconvenience caused.',
  broadcastCrashDetail:
    'Something went wrong. Do you want to report this error?',
  noAnotherCamera: 'You do not have another camera.',
  jumpliveDefault: 'Jump live with Webcam/Mobile',
  jumpliveMobile: 'Jump live with Device camera',
  cancelEvent: 'Are you sure you want to cancel this event?',
  cancelEventDetail: 'After cancel, your event will back to upcoming state.',
  deleteEvent: 'Are you sure you want to delete this session?',
  freeUpgrade: 'Congratulations, you can upgrade for free.',
  haveRTMPCamera:
    "You've added RTMP cameras. Please configure your cameras before broadcasting.",
  setupYi:
    'To set up YI-4K cameras, first enter the username and password for your Wi-Fi connection, and then a QR code will be generated for you.',
  setupOther:
    '* Please set this information in your camera to connect it directly to our server.',
  howToUseYi: 'How to live stream with a YI camera?',
  howToUseOther: 'How to live stream with action cameras?',
  howToUseGoPro: 'How to live stream with GoPro cameras?',
  howToUseMevo: 'How to live stream with Mevo cameras?',
  teamAdmin:
    'In this mode you can set multiple RTMP cameras in this device or your group can join your video event to perform a multi streaming broadcast. Each group member can have multiple cameras. Just start to broadcast right now!',
  teamMember:
    'You can join a video event as a group member. Just get a join code from the group admin and enter it to join the broadcast.',
  playThumbnails:
    'When you start the thumbnails, your available bandwidth will be divided among multiple video displays.',
  reWatch: 'Replay?',
  noStreamBroadcasting: 'You can try another camera angle/position to watch',
  disconnectedCamera: 'The camera is disconnected.',
  streamEnded: "This camera's broadcast has ended!",
  selectAnotherAngle: 'OR select another camera angle/position',
  loginForAccess: 'Please login to continue.',
  accessToLocation: 'You must allow access to Location in order to broadcast.',
  cameraPermission: 'Permission to use your camera',
  cameraPermissionDetail: 'We need your permission to record audio',
  audioRecordPermission: 'Permission to record audio',
  audioRecordPermissionDetail: 'We need your permission to record Audio',
  newBroadcaster: 'A new broadcaster has joined.',
  userJoined: ' has joined this event!',
  areYouSure: 'Are you sure?',
  accountLocked:
    'Your Account Has Been Temporarily Locked, Too many failed login attempts. Please try again later.',
  accountLockedDetail: "To recover your account, click 'Forgot Password.'",
  loginByForm:
    'This email address is already registered. Please log in using this account or use a different email address to register.',
  leaveBroadcastPage: 'The stream will end when you leave this page.',
  stopStreaming: 'End Stream, Are you sure you want to end the stream now?',
  adminPauseStream:
    'You currently have multiple streams active; do you wish to stop them all or only this one?',
  blockUser:
    'The blocked user can no longer access your profile, posts, or events.',
  unblockUser:
    'You and the unblocked user have profiles, posts, and events access.',
  unfollowUser: 'Are you sure you want to unfollow this user?',
  resendTheVerificationCode: 'Resend Verification Code',
  noStreamRecorded:
    'There is no stream recorded for this event. Also, we are unable to play any video less than 10 seconds.',
  between8to30: ' between 8 and 30 characters',
  removeStream: 'Are you sure you want to remove this stream?',
  youtubeConfig: 'You already have a YouTube configuration.',
  youtubeConfigDetail: 'Do you want to keep the current setup?',
  cameraSelectedCount:
    'Selected {{selectedCount}} of {{limitationCount}} streams.',
  multiWebcamDetected:
    'Multiple webcams were detected on your computer. You can choose multiple webcams for broadcasting with multiple cameras.',
  chooseConfiguration: 'Please select a configuration for this camera.',
  waitForChats: 'Any chat messages during live stream will appear here.',
  disableReStreaming: 'Do you want to disable Re-Streaming?',
  signInToChat: 'Sign in to chat',
  notAuthorizedToAccessThisPage:
    'Access denied, You are not authorized to access this page.',
  pageYouVisitedDotExist: 'Sorry, this page does not exist.',
  hashtagNumberLimit: 'of 15 hashtags',
  enterYoutubeStreamingUrl: 'Enter YouTube live-streaming URL here',
  enterYoutubeStreamingKey: 'Enter YouTube live-streaming key here',
  enterFacebookStreamingUrl: 'Enter Facebook live-streaming URL here',
  enterFacebookStreamingKey: 'Enter Facebook live-streaming key here',
  pleaseUseTheMobileAppOrTheWebOnPcForBroadcasting:
    'Can’t broadcast, Please broadcast using the mobile app or the web on a PC.',
  downloadAndroidApp: 'Download Android App',
  downloadIosApp: 'Download iOS App',
  beyondAppsGroup: 'Copyright © 2020 ViuCom a “Beyond Apps Group” company',
  alreadyHaveLiveEvent:
    'You are currently streaming an event; would you like to create a new event?',
  alreadyHaveLiveEventDesc: 'Do you want to resume the current event?',
  alreadyHaveLiveEventSecondDeviceDesc:
    'If you click OK, this device will be added to your current event and you will be multi-streaming.',
  rtmpCamerasWillBeRmoved:
    'All current RTMP stream configurations will be deleted.',
  reStreamPause:
    'Note: You may be able to pause and restart your streams on the ViuLive website, but if you are restreaming to Facebook and YouTube, you will most likely be disconnected after pausing.',
  inviteUserToJoin: 'Are you sure you want to invite {{username}}?',
  disinviteUserToJoin: 'Remove {{username}} from the invitation?',
  inviteLimitation: 'You may only invite {{inviteLength}}  streamers at a time'
}
