import * as actionTypes from "./actionTypes";

export const reportEvent = (token, object, server) => {
  return {
    type: actionTypes.REPORT_EVENT,
    token: token,
    object: object,
    server: server
  };
};

export const reportEventStart = () => {
  return {
    type: actionTypes.REPORT_EVENT_START
  };
};

export const reportEventSuccess = response => {
  return {
    type: actionTypes.REPORT_EVENT_SUCCESS,
    response: response
  };
};

export const reportEventFail = error => {
  return {
    type: actionTypes.REPORT_EVENT_FAIL,
    error: error
  };
};

// ==================================================================
export const getReportEvents = (token, oldReportEvents, searchModel, loadMore, server) => {
  return {
    type: actionTypes.GET_REPORT_EVENT,
    searchModel: searchModel,
    loadMore: loadMore,
    server: server,
    oldReportEvents: oldReportEvents,
    token: token
  };
};

export const getReportEventsStart = loadMore => {
  return {
    type: actionTypes.GET_REPORT_EVENT_START,
    loadMore: loadMore
  };
};

export const getReportEventsSuccess = response => {
  return {
    type: actionTypes.GET_REPORT_EVENT_SUCCESS,
    response: response
  };
};

export const getReportEventsFail = error => {
  return {
    type: actionTypes.GET_REPORT_EVENT_FAIL,
    error: error
  };
};


// ================================================================== 

// WE SHOULD SEPARATE BLOCKING FROM REPORTS

export const blockEvent = (token, id, server) => {
  return {
    type: actionTypes.BLOCK_EVENTS,
    id: id,
    server: server,
    token: token
  };
};

export const blockEventStart = () => {
  return {
    type: actionTypes.BLOCK_EVENTS_START
  };
};

export const blockEventSuccess = response => {
  return {
    type: actionTypes.BLOCK_EVENTS_SUCCESS,
    response: response
  };
};

export const blockEventFail = error => {
  return {
    type: actionTypes.BLOCK_EVENTS_FAIL,
    error: error
  };
};


// =============================== COMMENT REPORT

export const reportComment = (token, object, server) => {
  return {
    type: actionTypes.REPORT_COMMENT,
    token: token,
    object: object,
    server: server
  };
};

export const reportCommentStart = () => {
  return {
    type: actionTypes.REPORT_COMMENT_START
  };
};

export const reportCommentSuccess = response => {
  return {
    type: actionTypes.REPORT_COMMENT_SUCCESS,
    response: response
  };
};

export const reportCommentFail = error => {
  return {
    type: actionTypes.REPORT_COMMENT_FAIL,
    error: error
  };
};

// =========

export const getReportedComments = (token, object, server) => {
  return {
    type: actionTypes.GET_REPORTED_COMMENTS,
    token: token,
    object: object,
    server: server
  };
};

export const getReportedCommentsStart = () => {
  return {
    type: actionTypes.GET_REPORTED_COMMENTS_START
  };
};

export const getReportedCommentsSuccess = reportedComments => {
  return {
    type: actionTypes.GET_REPORTED_COMMENTS_SUCCESS,
    reportedComments: reportedComments
  };
};

export const getReportedCommentsFail = error => {
  return {
    type: actionTypes.GET_REPORTED_COMMENTS_FAIL,
    error: error
  };
};
