export const validations = {
  enterStreamKeyUrl: 'Please enter the stream key and stream URL.',
  enterValidStreamKeyUrl: 'Please enter a valid stream key and stream URL.',
  commentLength: "Comment can't exceed 300 characters ",
  currentPassword: 'Please enter your current password',
  cameraType: 'Please enter your camera position',
  newPassword: 'Please enter your new password',
  enterPassword: 'Please enter a password',
  confirmPassword: 'Please confirm your new password',
  passwordLength: 'Passwords should be between 8-30 characters',
  passwordMatch: 'The password confirmation does not match.',
  enterEmail: 'Please enter your email address',
  validEmail: 'Please enter a valid email address',
  fullname: 'Please enter your full name',
  fullnameMinLength: 'Full name should be at least 3 characters long',
  fullnameLength: 'Full name should be between 3-30 characters',
  biographyLength: 'Biography should be between 1 to 300 characters',
  selectCountry: 'Select Country',
  terms: "I agree to ViuLive's Terms and Conditions.",
  enterTitle: 'Please enter the title',
  enterName: 'Please enter the name',
  titleLength: 'Title should be between 3-200 characters',
  nameLength: 'Name should be between 3-200 characters',
  cameraNameLength: 'Name should be between 3-200 characters',
  customPositionLength: 'Name should be between 3-200 characters',
  enterCameraPosition: 'Please select the camera position!',
  selectBrand: 'Please select a brand',
  descriptionLength: 'Caption should be between 3-300 characters',
  startTime: 'Please select a start time.',
  uploadImage: 'Please upload an image',
  selectGender: 'Please select a gender',
  wifiSSID: 'Please enter wifi ssid',
  wifiPassword: 'Please enter wifi password',
  accessKey: 'Please enter your privacy password.',
  accessKeyLength: 'Privacy password should be between 8-30 characters',
  accessKeyNotValid: 'Enter a valid privacy password',
  configAllScreens: 'configure the screens.',
  configAllWideScreens: 'Please configure all the wide screens',
  configAllMobileScreens: 'Please set up all of the mobile screens.',
  selectCategory: 'Please select a category'
}
