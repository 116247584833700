import React,{Component} from 'react'
import {Form,Icon,Input,Button} from 'antd'
import PropTypes from 'prop-types';
import {Label} from '../../Elements/Label/Label'
import './Login.css'

export class Login extends Component{
    state = {
        username:null,
        password:null
    }
    onChangeHandler = (key,e) =>{
        this.setState({
            [key]:e.target.value
        })
    }
     onSubmit = (e) =>{
        e.preventDefault()
        this.props.onSubmit(this.state)
    }
    render(){
        return(
            <div className={`MSD-Login ${(this.props.className) ? this.props.className : ''}`}>
                {
                    (this.props.logo) ? 
                    <img src={this.props.logo} alt="logo"/>
                    : null
                }
                    {
                        (this.props.signupButton) ?
                        <div>
                            <h3 className="loginTitle">
                                Join with
                            </h3>
                                <this.props.signupButton />
                        </div> : null
                    }
                    {
                    (this.props.socialLogin) ?
                    <div className="MSD-SocialLogin">
                        <Label>OR LOGIN WITH</Label>
                            {
                                (this.props.socialLogin.facebook) ? 
                                <Button className="facebook">
                                    <Icon type="facebook" theme="filled" />
                                    FACEBOOK
                                </Button> : null
                            }
                            {
                                (this.props.socialLogin.google) ?
                                <Button className="gplus">
                                    <Icon type="google-plus" />
                                    GOOGLE
                                </Button> : null
                            }
                        
                        
                    </div> : null
    
                }
                <h3 className="loginTitle">
                    Login
                </h3>
                <Form onSubmit={(e) => this.onSubmit(e)}>
                    <Label>{(this.props.texts) ? this.props.texts.username : "USERNAME *"} </Label>
                    <Form.Item>
                        <Input 
                            placeholder={(this.props.texts) ? this.props.texts.username : "Username"}
                            type="text"
                            onChange={(e) => this.onChangeHandler("username",e)}
                        />
                    </Form.Item>
                    <Label>{(this.props.texts) ? this.props.texts.password : "PASSWORD *"} </Label>
                    <Form.Item>
                        <Input 
                            placeholder={(this.props.texts) ? this.props.texts.password : "Password"}
                            type="password"
                            onChange={(e) =>  this.onChangeHandler("password",e)}
                        />
                    </Form.Item>
                    <div className="loginFormBtn">
                            <Form.Item>
                                <Button htmlType="submit"  onClick={(e) => this.onSubmit(e)}>
                                    {(this.props.loginButtonIcon) ? <this.props.loginButtonIcon /> : null}
                                    {(this.props.texts) ? this.props.texts.login : "LOGIN"}
                                </Button>
                            </Form.Item>
                    </div>
                </Form>

    
            </div>
        )
    }
}



Login.propTypes = {
    logo:PropTypes.string,
    inputChangeHandler:PropTypes.func,
    onSubmit:PropTypes.func,
  }