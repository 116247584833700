import { put } from "redux-saga/effects";
import * as actions from "../actions/index";
import { POST, GET, PUT, DELETE } from "../callApiWrapper";

export function* getSearchRolesSaga(action) {
  yield put(actions.getSearchRolesStart());
  let data = {
    pageModel: {
      orders: [
        {
          dir: action.searchModel.orderDir
            ? action.searchModel.orderDir
            : action.searchModel.order,
          property: action.searchModel.orderProperty
            ? action.searchModel.orderProperty
            : "id"
        }
      ],
      pageNumber: action.searchModel.pageNumber,
      pageSize: action.searchModel.pageSize
    },
    searchModel: {
      title: action.searchModel.title ? action.searchModel.title : ""
    }
  };
  const headers = {
    "content-type": "application/json",
    "X-Auth-Token": action.token
  };

  const response = yield POST(`${action.server}api/role/search`, data, headers);
  if (response && response === 503) {
    yield put(actions.serverErrorSuccess(response));
  }
  if (response && response.status === 200) {
    yield put(actions.getSearchRolesSuccess(response.data));
  } else if (response && (response.status === 403 || response.status === 401)) {
    yield put(actions.userLogout(action.token, action.server));
  } else {
    yield put(actions.getSearchRolesFail(response));
  }
}

//   ============================== CALL SEARCH ROLES

export function* getRoleByIdSaga(action) {
  yield put(actions.getRoleByIdStart());
  const headers = {
    "content-type": "application/json",
    "X-Auth-Token": action.token
  };

  const response = yield GET(`${action.server}api/role/${action.id}`, headers);
  if (response && response === 503) {
    yield put(actions.serverErrorSuccess(response));
  }
  if (response && response.status === 200) {
    yield put(actions.getRoleByIdSuccess(response.data));
  } else if (response && (response.status === 403 || response.status === 401)) {
    yield put(actions.userLogout(action.token, action.server));
  } else {
    yield put(actions.getRoleByIdFail(response));
  }
}

//   ============================== CALL ROLE BY ID

export function* addRoleSaga(action) {
  yield put(actions.addRoleStart());
  const headers = {
    "content-type": "application/json",
    "X-Auth-Token": action.token
  };
  const response = yield POST(`${action.server}api/role`, action.data, headers);
  if (response && response === 503) {
    yield put(actions.serverErrorSuccess(response));
  }
  if (response && response.status === 200) {
    yield put(actions.addRoleSuccess(response.data));
  } else if (response && (response.status === 403 || response.status === 401)) {
    yield put(actions.userLogout(action.token, action.server));
  } else {
    yield put(actions.addRoleFail(response));
  }
}

//   ============================== ADD ROLE

export function* editRoleSaga(action) {
  yield put(actions.editRoleStart());
  const headers = {
    "content-type": "application/json",
    "X-Auth-Token": action.token
  };

  const response = yield PUT(`${action.server}api/role`, action.data, headers);
  if (response && response === 503) {
    yield put(actions.serverErrorSuccess(response));
  }
  if (response && response.status === 200) {
    yield put(actions.editRoleSuccess(response.data));
  } else if (response && (response.status === 403 || response.status === 401)) {
    yield put(actions.userLogout(action.token, action.server));
  } else {
    yield put(actions.editRoleFail(response));
  }
}

//   ============================== EDIT ROLE

export function* deleteRoleSaga(action) {
  yield put(actions.deleteRoleStart());
  const headers = {
    "content-type": "application/json",
    "X-Auth-Token": action.token
  };

  const response = yield DELETE(
    `${action.server}api/role/${action.id}`,
    headers
  );
  if (response && response === 503) {
    yield put(actions.serverErrorSuccess(response));
  }
  if (response && response.status === 200) {
    yield put(actions.deleteRoleSuccess(response));
  } else if (response && (response.status === 403 || response.status === 401)) {
    yield put(actions.userLogout(action.token, action.server));
  } else {
    yield put(actions.deleteRoleFail(response));
  }
}

//   ============================== DELETE ROLE
