import React from 'react';
import './Backdrop.css';
import PropTypes from 'prop-types';

export const Backdrop = (props) => {
    return(
        <div className="MSD-Backdrop" onClick={props.onClick} style={props.style}>
            {props.children}
        </div>
    )
}


Backdrop.propTypes = {
    onClick:PropTypes.func,
    style:PropTypes.object
}
