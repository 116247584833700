import * as actionTypes from '../actions/actionTypes'

const initialState = {
  user: null,
  response: null,
  loading: false,
  error: null,
  deleteProfileStart: false,
  deleteProfileFail: null,
  deleteProfileSuccess: null

}

const deleteMyProfileStart = state => {
  const newState = {
    ...state,
    deleteProfileStart: true,
    deleteProfileFail: null,
    deleteProfileSuccess: null
  }
  return newState
}

const deleteMyProfileClear = state => {
  const newState = {
    ...state,
    deleteProfileStart: false,
    deleteProfileFail: null,
    deleteProfileSuccess: null
  }
  return newState
}
const deleteMyProfileSuccess = (state, action) => {
  const newState = {
    ...state,
    deleteProfileStart: false,
    deleteProfileFail: null,
    deleteProfileSuccess: action.payload
  }
  return newState
}

const deleteMyProfileFail = (state, action) => {
  const newState = {
    ...state,
    deleteProfileStart: false,
    deleteProfileFail: action.payload,
    deleteProfileSuccess: null
  }
  return newState
}

const getMyProfileStart = state => {
  const newState = {
    ...state,
    loading: true,
    error: null,
    user: null
  }
  return newState
}
const getMyProfileSuccess = (state, action) => {
  const newState = {
    ...state,
    loading: false,
    user: action.user,
    error: null
  }
  return newState
}

const getMyProfileFail = (state, action) => {
  const newState = {
    ...state,
    user: null,
    loading: false,
    error: action.error
  }
  return newState
}

// ===============================================================

const editMyProfileStart = state => {
  const newState = {
    ...state,
    loading: true,
    error: null,
    response: null
  }
  return newState
}
const editMyProfileSuccess = (state, action) => {
  const newState = {
    ...state,
    loading: false,
    response: action.response,
    error: null
  }
  return newState
}

const editMyProfileFail = (state, action) => {
  const newState = {
    ...state,
    response: null,
    loading: false,
    error: action.error
  }
  return newState
}

export const profileReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.DELETE_MY_PROFILE_START:
      return deleteMyProfileStart(state)
    case actionTypes.DELETE_MY_PROFILE_SUCCESS:
      return deleteMyProfileSuccess(state, action)
    case actionTypes.DELETE_MY_PROFILE_FAIL:
      return deleteMyProfileFail(state, action)
    case actionTypes.DELETE_MY_PROFILE_CLEAR:
      return deleteMyProfileClear(state, action)
    case actionTypes.GET_MY_PROFILE_START:
      return getMyProfileStart(state)
    case actionTypes.GET_MY_PROFILE_SUCCESS:
      return getMyProfileSuccess(state, action)
    case actionTypes.GET_MY_PROFILE_FAIL:
      return getMyProfileFail(state, action)

    case actionTypes.EDIT_MY_PROFILE_START:
      return editMyProfileStart(state)
    case actionTypes.EDIT_MY_PROFILE_SUCCESS:
      return editMyProfileSuccess(state, action)
    case actionTypes.EDIT_MY_PROFILE_FAIL:
      return editMyProfileFail(state, action)

    default:
      return state
  }
}
