import { put } from "redux-saga/effects";
import * as actions from "../actions/index";
import { POST, GET } from "../callApiWrapper";

export function* userLoginSaga(action) {
  yield put(actions.userLoginStart());
  const response = yield GET(
    `${action.server}api/user/login`,
    { "Content-Type": "application/json" },
    { username: action.username, password: action.password }
  );
  if (response && response === 503) {
    yield put(actions.serverErrorSuccess(response));
  } else if (response && response.status === 200) {
    yield put(actions.userLoginSuccess(response.data));
  } else {
    yield put(actions.userLoginFail(response.data));
  }
}

// ===============================================================================

export function* userLogoutSaga(action) {
  const response = yield GET(`${action.server}api/user/logout`, {
    "Content-Type": "application/json",
    "X-Auth-Token": action.token
  });
  if (response) {
    yield put(actions.userLogoutSuccess(200));
  }
}

// ================================================================================
export function* forgotPasswordSendCodeSaga(action) {
  yield put(actions.forgotPasswordSendCodeStart());
  const body = {
    email: action.email
  };
  const headers = {
    "content-type": "application/json"
  };
  const response = yield POST(
    `${action.server}api/user/forgetPassword/sendCode`,
    body,
    headers
  );
  if (response && response === 503) {
    yield put(actions.serverErrorSuccess(response));
  } else if (response && response.status === 200) {
    yield put(actions.forgotPasswordSendCodeSuccess("sendCode"));
  } else {
    yield put(actions.forgotPasswordSendCodeFail(response.data));
  }
}

// ================================================================================
export function* forgotPasswordCheckCodeSaga(action) {
  yield put(actions.forgotPasswordCheckCodeStart());
  const body = {
    email: action.email,
    otp: {
      secret: action.code
    }
  };
  const headers = {
    "content-type": "application/json"
  };
  const response = yield POST(
    `${action.server}api/user/forgetPassword/checkCode`,
    body,
    headers
  );
  if (response && response === 503) {
    yield put(actions.serverErrorSuccess(response));
  } else if (response && response.status === 200) {
    yield put(actions.forgotPasswordCheckCodeSuccess("checkCode"));
  } else {
    yield put(actions.forgotPasswordCheckCodeFail(response.data));
  }
}

// ================================================================================
export function* forgotPasswordChangePasswordSaga(action) {
  yield put(actions.forgotPasswordChangePasswordStart());
  const body = {
    email: action.email,
    newPassword: action.newPassword,
    otp: {
      secret: action.code
    }
  };
  const headers = {
    "content-type": "application/json"
  };
  const response = yield POST(
    `${action.server}api/user/forgetPassword/change`,
    body,
    headers
  );
  if (response && response === 503) {
    yield put(actions.serverErrorSuccess(response));
  } else if (response && response.status === 200) {
    yield put(actions.forgotPasswordChangePasswordSuccess(response));
  } else {
    yield put(actions.forgotPasswordChangePasswordFail(response.data));
  }
}

// ================================================================================

export function* userRegisterSendCodeSaga(action) {
  yield put(actions.userRegisterSendCodeStart());
  const headers = {
    "content-type": "application/json"
  };
  const response = yield POST(
    `${action.server}api/user-registration/sendCode`,
    action.object,
    headers
  );
  if (response && response === 503) {
    yield put(actions.serverErrorSuccess(response));
  } else if (response && response.status === 200) {
    yield put(actions.userRegisterSendCodeSuccess("sendCode"));
  } else {
    yield put(actions.userRegisterSendCodeFail(response.data));
  }
}

// ================================================================================

export function* userRegisterRegisterSaga(action) {
  yield put(actions.userRegisterRegisterStart());
  const registerInfo = {
    identity: action.object.identity,
    email: action.object.email,
    password: action.object.password,
    confirmPassword: action.object.confirmPassword,
    otp: {
      secret: action.object.otp
    }
  };
  let response;
  if (registerInfo.identity.avatar.encoding === "BASE64") {
    const ddb = `--${registerInfo.otp.secret}`;
    const ending = `${ddb}--`;
    let body;
    let file;
    const jsonData =
      `\n${ddb}\n` +
      `Content-Disposition: form-data; name="data"\n` +
      `Content-Type: application/json\n\n` +
      `${JSON.stringify(registerInfo)}\n`;
    if (action.object.file) {
      file =
        `${ddb}\n` +
        `Content-Disposition: form-data; name="file"; filename="personAvatar"\n` +
        `Content-Type: ${action.object.file.mime}\n\n` +
        `${action.object.file.data}\n`;
    }
    body = jsonData;
    if (file) {
      body = body + file + ending;
    } else {
      body = body + ending;
    }
    response = yield fetch(`${action.server}api/user-registration/register`, {
      method: "POST",
      headers: {
        "Content-type": `multipart/form-data; boundary=${registerInfo.otp.secret}`
      },
      body
    })
      .then(res => {
        return res;
      })
      .catch(err => {
        return err;
      });
    if (response && response.status === 200) {
      yield put(actions.userRegisterRegisterSuccess(response.status));
    } else {
      yield put(actions.userRegisterRegisterFail(response));
    }
  } else {
    const eventData = new FormData();
    eventData.append(
      "data",
      new Blob([JSON.stringify(registerInfo)], { type: "application/json" })
    );
    response = yield POST(
      `${action.server}api/user-registration/register`,
      eventData,
      {}
    );

    if (response && response === 503) {
      yield put(actions.serverErrorSuccess(response));
    } else if (response && response.status === 200) {
      yield put(actions.userRegisterRegisterSuccess(response.status));
    } else {
      yield put(actions.userRegisterRegisterFail(response.data));
    }
  }
}

// ================================================================================

export function* userSocialLoginSaga(action) {
  yield put(actions.userSocialLoginStart());

  const headers = {
    "content-type": "application/json"
  };
  const response = yield POST(
    `${action.server}api/user-registration/register/globalProvider`,
    action.object,
    headers
  );
  if (response && response === 503) {
    yield put(actions.serverErrorSuccess(response));
  } else if (response && response.status === 200) {
    yield put(actions.userSocialLoginSuccess(response.data));
  } else {
    yield put(actions.userSocialLoginFail(response.data));
  }
}
