import * as actionTypes from "./actionTypes";

export const addComment = (token, object, server) => {
  return {
    type: actionTypes.ADD_COMMENT,
    object: object,
    server: server,
    token: token
  };
};
export const addCommentStart = () => {
  return {
    type: actionTypes.ADD_COMMENT_START
  };
};

export const addCommentSuccess = response => {
  return {
    type: actionTypes.ADD_COMMENT_SUCCESS,
    response: response
  };
};
export const addCommentFail = error => {
  return {
    type: actionTypes.ADD_COMMENT_FAIL,
    error: error
  };
};

// ================================================ searchComments

export const searchComments = (token, object, server) => {
  return {
    type: actionTypes.SEARCH_COMMENTS,
    object: object,
    server: server,
    token: token
  };
};
export const searchCommentsStart = () => {
  return {
    type: actionTypes.SEARCH_COMMENTS_START
  };
};

export const searchCommentsSuccess = comments => {
  return {
    type: actionTypes.SEARCH_COMMENTS_SUCCESS,
    comments: comments
  };
};
export const searchCommentsFail = error => {
  return {
    type: actionTypes.SEARCH_COMMENTS_FAIL,
    error: error
  };
};

// ================================================ searchCommentReplies

export const searchCommentReplies = (token, object, server) => {
  return {
    type: actionTypes.SEARCH_COMMENT_REPLIES,
    object: object,
    server: server,
    token: token
  };
};
export const searchCommentRepliesStart = () => {
  return {
    type: actionTypes.SEARCH_COMMENT_REPLIES_START
  };
};

export const searchCommentRepliesSuccess = replies => {
  return {
    type: actionTypes.SEARCH_COMMENT_REPLIES_SUCCESS,
    replies: replies
  };
};
export const searchCommentRepliesFail = error => {
  return {
    type: actionTypes.SEARCH_COMMENT_REPLIES_FAIL,
    error: error
  };
};

// ================================================ deleteComments

export const deleteComment = (token, id, server) => {
  return {
    type: actionTypes.DELETE_COMMENT,
    id: id,
    server: server,
    token: token
  };
};
export const deleteCommentStart = () => {
  return {
    type: actionTypes.DELETE_COMMENT_START
  };
};

export const deleteCommentSuccess = response => {
  return {
    type: actionTypes.DELETE_COMMENT_SUCCESS,
    response: response
  };
};
export const deleteCommentFail = error => {
  return {
    type: actionTypes.DELETE_COMMENT_FAIL,
    error: error
  };
};

// ===================================================== EDIT COMMENT

export const editComment = (token, object, server) => {
  return {
    type: actionTypes.EDIT_COMMENT,
    object: object,
    server: server,
    token: token
  };
};
export const editCommentStart = () => {
  return {
    type: actionTypes.EDIT_COMMENT_START
  };
};

export const editCommentSuccess = response => {
  return {
    type: actionTypes.EDIT_COMMENT_SUCCESS,
    response: response
  };
};
export const editCommentFail = error => {
  return {
    type: actionTypes.EDIT_COMMENT_FAIL,
    error: error
  };
};
// ===================================================== BLOCK REPORTED COMMENT

export const blockComment = (token, id, server) => {
  return {
    type: actionTypes.BLOCK_COMMENT,
    id: id,
    server: server,
    token: token
  };
};

export const blockCommentStart = () => {
  return {
    type: actionTypes.BLOCK_COMMENT_START
  };
};

export const blockCommentSuccess = response => {
  return {
    type: actionTypes.BLOCK_COMMENT_SUCCESS,
    response: response
  };
};

export const blockCommentFail = error => {
  return {
    type: actionTypes.BLOCK_COMMENT_FAIL,
    error: error
  };
};
