import React from 'react'
import styles from './accessDenied.css'

export const accessDenied = () =>{
    return(
        <div className={styles.accessDenied}>
        <h2>
            403 Error
        </h2>
        <p>
            You don't have permission to visit this page!
        </p>
    </div>
    )
}