import React from 'react';
import PropTypes from 'prop-types';
import {Icon} from 'antd'
import './HamburgerMenu.css';

export const HamburgerMenu = (props) =>{
    return(
        <span className="HamburgerMenu" onClick={props.onClick}>
            <Icon type="menu" />
        </span>
    )
}

HamburgerMenu.propTypes = {
    onClick:PropTypes.func
  }