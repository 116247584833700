import React from 'react'
import {Form,Input,Button} from 'antd'
import PropTypes from 'prop-types';
import {Label} from '../../Elements/Label/Label'
import './ForgotPassword.css'


export const ForgotPassword = (props) =>{
    return(
        <div className="MSD-ForgotPassword">
            <Form onSubmit={(e) => props.onSubmit(e)}>
                <Form.Item>
                    <Label theme="material">USERNAME *</Label>
                    <Input 
                        type="text"
                        placeholder="Enter your username"
                        onChange={(e) => props.inputChangeHandler("username",e.target.value)} />
                </Form.Item>
                <div>
                    <Button onClick={(e) => props.onSubmit(e)}>SUBMIT</Button>
                </div>
            </Form>
        </div>
    )
}

ForgotPassword.propTypes = {
    inputChangeHandler:PropTypes.func,
    onSubmit:PropTypes.func,
  }