import React, { Component } from "react";
import DatePicker from "react-mobile-datepicker";
import "./mobileDatePicker.css";
export class MobileDatePicker extends Component {
  state = {
    time: this.props.defaultValue
      ? new Date(this.props.defaultValue)
      : new Date(),
    isOpen: false,
    dataFormat: null,
    maxDate: null
  };

  componentDidMount() {
    if (this.props.disabledDate) {
      const date = new Date();
      date.setDate(date.getDate() + parseInt(this.props.maximumDay));
      this.setState({
        maxDate: date
      });
    }
    let dateConfig;
    switch (this.props.format) {
      case "dateAndTime":
        dateConfig = {
          year: {
            format: "YYYY",
            caption: "Year",
            step: 1
          },
          month: {
            format: "MM",
            caption: "Month",
            step: 1
          },
          date: {
            format: "DD",
            caption: "Day",
            step: 1
          },
          hour: {
            format: "hh",
            caption: "Hour",
            step: 1
          },
          minute: {
            format: "mm",
            caption: "Min",
            step: 1
          }
        };
        this.setState({
          dataFormat: dateConfig
        });
        break;
      case "date":
        dateConfig = {
          year: {
            format: "YYYY",
            caption: "Year",
            step: 1
          },
          month: {
            format: "MM",
            caption: "Month",
            step: 1
          },
          date: {
            format: "DD",
            caption: "Day",
            step: 1
          }
        };
        this.setState({
          dataFormat: dateConfig
        });
        break;
      case "time":
        dateConfig = {
          hour: {
            format: "hh",
            caption: "Hour",
            step: 1
          },
          minute: {
            format: "mm",
            caption: "Min",
            step: 1
          }
        };
        this.setState({
          dataFormat: dateConfig,
          time: this.props.defaultValue
            ? new Date(`2019-01-01 ${this.props.defaultValue}`)
            : new Date("2019-01-01 00:00")
        });
        break;
      default:
        return null;
    }
  }
  handleClick = () => {
    this.setState({ isOpen: true });
  };

  handleCancel = () => {
    this.setState({ isOpen: false });
  };

  handleSelect = time => {
    this.setState({ time, isOpen: false });
    this.props.onChange(time);
  };
  render() {
    return (
      <div>
        <div className="mobileDatePickerBtn" onClick={this.handleClick}>
          {this.props.defaultValue ? this.props.defaultValue : "Please Select"}
        </div>

        <DatePicker
          value={this.state.time}
          dateConfig={this.state.dataFormat}
          confirmText="Ok"
          cancelText="Cancel"
          showCaption={true}
          min={this.props.disabledDate ? this.props.min ? this.props.min : new Date() : new Date(1970, 0, 1)}
          max={
            this.props.disabledDate ? this.state.maxDate : new Date(2050, 0, 1)
          }
          isOpen={this.state.isOpen}
          onSelect={this.handleSelect}
          onCancel={this.handleCancel}
        />
      </div>
    );
  }
}
